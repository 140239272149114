import React from 'react'

import { connect } from 'react-redux'

// import { addLocaleData } from 'react-intl'
import { IntlProvider } from 'react-intl'
import { Route, Switch } from 'react-router-dom'
import en from './locales/en.json'
// import enLocale from 'react-intl/locale-data/en'
//eslint-disable-next-line
import esMoment from 'moment/locale/es'
import es from './locales/es.json'
// import esLocale from 'react-intl/locale-data/es'
//eslint-disable-next-line
import deMoment from 'moment/locale/de'
import de from './locales/de.json'

//eslint-disable-next-line
import frMoment from 'moment/locale/fr'
import fr from './locales/fr.json'
// import frLocale from 'react-intl/locale-data/fr'
//eslint-disable-next-line
import itMoment from 'moment/locale/it'
import it from './locales/it.json'
// import itLocale from 'react-intl/locale-data/it'

import moment from 'moment'
import Routes from '../components/routes'
import { withRouter } from 'react-router'
import { getLanguage, getLanguageDir } from '../store/ui/reducers.js'
import Spinner from '../components/global/Spinner.js'
import { getIsLogged } from '../store/login/reducers.js'

// addLocaleData([
//     ...enLocale,
//     ...esLocale,
//     ...deLocale,
//     ...fiLocale,
//     ...frLocale,
//     ...heLocale,
//     ...itLocale,
//     ...jaLocale,
//     ...nlLocale,
//     ...noLocale,
//     ...plLocale,
//     ...ptLocale,
//     ...svLocale,
//     ...trLocale,
//     ...enUsLocale,
//     ...daLocale
// ])

const messages = {
    en,
    es,
    de,
    fr,
    it,
}

const Error404 = () => <h1>404</h1>
const mapStateToProps = (state) => {
    return {
        locale: 'en',
        dir: getLanguageDir(state),
        isLogged: getIsLogged(state),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // askLocation: (locale, route) => {
        //     return dispatch(askLocation(locale, route))
        // },
    }
}

class MyIntlProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            messagesLoaded: false,
        }

        this.html = window.document.documentElement
    }

    render() {
        moment.locale(this.props.locale)
        this.html.lang = this.props.locale
        this.html.dir = this.props.dir ? this.props.dir : 'ltr'

        return (
            <div>
                <IntlProvider locale={this.props.locale} messages={messages[this.props.locale]} key={this.props.locale}>
                    <Routes />
                </IntlProvider>
            </div>
        )
    }
}

MyIntlProvider = withRouter(connect(mapStateToProps, mapDispatchToProps)(MyIntlProvider))

const IntlRoute = () => {
    return (
        <div>
            <Switch>
                <Route path="/:lang(es|en|de|da|fi|he|en-CA|fr-CA|fr|it|ja|nl|no|pt|pl|sv|tr)?" component={MyIntlProvider} />
                <Route component={Error404} />
            </Switch>
        </div>
    )
}

export default IntlRoute
