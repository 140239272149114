import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Chat from './Chat'
import LocalizedLink from '../global/LocalizedLink'
import Alerts from '../alerts/Alerts'

export default class Chats extends Component {
    state = {
        activeChatId: null,
        filters: {
            allStates: false
        },
        isChatsExpanded:false
    }
    componentDidMount = () => {
        this.props.fetchChats().then(() => {
            if (this.props.activeChatId) {
                this.openChat(this.props.activeChatId);
            }
        });
    }

    openChat = (chatId) => {
        this.setState({activeChatId: parseInt(chatId)});
        this.props.fetchChat({ chatId: parseInt(chatId) });
    }

    render() {

        if(!this.props.verified)
        {
            return (
                 <Alerts alert={'VERIFY_YOUR_EMAIL'} status={'warning'} />
                )
        }

        // if (!this.props.bankData || !this.props.bankData.validated) {
        //     return (
        //         <Alerts alert={'FILL_FINANCIAL_INFO_TO_TRADE'} status={'warning'} />
        //     )
        // }
        
        return (
            <section className='section'>
                {/* {this.props.isLoading && <Spinner isLoading={true} />} */}

                <div className=''>
                    <div className='domain__base-box domain__base-box-small-padding buttons'>
                        <div className=''>
                            <div className='row'>
                                <div
                                    className='col-12 col-lg-3'
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                    <nav className='card'>
                                        <div className='card-header' style={{ background: 'lightgray' }}
                                        onClick={() => this.setState({isChatsExpanded:!this.state.isChatsExpanded})}>
                                            <FormattedMessage id='chats' defaultMessage='Chats'></FormattedMessage><i className={this.state.isChatsExpanded? 'fa fa-chevron-down': 'fa fa-chevron-up'}  style={{marginLeft:'8px'}}/>
                                        </div>
                                        <ul className={`list-group list-group-flush ${this.state.isChatsExpanded? 'd-none' : 'd-block'}`}>
                                            {this.props.chats.map(item => {
                                                return (
                                                    <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'}
                                                                   key={item.id}
                                                                   enableActiveClass={false}
                                                                   params={{ view: 'chats', id: item.id }}
                                                                   onClick={() => this.openChat(item.id)}>
                                                        <li
                                                            className={parseInt(this.props.activeChatId) === item.id
                                                                ? 'list-group-item active'
                                                                : 'list-group-item'}
                                                        >
                                                            <span
                                                                style={{
                                                                    display: 'flex',
                                                                    flexWrap: 'wrap',
                                                                    cursor: 'pointer'
                                                                }}
                                                                className={
                                                                    this.props.activeChatId === item.id
                                                                        ? 'panel-block active'
                                                                        : 'panel-block'
                                                                }
                                                            >
                                                                    <span className='panel-icon'>
                                                                        {item.unread_user ? (
                                                                            <span
                                                                                className='message__badge'>{item.unread_user}</span>
                                                                        ) : (
                                                                            <i className='fas fa-comment'
                                                                               aria-hidden='true'></i>
                                                                        )}
                                                                    </span>
                                                                {item.recipient_name}
                                                            </span>
                                                        </li>
                                                    </LocalizedLink>
                                                )
                                            })}
                                        </ul>
                                    </nav>
                                </div>
                                <div className='col-12 col-lg-9'>
                                    {
                                        this.props.chats.length > 0 &&
                                        <Chat relative key={this.props.activeChatId} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
