import { Votes } from '../../screens/PublicProfileScreen';
import LocalizedLink from './LocalizedLink'
import React from 'react'

export const PublicProfileLink = ({userNameSlug, label, className, transactions = null, votes = null}) => {
    return (
        <div className="public-profile-link" style={{display:'inline-flex',flexDirection:'row',borderLeft:0,padding:0}}>
            <LocalizedLink routeId="ROUTE_PUBLIC_USER_PROFILE" className={className} params={{ userNameSlug: userNameSlug }}>
                {label}
                {!!transactions && <span style={{ borderRadius: '8px', background: 'lightgray', marginLeft: '8px', padding: '4px 8px' }}>{transactions}</span>}
                {votes && <Votes votes={votes} />}
                {/* {votes && <Votes votes={votes.vote_seller_general} />} */}
            </LocalizedLink>
        </div>
    )
}
