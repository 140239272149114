import { fetchCredit } from './../../store/login/actions';
import { getCredit, getShippingAddress, getUserName } from './../../store/login/reducers'
import { getCheckoutSummary, payMyPendingCredit } from './../../store/cart/actions'
import { connect } from 'react-redux'
import { getCheckoutResponse, showCheckoutModalSelector } from '../../store/cart/reducers'
import * as ui from '../../store/ui/actions'
import OrderFinished from './OrderFinished'
import { injectIntl } from 'react-intl'

const mapStateToProps = (state,{match}) => {
    return {
        show: showCheckoutModalSelector(state),
        checkoutResponse: getCheckoutResponse(state),
        checkoutId: match.params.checkoutId,
        credit: getCredit(state),
        shippingAddress: getShippingAddress(state),
        userName: getUserName(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeCheckoutModal())
        },
        payMyPendingCredit: (url) => {
            dispatch(payMyPendingCredit(url))
        },
        getCheckoutSummary: (checkoutId) => {
            dispatch(getCheckoutSummary(checkoutId))
        },
        fetchCredit: () => {
            dispatch(fetchCredit())
        },
    }
}

const OrderFinishedContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderFinished)

export default injectIntl(OrderFinishedContainer)
