import React from 'react'
import { OrderLink } from '../tasks/OrderLink'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import moment from 'moment'

export const ChatPendingMiniNotification = (({ notification, translationId, translationParams = {} }) => {
    return <div className='container-fluid mb-2'>
        <div className='card'>
            <div className='card-body'>
                <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'}
                               className='colored-link'
                               params={{ view: 'chats', id: notification.chat_id }}>
                    <div className='row'>
                        <div className='col-2'>
                            <div className='circle bg-color-iron' style={{ marginRight: '15px' }}>
                            <span className='material-icons-outlined'>
                                message
                            </span>
                            </div>
                        </div>
                        <div className='col-8'>
                            <div>
                                <Translation id={translationId} values={{ ...translationParams }} />
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className='border-left-line'>
                            <span className='material-icons-outlined' style={{ padding: '16px' }}>
                                    call_made
                                </span>
                            </div>
                        </div>
                    </div>
                </LocalizedLink>
            </div>
        </div>
    </div>
})

export const OrderMiniNotification = ({ notification, translationId, translationParams = {} }) => {
    return <div className='container-fluid mb-2'>
        <div className='card'>
            <div className='card-body'>
                <LocalizedLink routeId='ROUTE_ORDER'  className='colored-link' params={{ id: notification.order_id }}>

                    <div className='row justify-content-center align-items-center'>
                        <div className='col-2'>
                            <div className='circle bg-color-iron' style={{ marginRight: '15px' }}>
                            <span className='material-icons-outlined'>
                                sell
                            </span>
                            </div>
                        </div>
                        <div className='col-8'>
                            <div>
                                <Translation id={translationId} values={{ ...translationParams }} />
                            </div>
                            <div>
                                <OrderLink
                                    orderId={notification.order_id}></OrderLink> {moment(notification.created_at).format('DD/MM/YYYY').toString()}
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className='border-left-line'>
                                <span className='material-icons-outlined' style={{ padding: '16px' }}>
                                    call_made
                                </span>

                            </div>
                        </div>
                    </div>
                </LocalizedLink>
            </div>
        </div>
    </div>
}

export const PendingTaskMiniNotification = ({ notification, translationId, qtyPendingTask }) => {
    return <div className='container-fluid mb-2'>
        <div className='card'>
            <div className='card-body'>
                <LocalizedLink routeId='ROUTE_USER_DASHBOARD'  className='colored-link' params={{ view: '', id: '' }}>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-2'>
                            <div className='circle bg-color-iron' style={{ marginRight: '15px' }}>
                            <span className='material-icons-outlined'>
                                pending_actions
                            </span>
                            </div>
                        </div>
                        <div className='col-8'>

                            <div>
                                <span className='badge badge-pill badge-secondary'>{qtyPendingTask}</span> <Translation
                                id={translationId} />
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className='border-left-line'>

                                <span className='material-icons-outlined' style={{ padding: '16px' }}>
                                    call_made
                                </span>
                            </div>
                        </div>
                    </div>
                </LocalizedLink>

            </div>
        </div>
    </div>
}
