import React from 'react'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { byProductId } from '../../store/products/reducers'
import { getUserPublicInfo } from '../../store/user/reducers'
import { getCurrentChatRecipientName } from '../../store/chats/reducers'
import { findSaleItemById } from '../../store/sale/reducers'
import { findBuyItemById } from '../../store/buy/reducers'
import { findCollectionById } from '../../store/collection/reducers'


const DynamicProductName = ({ match }) => {
    const product = useSelector(state => byProductId(state, match.params.id))

    return <span>{product?.name} ({product?.setName})</span>
}

const DynamicPublicProfileName = () => {
    const currentUser = useSelector(state => getUserPublicInfo(state))
    return <span>{ currentUser?.name }</span>
}


const DynamicChatRecipientName = () => {
    const recipientName = useSelector(state => getCurrentChatRecipientName(state))
    return <span>{ recipientName ?? '-'}</span>
}

const DynamicEditSaleItemName = ({ match }) => {
    const saleItem = useSelector(state => findSaleItemById(state, match.params.id))
    return <span>{ saleItem?.product.name} ({saleItem?.product?.setName})</span>
}

const DynamicEditBuyItemName = ({ match }) => {
    const buyItem = useSelector(state => findBuyItemById(state, match.params.id))
    return <span>{ buyItem?.product.name} ({buyItem?.product?.setName})</span>
}

const DynamicCollectionItemName = ({ match }) => {
    const buyItem = useSelector(state => findCollectionById(state, match.params.id))
    return <span>{ buyItem?.product.name} ({buyItem?.product?.setName})</span>
}

const routes = [
    { path: '/', breadcrumb: 'Home' },
    { path: '/product', breadcrumb: null },
    { path: '/product/:id',  breadcrumb: DynamicProductName },
    { path: '/user/:id',  breadcrumb: DynamicPublicProfileName },
    { path: '/dashboard/chats/:activeChatId',  breadcrumb: DynamicChatRecipientName },
    { path: '/dashboard/edit-item-sale/:id',  breadcrumb: DynamicEditSaleItemName },
    { path: '/dashboard/edit-item-buy/:id',  breadcrumb: DynamicEditBuyItemName },
    { path: '/dashboard/edit-item-collection/:id',  breadcrumb: DynamicCollectionItemName },
    { path: '/dashboard/buy/:id',  breadcrumb: DynamicPublicProfileName },
    { path: '/dashboard/sale/:id',  breadcrumb: DynamicPublicProfileName },
    { path: '/reset-password', breadcrumb: null},
    { path: '/reset-password/:token', breadcrumb: 'Reset my password'},
];

const BreadCrumbs = ({breadcrumbs}) => {
    const location = useLocation()
    const blackList = ['/']
    if ( blackList.includes(location.pathname) ) {
        return ''
    }

    return (
        <nav>
            {breadcrumbs.map(({ match, breadcrumb }, index) => (
                <Link
                    key={match.url}
                    to={match.url}
                    className={match.pathname === location.pathname ? "breadcrumb-active" : "breadcrumb-not-active"}
                >
                    {breadcrumb} {index < (breadcrumbs.length-1) && '/ ' }
                </Link>
            ))}
        </nav>
    );
}

export default withBreadcrumbs(routes)(BreadCrumbs);
