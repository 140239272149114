import jaceBg from './assets/img/jace-background.jpeg'
import LocalizedLink from './components/global/LocalizedLink'
import Alerts from './components/alerts/Alerts'
import Spinner from './components/global/Spinner'
import SimplifiedResult from './components/product/SimplifiedResult'
import ReactTooltip from 'react-tooltip'
import React from 'react'
import { getFetchedProducts } from './store/products/reducers'
import { connect } from 'react-redux'
import { Hits, ResetFilters, SearchBox, SearchkitManager, SearchkitProvider, InitialLoader } from 'searchkit'
import Results from './Results'
import { defineMessages, injectIntl } from 'react-intl'

import Select from 'react-select'


const mapStateToProps = (state) => {
    return {
        products: getFetchedProducts(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

defineMessages({
    SEARCH_BY_NAME_OR_WHATEVER: {
        id: 'select',
        description: 'select',
        defaultMessage: 'Select'
    }
})

class Home extends React.Component {
    render() {
        const hitsComponent = (hit) => {
            
            let product = hit.result._source
            return <SimplifiedResult product={product} />
            
        }
        const hits = <Hits hitsPerPage={20} itemComponent={hitsComponent} />
        const ResetSearchbox = ({ hasFilters, resetFilters }) => {
            return <div onClick={resetFilters} style={{ visibility: hasFilters ? 'visible' : 'hidden' }}
                        className='bt-reset-searchbox' />
        }
        return (
            <div className="search-overlay" id="section-home">
                <div className="xl-container">
                    <ResetFilters component={ResetSearchbox} options={{ query: true, filter: false, pagination: true }} />
                    <div className="col-12">
                        <Results hits={hits} />
                        <InitialLoader component={() => <Spinner isLoading={true} relative />} />
                    </div>
                    <div className="home-cards" style={{}}>
                        <div className="home-card ">
                            <div style={{background:'gray',borderRadius:'100%',width:'100px',height:'100px',marginBottom:'32px',display:'flex',alignItems:'center',justifyContent:'center'}}> <span className="material-icons-outlined" style={{ color: 'white', fontSize: '48px' }}>
                                    token
                                </span></div>
                            <h5>What does C&C do for you?</h5>
                            <p>Take control of your collectible cards. Seamlessly manage, buy, and sell your collection.Start maximizing your collectibles experience today!</p>
                        </div>
                        <div className="home-card ">
                            <div style={{background:'gray',borderRadius:'100%',width:'100px',height:'100px',marginBottom:'32px',display:'flex',alignItems:'center',justifyContent:'center'}}> <span className="material-icons-outlined" style={{ color: 'white', fontSize: '48px' }}>
                                    category
                                </span></div>
                            <h5>Handle your collection, it is easy</h5>
                            <p>Craft your card collection like a pro. Discover rare gems, connect with fellow collectors, and unlock new possibilities for your passion</p>
                        </div>
                        <div className="home-card ">
                            <div style={{background:'gray',borderRadius:'100%',width:'100px',height:'100px',marginBottom:'32px',display:'flex',alignItems:'center',justifyContent:'center'}}> <span className="material-icons-outlined" style={{ color: 'white', fontSize: '48px' }}>
                                    payments
                                </span></div>
                            <h5>Sell cards you don’t need</h5>
                            <p>Transform unwanted cards into treasures. Connect with collectors, unearth rare finds, and elevate your card game</p>
                        </div>
                        <div className="home-card ">
                            <div style={{background:'gray',borderRadius:'100%',width:'100px',height:'100px',marginBottom:'32px',display:'flex',alignItems:'center',justifyContent:'center'}}> <span className="material-icons-outlined" style={{ color: 'white', fontSize: '48px' }}>
                                    checklist
                                </span></div>
                            <h5>Create a buylist!</h5>
                            <p>Create your personalized card buylist. Easily track and acquire the cards you desire.Turn your wish list into reality</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Home))
