import React from 'react'

import { defineMessages, injectIntl } from 'react-intl'
import { connect } from 'react-redux'

defineMessages({
    TOKEN_HAS_EXPIRED: {
        id: 'TOKEN_HAS_EXPIRED',
        description: 'TOKEN_HAS_EXPIRED',
        defaultMessage: "Your session has expired"
    },
    ORDER_BUY_NOT_ENOUGH_CREDIT: {
        id: 'ORDER_BUY_NOT_ENOUGH_CREDIT',
        description: 'ORDER_BUY_NOT_ENOUGH_CREDIT',
        defaultMessage: "Error in checkout. Buyers haven't enough buy credit."
    },
    IMAGE_FRONT: {
        id: 'IMAGE_FRONT',
        description: 'IMAGE_FRONT',
        defaultMessage: "Card front photo"
    },
    IMAGE_BACK: {
        id: 'IMAGE_BACK',
        description: 'IMAGE_BACK',
        defaultMessage: "Card back photo"
    },
    CONFIRM_DELETE_ITEM_FROM_SALE: {
        id: 'CONFIRM_DELETE_ITEM_FROM_SALE',
        description: 'CONFIRM_DELETE_ITEM_FROM_SALE',
        defaultMessage: "Delete item from sale?"
    },
    CONFIRM_DELETE_ITEM_FROM_BUY: {
        id: 'CONFIRM_DELETE_ITEM_FROM_BUY',
        description: 'CONFIRM_DELETE_ITEM_FROM_BUY',
        defaultMessage: "Delete item from buy?"
    },
    CONFIRM_DELETE_ITEM_FROM_COLLECTION: {
        id: 'CONFIRM_DELETE_ITEM_FROM_COLLECTION',
        description: 'CONFIRM_DELETE_ITEM_FROM_COLLECTION',
        defaultMessage: "Delete item from collection?"
    }
});

const mapStateToProps = (state) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {}
}

class Translation extends React.Component {
    state = { showInsertTranslation: false }
    render() {
        return (
            <div style={{ all: 'unset'}}>
                {this.props.intl.formatMessage({ id: this.props.id, defaultMessage: this.props.defaultMessage }, this.props.values ? this.props.values : {})}
            </div>
        )
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Translation))
