import { getUserId } from '../login/reducers'

const initState = {
    currentChatId: 0,
    currentChatMessages: [],
    chats: [],
    isLoading: false,
    status: null,
    messages: 0
}

const chats = (state = initState, action) => {
    switch (action.type) {
        // traer todos los chats del usuario
        case 'FETCH_CHATS_REQUEST':
            return { ...state, isLoading: true }
        case 'FETCH_CHATS_SUCCESS':
            return { ...state, isLoading: false, chats: action.chats, pages: action.pages }
        case 'FETCH_CHATS_FAILED':
            return { ...state, isLoading: false, status: action }

        // buscar o crear un chat
        case 'GET_OR_CREATE_CHAT_REQUEST':
            return { ...state, isLoading: true }
        case 'GET_OR_CREATE_CHAT_SUCCESS':
            return { ...state, isLoading: false }
        case 'GET_OR_CREATE_CHAT_FAILED':
            return { ...state, isLoading: false, status: action.error }

        // traer un chat concreto
        case 'FETCH_CHAT_MESSAGES_REKEST':
            return { ...state, isLoading: true }
        case 'FETCH_CHAT_MESSAGES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                currentChatMessages: action.response.data,
                currentChatId: action.chatId
            }
        case 'FETCH_CHAT_MESSAGES_FAILED':
            return { ...state, isLoading: false, status: action }

        // enviar un mensaje
        case 'SEND_CHAT_MESSAGE_REQUEST':
            return { ...state, isLoading: true }
        case 'SEND_CHAT_MESSAGE_SUCCESS':
            return { ...state, isLoading: false, status: action.response.data }
        case 'SEND_CHAT_MESSAGE_FAILED':
            return { ...state, isLoading: false, status: action.error }
        case 'SEND_ATTACHMENT_MESSAGE_CHAT_REQUEST':
            return { ...state, isLoading: true }
        case 'SEND_ATTACHMENT_MESSAGE_CHAT_SUCCESS':
            return { ...state, isLoading: false }
        case 'SEND_ATTACHMENT_MESSAGE_CHAT_FAILED':
            return { ...state, isLoading: false }
        case 'ADD_MESSAGE_CHAT_SUCCESS':
            if (state.currentChatId !== action.message.chat_id) {
                return state
            }
            return {
                ...state,
                currentChatMessages: [...state.currentChatMessages, action.message]
            }

        case 'CLEAR_CURRENT_CHAT':
            const currentChatId = state.currentChatId;
            return {
                ...state,
                isLoading: false,
                currentChatMessages: [],
                currentChatId: -1,
                chats: state.chats.map(chat => {
                    if (chat.id !== currentChatId) {
                        return chat
                    }
                    return {
                        ...chat,
                        'unread_user': 0,
                    }
                })
            }
        case 'CLEAR_CHATS':
            return {
                ...state,
                isLoading: false,
                chats: []
            }
        case 'INCREASE_UNREAD_DISTINCT_ACTIVE_CHAT_MESSAGES':
            return {
                ...state,
                chats: state.chats.map(chat => {
                    if (chat.id !== action.chatId) {
                        return chat
                    }
                    return {
                        ...chat,
                        'unread_user': chat.unread_user + 1
                    }
                })
            }
        case 'CLEAR_CHAT_UNREAD_COUNTER':
            return {
                ...state,
                chats: state.chats.map(chat => {
                    if (chat.id !== action.chatId) {
                        return chat
                    }
                    return {
                        ...chat,
                        'unread_user': 0,
                    }
                })
            }
        default:
            return state
    }
}

export const getChat = state => {
    const chatData = state.chats.chats.find(item => item.id === state.chats.currentChatId)
    return {
        chat: state.chats.currentChatMessages,
        participants: {
            user1: chatData?.user1,
            user2: chatData?.user2
        },
        chatId: state.chats.currentChatId
    }
}
export const getChats = state => {
    return state.chats.chats
}

export const getIsLoading = state => {
    return state.chats.isLoading
}

export const getPages = state => {
    return state.chats.pages
}

export const getStatus = state => {
    return state.chats.status
}
export const getMessages = state => {
    return state.chats.messages
}

export const getCurrentChatRecipientName = state => {
    const currentUserId = getUserId(state)
    const currentChat = getChat(state)

    if (currentUserId && currentChat && currentChat.participants.user1 && currentChat.participants.user2) {
        if (currentUserId === currentChat.participants.user1.id) {
            return currentChat.participants.user2.name
        } else if (currentUserId === currentChat.participants.user2.id) {
            return currentChat.participants.user1.name
        }
    }
    return null
}


export const getUnreadActiveChatMessages = state => state.chats.chats
    .find(chat => chat.id === state.chats.currentChatId)
    ?.unread_user;
export const getAllUnreadMessages = state => {
    return state.chats.chats
        .reduce((accumulator, chat) => {
            return accumulator + chat.unread_user
        }, 0)
}

export default chats
