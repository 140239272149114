export const initState = {
    maintenanceMode: false
}

const maintenanceMode = (state = initState, action) => {
    switch (action.type) {
        case 'MAINTENANCE_MODE_ON':
            return Object.assign({}, state, { maintenanceMode: true })
        case 'MAINTENANCE_MODE_OFF':
            return Object.assign({}, state, { maintenanceMode: false })
        default:
            return state
    }
}
export const getMaintenanceMode = state => {
    return state.maintenanceMode.maintenanceMode
}
export default maintenanceMode
