import Translation from '../../global/Translation'
import noImage from '../../../assets/img/advanced-search/advanced-search.jpg'

export const ProductAccessoryDetails = ({ product }) => {
    const imageUrl = product?.img || noImage

    return (
        <div className="row">
            <h3>{product.name}</h3>
            <div className="col-12 col-lg-4 mt-2">
                <img
                    src={imageUrl}
                    className="card-image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = noImage
                        return true
                    }}
                ></img>
            </div>
            <div className="col-12 col-lg-8 mt-1" style={{ fontWeight: '300' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <dl className="labeled row no-gutters m-0">
                        <dt className="col-5">
                            <Translation id="card_name" defaultMessage="Card Name" />:
                        </dt>
                        <dd className="col-7">{product.name}</dd>

                        <dt className="col-5">
                            <Translation id="type" defaultMessage="Type" />:
                        </dt>
                        <dd className="col-7">{product.AccessoryProductType}</dd>
                        <dt className="col-5">
                            <Translation id="description" defaultMessage="Description" />:
                        </dt>
                        <dd className="col-7">{product.AccessoryProductDescription}</dd>

                        <dd className="col-7">
                            {product.setName}{' '}
                            {product.keyruneCode && (
                                <img
                                    style={{ width: '20px' }}
                                    src={`https://svgs.scryfall.io/sets/${product.keyruneCode.toLowerCase()}.svg`}
                                    onError={(event) => {
                                        event.target.onerror = ''
                                        event.target.src = 'https://svgs.scryfall.io/sets/planeswalker.svg'
                                        return true
                                    }}
                                />
                            )}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}
