import React, { useState } from 'react'
import {
    RefinementListFilter,
    Hits,
    InputFilter,
    ItemList, BoolShould, TermQuery
} from 'searchkit'
import SimplifiedResult from '../components/product/SimplifiedResult'
import Results from '../Results'
import cardImage from '../assets/img/advanced-search/advanced-search.jpg'

export const setAdvancedSearchDefaultFilters = (sk) => {
    sk.addDefaultQuery(query => {
        return query.addFilter('isProductSingle', BoolShould([TermQuery('isProductSingle', true)]))
    })
}

const advancedSearchScreen = () => {

    const hitsComponent = (hit) => {

        let product = hit.result._source
        return <SimplifiedResult product={product} />

    }

    const hits = <Hits hitsPerPage={30} itemComponent={hitsComponent} />

    const NameFilter = (props) => {
        return  <InputFilter
            id="name_q"
            placeholder="Name"
            searchOnChange={true}
            prefixQueryFields={["name"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["name"]}
            mod={props.mod}
        />
    }

    const ManaCostFilter = (props) => {
        return  <InputFilter
            id="mana_cost_q"
            placeholder="Mana cost"
            searchOnChange={true}
            prefixQueryFields={["manaCost"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["manaCost"]}
            mod={props.mod}
        />
    }

    const ConvertedManaCostFilter = (props) => {
        return  <InputFilter
            id="converted_mana_cost_q"
            placeholder="CMC"
            searchOnChange={true}
            prefixQueryFields={["convertedManaCost"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["convertedManaCost"]}
            mod={props.mod}
        />
    }
    const TypeFilter = (props) => {
        return  <InputFilter
            id="type_q"
            placeholder="Type"
            searchOnChange={true}
            prefixQueryFields={["originalType","type"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["originalType","type"]}
            mod={props.mod}
        />
    }

    const SetFilter = (props) => {
        return  <InputFilter
            id="set_q"
            placeholder="Set"
            searchOnChange={true}
            prefixQueryFields={["setName"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["setName"]}
            mod={props.mod}
        />
    }

    const TextFilter = (props) => {
        return  <InputFilter
            id="text_q"
            placeholder="Text"
            searchOnChange={true}
            prefixQueryFields={["originalText","text"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["originalText","text"]}
            mod={props.mod}
        />
    }

    const FlavorTextFilter = (props) => {
        return  <InputFilter
            id="flavor_text_q"
            placeholder="Flavor text"
            searchOnChange={true}
            prefixQueryFields={["flavorText"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["flavorText"]}
            mod={props.mod}
        />
    }
    const PowerFilter = (props) => {
        return  <InputFilter
            id="power_q"
            placeholder="P"
            searchOnChange={true}
            prefixQueryFields={["power"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["power"]}
            mod={props.mod}
        />
    }

    const ToughnessFilter = (props) => {
        return  <InputFilter
            id="toughness_q"
            placeholder={"T"}
            searchOnChange={true}
            prefixQueryFields={["toughness"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["toughness"]}
            mod={props.mod}
        />
    }

    const ArtistFilter = (props) => {
        return  <InputFilter
            id="artist_q"
            placeholder="Artist"
            searchOnChange={true}
            prefixQueryFields={["artist"]}
            queryOptions={{ default_operator: 'AND' }}
            queryFields={["artist"]}
            mod={props.mod}
        />
    }

    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-xl-3'>
                        <div className='d-flex justify-content-center'
                            style={{
                                position: 'sticky',
                                top: '128px',
                            }}
                        >
                            <div className='advanced-search-card'
                                style={{
                                    backgroundImage: `url("${cardImage}")`,
                                }}
                            >
                                 <div style={{height:'100%'}}>
                                     <NameFilter mod='advanced-search-card-name-input' />
                                     <ManaCostFilter mod='advanced-search-mana-cost-input' />
                                     <ConvertedManaCostFilter mod='advanced-search-cmc-input' />
                                     <TypeFilter mod='advanced-search-type-input' />
                                     <SetFilter mod='advanced-search-set-input' />
                                     <TextFilter mod='advanced-search-text-input' />
                                     <FlavorTextFilter mod='advanced-search-flavor-input' />
                                     <PowerFilter mod='advanced-search-power-input' />
                                     <ToughnessFilter mod='advanced-search-toughness-input' />
                                     <ArtistFilter mod='advanced-search-artist-input' />
                                     <RefinementListFilter
                                         field="rarity.raw"
                                         title="Rarity"
                                         id='advanced-search-rarity'
                                         operator="OR"
                                         showCount={false}
                                         // itemComponent={RefinementOption}
                                         listComponent={ItemList}
                                     />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-xl-9'>
                        <div id="section-advanced-search">
                            <Results hits={hits} />
                         </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default advancedSearchScreen
