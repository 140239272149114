import { LOCATION_CHANGE } from 'react-router-redux'

export const initState = {
    saleItems: [],
    saleItem: null,
    page: 0,
    pagesCount: 0,
    pageSize: 10,
    total: 0,
    error: null,
    errors: null,
    isSending: false,
    isLoading: false,
    productsWithoutPrice: [],
}

const sale = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_SALE_ITEMS_REQUEST':
        case 'FETCH_SALE_ITEMS_FROM_USER_REQUEST':
            return { ...state, error: null, isLoading: true }
        case 'FETCH_SALE_ITEMS_SUCCESS':
        case 'FETCH_SALE_ITEMS_FROM_USER_SUCCESS':
            const expansionList =
                action.expansionsList?.map((expansion) => ({ value: expansion.expansionCode, label: `${expansion.expansionName} (${expansion.cardsCount})` })) ?? []
            return {
                ...state,
                saleItems: action.saleItems.current_page === 1 ? action.saleItems.data : state.saleItems.concat(action.saleItems.data),
                page: action.saleItems.current_page,
                pagesCount: action.saleItems.last_page,
                pageSize: action.saleItems.per_page,
                total: action.saleItems.total,
                expansionsList: [{ value: null, label: 'Any' }, ...expansionList],
                isLoading: false,
            }
        case 'FETCH_SALE_ITEMS_FAILURE':
        case 'FETCH_SALE_ITEMS_FROM_USER_FAILURE':
            return { ...state, error: action.response }
        case 'FETCH_SALE_ITEM_REQUEST':
            return { ...state, saleItem: null, error: null, isLoading: true }
        case 'FETCH_SALE_ITEM_SUCCESS':
            return { ...state, saleItem: action.saleItem, error: null, isLoading: false }
        case 'FETCH_SALE_ITEM_FAILURE':
            return { ...state, saleItem: null, error: action.response, isLoading: false }
        case 'ADD_TO_SALE_REQUEST':
            return { ...state, isSending: true, errors: null }
        case 'ADD_TO_SALE_SUCCESS':
            return { ...state, isSending: false, errors: null }
        case 'ADD_TO_SALE_FAILURE':
            return { ...state, isSending: false, errors: action.errors }
        case 'EDIT_ITEM_FROM_SALE_REQUEST':
            return { ...state, isSending: true, errors: null }
        case 'EDIT_ITEM_FROM_SALE_SUCCESS':
            return { ...state, isSending: false, errors: null }
        case 'EDIT_ITEM_FROM_SALE_FAILURE':
            return { ...state, isSending: false, errors: action.errors }
        case 'UPDATE_PRICE_FROM_PROVIDER_SUCCESS':
            return { ...state, isSending: false, errors: null, productsWithoutPrice: action.productsWithoutPrice }
        case 'UPDATE_PRICE_FROM_PROVIDER_FAILURE':
            return { ...state, isSending: false, errors: action.errors }
        case LOCATION_CHANGE:
            return { ...state, productsWithoutPrice: [] }
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export const findSaleItemById = (state, id) => {
    if (state.sale.saleItem?.id == id) {
        return state.sale.saleItem
    }
    if (!state.sale.saleItems || !state.sale.saleItems.length) {
        return null
    }
    let found = state.sale.saleItems.find((item) => {
        return item.id == id
    })
    return found
}
export const getError = (state) => {
    return state.products.error
}

export const getSaleItems = (state, id) => {
    return state.sale.saleItems
}

export const getSaleItemsTableInfo = (state) => {
    const saleItemsState = state.sale
    const { page, pagesCount, pageSize, total } = saleItemsState
    return { page, pagesCount, pageSize, total }
}

export const getSaleItemsExpansionsAvailableList = (state) => {
    return state.sale.expansionsList
}

export const getIsLoading = (state) => {
    return state.sale.isLoading
}

export const isSending = (state) => {
    return state.sale.isSending
}

export const getSaleErrorForms = (state) => {
    return state.sale.errors
}

export const productsWithoutPrice = (state) => {
    return state.sale.productsWithoutPrice
}

export default sale
