import React from 'react'

const WantToBuyScreen = (props) => {
    return (
        <div className="mb-3 container-fluid">
            <div>
                <h1>Your cards, your way. Create a buylist!</h1>
                <p>
                    Can't believe it, can you? It surely left you with a twisted neck just by reading it. <br />
                    But yes, your myopic eyes are not deceiving you. <br />
                    You read it right, my buddy, the buylists have arrived!<br />
                </p>
                <p>
                    At CardsandCollections, you have the amazing power to create your very own buylist on your own website. <br />
                    Yes, you read that right! The highly anticipated buylists are here to stay. <br />
                    So, grab a seat, get your popcorn ready, and let the party begin!<br />
                </p>
                <p>
                    Now you can advertise the items you want to buy, whether it's to complete your collections, your favourite decks, or simply find the perfect gift for Mother's Day. <br />
                    At CardsandCollections, you have the opportunity to search for and find those special items you're looking for. <br />
                    Whether you're in search of that missing card to complete your card set or a specific deck to enhance your gameplay strategy, our platform provides you with the possibility to connect with trusted sellers.<br />
                </p>
                <p>
                    To create your buylist, you will need to follow these steps: 
                </p>
                <p>
                    Step 1: Complete registration on the platform:
                    <ul>
                        <li>Fill out the registration form on the platform.</li>
                        <li>Provide your personal details, including your address.</li>
                        <li>Verify your bank account following the provided instructions.</li>
                    </ul>
                </p>
                <p>
                    Step 2: Access your dashboard: 
                    <ul>
                        <li>Log in to your account and navigate to your dashboard.</li>
                        <li>Locate the "Want to buy" option.</li>
                        <li>Click on it to initiate the buying process.</li>
                    </ul>
                </p>
                <p>
                    Step 3: Choose the item's details and price: 
                    <ul>
                        <li>
                            Select the specific item you want to buy.
                        </li>
                        <li>
                            Provide the relevant details such as its condition, edition, language, etc.
                        </li>
                        <li>
                            Enter the desired price for the item.  
                        </li>
                    </ul>
                </p>
                <p>
                    Additionally, you can also list an item for buy from the individual card page. <br />
                    When you find the item, you want to buy in the search results, select the "Add to Buy" option to input the item's details and price.<br />
                </p>
                <p>
                    But how is all this carried out? <br />
                    By using our buylist credit. <br />
                    What does this credit entail? It is the amount of money you allocate from your total credit to your buylist ads, making sure it doesn't exceed the available credit in your CardsandCollections account. <br />
                    For example, if your buylist credit has a value of $6, it means that other users will be able to see and respond to your buylist ads that are equal to or less than $6. <br />
                    Now, does this mean that if I have a buylist ad for an item higher than $6 it will be deleted? <br />
                    Not at all, my friend! <br />
                    Other users simply won't be able to see it until you increase your balance to $9 or more. <br />
                    At that point, they will be able to view it and respond to your offer.<br />
                </p>
                <p>
                    To access your buylist budget, you need to go to the top left window, click on the initial of your nickname, and in the credit section, you will find "Buylist budget".<br />
                </p>
                <p>
                    It is highly recommended, my faithful friend, that you use this option wisely. <br />
                    Try to keep your buylist ads up to date, and if you are no longer interested, delete them. <br />
                    This way, if you increase your balance again, you won't have old ads that are no longer relevant appearing. <br />
                    Remember, be organized! I know you can do it!<br />
                </p>
                <p>
                    Little trick, my friend. <br />
                    If you ever need to take a break and enjoy a well-deserved vacation on a paradise beach or if you are passionate about volunteering for the conservation of adorable quokkas (Setonix brachyurus) in Australia, you always have the option to decrease your buylist budget to zero. <br />
                    This way, your buylist items won't be visible to other users until you decide to increase your balance again. <br />
                    Remember, enjoy life! It's not all about cards.<br />
                </p>               
            </div>
        </div>
    )
}

export default WantToBuyScreen