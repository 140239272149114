import * as api from '../api'
import { getIsLogged, getToken } from '../login/reducers'


export const fetchMyNotifications = () => (dispatch, getState) => {
    const isLogged = getIsLogged(getState())
    if (!isLogged) return

    dispatch({
        type: 'FETCH_MY_NOTIFICATIONS_REQUEST',
    })

    return api
        .fetchMyNotifications(getToken(getState()))
        .then((response) => {
            return dispatch({ type: 'FETCH_MY_NOTIFICATIONS_SUCCESS', notifications: response.data.notifications })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_MY_NOTIFICATIONS_FAILURE', response: error.response })
        })
}
