import React from 'react'
import moment from 'moment'
import { Player } from 'video-react';

export const ChatMessage = ({ isOwnMessage, messageData, userToken }) => {
    const {
        created_at,
        user_sender_id,
        is_admin_message,
        message,
        message_type,
        ...other
    } = messageData;
    const multimediaUrl = `${process.env.REACT_APP_API_HOST}/api/chats/get-multimedia-message/${other.id}?authToken=${userToken}`;

    const isVideo = message.includes('.mp4');

    const downloadMultimedia = (event) => {
        event.preventDefault();
        window.open(multimediaUrl,'_blank');
    }
    return (
            <div className={`message ${isOwnMessage ? 'message--sent' : 'message--received'}`}>
                {message_type === 'MULTIMEDIA' && <React.Fragment>
                    {isVideo ?
                        <div style={{ margin: '0 auto', maxWidth: '250px',  width: '250px', height: '200px'}}>
                            <Player
                                playsInline
                            >
                                <source src={multimediaUrl}/>
                            </Player>
                        </div>
                        : <img style={{ width: '150px', height: '150px', cursor: 'pointer' }}
                          onClick={(event) => downloadMultimedia(event)}
                          src={multimediaUrl} />
                    }
                </React.Fragment>
                }
                {message_type === 'TEXT' && <span>{message}</span> }

                <span className='message__metadata'>
                    <span className='metadata__time'>
                        {moment(created_at)
                            .format('DD MMM HH:mm')
                            .toString()}
                    </span>
                </span>
            </div>
    )
}
