import { connect } from 'react-redux'

import Error from './Error'
import { getAlert } from '../../store/error/reducers'

const mapStateToProps = state => {
    return {
        alert: getAlert(state)
    }
}

const ErrorContainer = connect(mapStateToProps, null)(Error)

export default ErrorContainer
