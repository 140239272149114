import * as api from '../api'
import { getToken } from '../login/reducers'
import { getLanguage } from '../ui/reducers'
import { goBack } from 'react-router-redux'

export const fetchCollectionById = (collectionId) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_COLLECTION_REQUEST', collectionId })
    return api
        .fetchCollectionById(getToken(getState()), collectionId)
        .then((response) => {
            return dispatch({ type: 'FETCH_COLLECTION_SUCCESS', collection: response.data})
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_COLLECTION_FAILURE', response: error.response })
        })
}

export const fetchCollections = (page, pageSize, filters) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_COLLECTIONS_REQUEST' })
    return api
        .fetchCollections(getToken(getState()), page, pageSize, filters )
        .then((response) => {
            return dispatch({ type: 'FETCH_COLLECTIONS_SUCCESS', collectionItems: response.data.data, expansionsList: response.data.expansionsList})
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_COLLECTIONS_FAILURE', response: error.response })
        })
}


export const addToCollection = (collectionItem) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'ADD_TO_COLLECTION_REQUEST', collectionItem })
    return api
        .addToCollection(getToken(getState()), collectionItem)
        .then((response) => {
            return dispatch({ type: 'ADD_TO_COLLECTION_SUCCESS' })
        })
        .catch((error) => {
            let errors = null
            if (error.response && error.response.status === 422) {
                errors = error.response.data.errors;
            }
            return dispatch({ type: 'ADD_TO_COLLECTION_FAILURE', response: error.response, errors })
        })
}
export const editItemFromCollection = (collectionItem) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'EDIT_ITEM_FROM_COLLECTION_REQUEST', collectionItem })
    return api
        .editItemFromCollection(getToken(getState()), collectionItem)
        .then((response) => {
            dispatch(goBack())
            return dispatch({ type: 'EDIT_ITEM_FROM_COLLECTION_SUCCESS' })
        })
        .catch((error) => {
            let errors = null
            if (error.response && error.response.status === 422) {
                errors = error.response.data.errors;
            }
            return dispatch({ type: 'EDIT_ITEM_FROM_COLLECTION_FAILURE', response: error.response, errors })
        })
}
export const deleteItemFromCollection = (itemId) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'REMOVE_FROM_COLLECTION_REQUEST', itemId })
    return api
        .deleteItemFromCollection(getToken(getState()), itemId)
        .then((response) => {
            return dispatch({ type: 'REMOVE_FROM_COLLECTION_SUCCESS' })
        })
        .catch((error) => {
            return dispatch({ type: 'REMOVE_FROM_COLLECTION_FAILURE', response: error.response })
        })
}


export const updateQtyItemFromCollection = (itemId, qty) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'UPDATE_QTY_ITEM_OF_COLLECTION_REQUEST', itemId, qty })
    return api
        .updateQtyItemFromCollection(getToken(getState()), itemId, qty)
        .then((response) => {
            return dispatch({ type: 'UPDATE_QTY_ITEM_OF_COLLECTION_SUCCESS' })
        })
        .catch((error) => {
            return dispatch({ type: 'UPDATE_QTY_ITEM_OF_COLLECTION_FAILURE', response: error.response })
        })
}
