import React from 'react'

import LocalizedLink from '../global/LocalizedLink'
// import iconProfile from '../../assets/img/icons/profile.svg'
import { injectIntl } from 'react-intl'
import ProfileLinks from '../header/ProfileLinks'

const UserProfileModal = ({ show, onLogin, onClose, callback, onLogout, username, userimage, intl, name, totalUnassignedReservations, userType, email, isLogged }) => {
    return (
        <div>
            <div className="modal-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
            <div id="user-profile-modal-container" className="user-profile" style={{ display: show ? 'block' : 'none' }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 ">
                            {/* <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'orders', open: '' }}>
                                <header className="container-fluid">
                                    {userimage && <img alt="" src={userimage} className="icon" style={{ borderRadius: userimage ? '100%' : '' }} />}
                                    {name}
                                </header>
                            </LocalizedLink> */}
                            <div className="user-profile-menu">
                                <ul>
                                    <ProfileLinks />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default injectIntl(UserProfileModal)
