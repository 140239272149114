import React from 'react'

const HowToUseScreen = (props) => {
    return (
        <div className="mb-3 container-fluid">
            <div>
                <h1>Under construction</h1>
             </div>
        </div>
    )
}

export default HowToUseScreen