import React from 'react'

import InputNumberView from './InputNumberView'

class InputNumber extends React.Component {
    render() {
        let { value, change, size = '', step = 1, min = null, limit = null, decimals = 0, allowNull = false } = this.props
        let lvlPrecision = decimals > 4 ? decimals : 4

        if (limit && parseFloat(limit, 10) <= 0) value = 0
        const changeValue = (val, button = '') => {
            let newValue = val
            // Si el nuevo valor no es divisible por el step: toma el valor inmediatamente menor
            if (val % step !== 0) newValue = (step * parseInt((val / step).toFixed(lvlPrecision), 10)).toFixed(decimals)
            // En el caso de tener límite y el núevo valor está por encima de la diferencia del límite con el step: tomará el valor del límite
            // Para los casos de entrada en el input y el botón plus
            if (limit && (val > limit || limit - step < val)) newValue = limit
            // Para el botón minus: En el caso de estár en el límite y restar 1 step, restará la diferencia y un step ej.
            // valor 11, step 3, --> nuevo valor debería ser 6  (de 6 a 11 y de 11 a 6)
            if (limit && (button === 'minus' && (val > limit || limit - step <= val))) newValue = (step * parseInt((val / step).toFixed(lvlPrecision), 10)).toFixed(decimals)
            //Si el valor está por debajo del min estando definido
            if (min && val < min) newValue = min
            //Si el valor está por debajo del step sin estar el min definido
            else if (val < step) newValue = step

            // si permite null, modificamos comportamiento para que pueda gardar 0 en la tabla de admin mktools
            if (allowNull) {
                if (val == 0 || val == '' || val == null) {
                    newValue = 0
                } else if (val < step) {
                    newValue = step
                }
            }
            //Si el valor no es un número
            if (isNaN(val) && !allowNull) newValue = min || step
            //Si el límite es 0, el valor es 0
            if (limit !== null && limit <= 0) newValue = 0
            //Si el límite es menor que el mínimo, el valor es 0
            if (limit < min) newValue = 0

            if (change !== undefined) {
                change(parseFloat(newValue))
            }

            return parseFloat(newValue)
        }

        return <InputNumberView value={value} onChange={changeValue} step={step} size={size} decimals={decimals} allowNull={allowNull} limit={limit} />
    }
}

export default InputNumber
