import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../../../../components/global/Translation'
import { useFormik } from 'formik'
import Button from '../../../../components/global/Button'
import * as Yup from 'yup'
import { getAlert, getBankData } from '../../../../store/login/reducers'
import {
    getBankValidationInfo,
    updateBankDataValidation,
    updateFinancialInformation
} from '../../../../store/login/actions'
import Alerts from '../../../../components/alerts/Alerts'
import Modal from '../../../../components/global/Modal'

const BankDataValidationForm = (props) => {

    const dispatch = useDispatch()
    const bankData = props.bankData

    defineMessages({
        DEPOSIT_MUST_BE_A_NUMBER: {
            id: 'DEPOSIT_MUST_BE_A_NUMBER',
            description: 'DEPOSIT_MUST_BE_A_NUMBER',
            defaultMessage: 'Deposit must be a number'
        },
        WRONG_DEPOSIT_VALIDATION: {
            id: 'WRONG_DEPOSIT_VALIDATION',
            description: 'WRONG_DEPOSIT_VALIDATION',
            defaultMessage: 'Incorrect deposit validation'
        },
        VALIDATE: {
            id: 'VALIDATE',
            description: 'VALIDATE',
            defaultMessage: 'Validate'
        },
        VALIDATED_BANK_ACCOUNT: {
            id: 'VALIDATED_BANK_ACCOUNT',
            description: 'VALIDATED_BANK_ACCOUNT',
            defaultMessage: 'Your bank account has been validated'
        }
    })

    const [showModal, setModal] = useState(false)


    // Formulario

    const initialValues = {
        dep1: '',
        dep2: '',
        attempts: bankData.attempts
    }

    const validationSchema = Yup.object({
        dep1: Yup.number()
            .typeError(props.intl.formatMessage({ id: 'DEPOSIT_MUST_BE_A_NUMBER' }))
            .required(props.intl.formatMessage({ id: 'REQUIRED' }))
            .min(0.01),
        dep2: Yup.number()
            .typeError(props.intl.formatMessage({ id: 'DEPOSIT_MUST_BE_A_NUMBER' }))
            .required(props.intl.formatMessage({ id: 'REQUIRED' }))
            .min(0.01),
        attempts: Yup.number()
            .required()
            .min(0)
            .max(2)
    })

    const onSubmit = (values, action) => {
        dispatch(updateBankDataValidation(values))
        action.resetForm({ values })
    }

    const handleClickSubmit = event => {
        formik.submitForm()
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    return (
        <React.Fragment>
            <div className='offset-md-1 col-10 mt-3'>
                <form className='form'>
                    <div className='row'>
                        <h1>
                            <Translation id='validation_status' defaultMessage='Validation status' />
                        </h1>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mb-3'>
                                    <div className='col-md-4'>
                                        <label htmlFor='dep1'>
                                            <Translation id='dep1' defaultMessage='Deposit 1' />*
                                        </label>
                                        <input
                                            className='form-control mb-3'
                                            type='number'
                                            id='dep1'
                                            name='dep1'
                                            placeholder={props.intl.formatMessage({ id: 'dep1' })}
                                            value={formik.values.dep1}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            step='0.01'
                                        />
                                        {formik.touched.dep1 && formik.errors.dep1 ? (
                                            <div style={{ color: 'red' }}>{formik.errors.dep1}</div>
                                        ) : null
                                        }
                                    </div>
                                    <div className='col-md-4'>
                                        <label htmlFor='dep2'>
                                            <Translation id='dep2' defaultMessage='Deposit 2' />*
                                        </label>
                                        <input
                                            className='form-control mb-3'
                                            type='number'
                                            id='dep2'
                                            name='dep2'
                                            placeholder={props.intl.formatMessage({ id: 'dep2' })}
                                            value={formik.values.dep2}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            step='0.01'
                                        />
                                        {formik.touched.dep2 && formik.errors.dep2 ? (
                                            <div style={{ color: 'red' }}>{formik.errors.dep2}</div>
                                        ) : null
                                        }
                                    </div>
                                    <div className='col-md-4'>
                                        <label htmlFor='attempts'>
                                            <Translation id='attempts' defaultMessage='Attempts' />
                                        </label>
                                        <input
                                            className='form-control mb-3'
                                            type='text'
                                            id='attempts'
                                            name='attempts'
                                            value={bankData.attempts}
                                            disabled
                                        />
                                        {formik.values.attempts > 2 &&
                                            <h2 style={{ color: 'red' }}><
                                                Translation id='attempsError'
                                                            defaultMessage='Max attemps error allowed, please contact with administrator' />
                                            </h2>
                                        }
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-'>
                                        <Button type='submit'
                                                disabled={!formik.dirty || !formik.isValid}
                                                onClick={handleClickSubmit}>{'VALIDATE'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default withRouter(injectIntl(BankDataValidationForm))
