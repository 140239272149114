import React from 'react'
import { connect, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter,Route,Switch } from 'react-router-dom'
import AddToSaleForm from '../components/sale/AddToSaleForm'
import Alerts from '../components/alerts/Alerts'
import { getBankData } from '../store/login/reducers'

const AddToSaleScreen = (props,intl) => {

    const bankData = useSelector(state => getBankData(state))

    if(!bankData?.validated)
    {
        return (
            <div className='col-12'>
                <Alerts alert={"FILL_FINANCIAL_INFO_TO_TRADE"} status={"warning"} />
            </div>
        )
    }

    return (

        <div className="container-fluid">
        {/* <h2>add to collection</h2> */}
            <AddToSaleForm></AddToSaleForm>
        </div>
    )
}

export default withRouter(injectIntl(connect()(AddToSaleScreen)))
