import React from 'react'
import SummaryContainer from '../components/summary/SummaryContainer'

const SummaryScreen = (props) => (
    <div className="container">
            <SummaryContainer {...props} />
    </div>
)

export default SummaryScreen
