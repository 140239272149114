import { setCollectableContext } from './../../store/products/actions';
import { getHasFilledShippingAddress } from './../../store/login/reducers';
import { push } from 'react-router-redux';
import { connect } from 'react-redux'
import Header from './Header'
import { showingProfileModal, getCurrentUrl } from '../../store/ui/reducers'
import { getUserType, getIsLogged,getUserName } from '../../store/login/reducers'
import { getCurrentCollectableContext } from '../../store/products/reducers'


const mapStateToProps = (state) => {
    return {
        showMenu: showingProfileModal(state),
        userType: getUserType(state),
        username: getUserName(state),
        isLogged: getIsLogged(state),
        currentUrl: getCurrentUrl(state).pathname,
        hasFilledShippingAddress: getHasFilledShippingAddress(state),
        collectableContext: getCurrentCollectableContext(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (url) => {
            dispatch(push(url))
        },
        setCollectableContext: (context) => {
            dispatch(setCollectableContext(context))
        },
    }
}

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderContainer
