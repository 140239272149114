import React, { useRef, useState } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import Modal from './Modal'
import Translation from './Translation'
import Button from './Button'

const QrCode = ({url}) => {
    const qrRef = useRef()
    const downloadQRCode = (e) => {
        e.preventDefault()
        let canvas = qrRef.current.querySelector("canvas");
        let image = canvas.toDataURL("image/png");
        let anchor = document.createElement("a");
        anchor.href = image;
        anchor.download = `qr of my public profile link.png`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }


    const qrcode = (
        <QRCodeCanvas
            id='qrCode'
            value={url}
            size={300}
            // bgColor={'#00ff00'}
            level={'H'}
        />
    )
    return (
        <div className='qrcode__container'>
            <div ref={qrRef}>{qrcode}</div>
            <div className='input__group'>
                <form onSubmit={downloadQRCode}>
                    <button type='submit' disabled={!url} className="bt">
                        Download QR code
                    </button>
                </form>
            </div>
        </div>
    )
}


export const QrDownloadModal = ({ url, title }) => {
    const [showModal, setShowModal] = useState(false)
    return (
        <React.Fragment>
            <div>
                <Button className='bt-size-medium' onClick={() => setShowModal(true)}>
                    <Translation id='public_profile_qr_generator' defaultMessage='Qr of my public profile link' /> 🔗
                </Button>
            </div>
            {showModal && <Modal
                title={title}
                onClose={() => setShowModal(false)}
            >
                <QrCode
                        url={url}
                    />
            </Modal>}
        </React.Fragment>
    )
}
