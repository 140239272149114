import React from 'react'
import Spinner from '../global/Spinner'
import FlagIcon from '../global/FlagIcon'

import Translation from '../global/Translation'
import { injectIntl } from 'react-intl'
import { useSelector, connect } from 'react-redux';
import { getAvailableLangs } from '../../store/products/reducers'

const DisplayLanguage = ({lang}) => {
    const availableLang = useSelector((state) => getAvailableLangs(state))
    const langItem = availableLang.find(item => {
        return item.value == lang
    })
    // console.log(lang)
        return (
            <div>
                {/* <img src={langItem.img} style={{ width: '32px', marginTop: '8px' }} /> */}
                <FlagIcon size={'2x'} code={langItem.flagValue.toLowerCase()} />
                {/* {' '}{langItem.label} */}
            </div>
        )

   

}

export default injectIntl(connect()(DisplayLanguage))
