import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import { fetchCreditRetrieveIngress } from '../store/user/actions'
import { getCreditRetrieveIngress } from '../store/user/reducers'

const CreditHistoryScreen = (props) => {
    // llamar a pi para coger los registros
    const dispatch = useDispatch()
    const creditRetrieveIngress = useSelector((state) => getCreditRetrieveIngress(state))

    useEffect(() => {
        dispatch(fetchCreditRetrieveIngress())
    }, [])

    if (!creditRetrieveIngress || creditRetrieveIngress?.length == 0)
        return (
            <div className="card">
                <div className="card-body">
                    <h2 class="col-12">
                        <Translation id="no_credit_history" defaultMessage="No credit history yet" />
                    </h2>
                </div>
            </div>
        )

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 ">
                        <h2>
                            <Translation id="credit_history" defaultMessage="Credit history" />
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div className="card">
                            <div className="card-body">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <Translation id="credit_before" defaultMessage="Credit Before" />
                                            </th>
                                            <th scope="col">
                                                <Translation id="credit_requested" defaultMessage="Credit Request" />
                                            </th>
                                            <th scope="col">
                                                <Translation id="credit_after" defaultMessage="Credit After" />
                                            </th>
                                            <th scope="col">
                                                <Translation id="date" defaultMessage="Date" />
                                            </th>
                                            <th scope="col">
                                                <Translation id="type" defaultMessage="Type" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {creditRetrieveIngress.map((item) => (
                                            <tr>
                                                <td>{item.credit_before}</td>
                                                <td>{item.credit_requested}</td>
                                                <td>{item.credit_after}</td>
                                                <td>{item.created_at}</td>
                                                <td>{item.type === 'ingress' ? <i class="fas fa-plus text-success"></i> : <i class="fas fa-minus text-danger"></i>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(CreditHistoryScreen)))
