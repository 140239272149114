import { FormInput } from '../../global/FormInput'
import Translation from '../../global/Translation'
import React from 'react'

export const ItemPriceInput = ({errorsForm, defaultPrice, setValue, setRef}) => {

    return (
        <div className='row price'>
            <FormInput
                id='price'
                name='price'
                type='number'
                showRequired={false}
                // className="price"
                min={0.01}
                step={0.01}
                required={true}
                label={<Translation id='price' defaultMessage='Price on S$' />}
                errors={errorsForm}
                defaultValue={defaultPrice}
                onChange={(e) => setValue(e.target.value)}
                placeholder={'00.00'}
                setRef={setRef}
                style={{
                    flexDirection: 'row',
                    background: '#ffc466',
                    padding: '8px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                    // gridTemplateColumns: '1fr 1fr',
                    // justifyContent: 'center',
                }}
            />
            {/* <span style={{ position: 'relative', bottom: '40px', right: '-85%' }}>S$</span> */}
        </div>
    )
}
