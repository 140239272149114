import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import FinancialInformationContainer from './FinancialInformationContainer'

const FinancialInformation = (props) => {

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <FinancialInformationContainer />
          </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(FinancialInformation)))
