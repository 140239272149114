import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import PersonalInformation from '../components/user/profile/personal-info/PersonalInformation'
import ShippingInformation from '../components/user/profile/shipping-info/ShippingInformation'
import FinancialInformation from '../components/user/profile/financial-info/FinancialInformation'
import BusinesConfigForm from '../components/user/profile/business-options/BusinesConfigForm'
import ProfileSumarry from '../components/user/profile/ProfileSummary'

const MyProfileScreen = (props) => {

    return (
        <div>
            <ProfileSumarry />
            <PersonalInformation />
            <ShippingInformation />
            <FinancialInformation />
            <BusinesConfigForm />
        </div>
    )
}

export default withRouter(injectIntl(connect()(MyProfileScreen)))

