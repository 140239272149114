import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
// import mallDiscount from '../../assets/img/logos/mall-discount.svg'

defineMessages({
    ACCOUNT_CREATED_SUCCESSFULLY: {
        id: 'ACCOUNT_CREATED_SUCCESSFULLY',
        description: 'ACCOUNT_CREATED_SUCCESSFULLY',
        defaultMessage: 'Account created successfully',
    },
    CART_LINE_ADDED_SUCCESS: {
        id: 'CART_LINE_ADDED_SUCCESS',
        description: 'CART_LINE_ADDED_SUCCESS',
        defaultMessage: 'Cart line added successfully',
    },
    CART_LINE_REMOVED_SUCCESS: {
        id: 'CART_LINE_REMOVED_SUCCESS',
        description: 'CART_LINE_REMOVED_SUCCESS',
        defaultMessage: 'Cart line removed successfully',
    },
    EMPTY_CART_SUCCESS: {
        id: 'EMPTY_CART_SUCCESS',
        description: 'EMPTY_CART_SUCCESS',
        defaultMessage: 'Empty cart success',
    },
    CART_TYPE_INCOMPATIBLE_EXCEPTION: {
        id: 'CART_TYPE_INCOMPATIBLE_EXCEPTION',
        description: 'CART_TYPE_INCOMPATIBLE_EXCEPTION',
        defaultMessage: 'You cant make a buy and sell at the same time',
    },
    SUCCESS: {
        id: 'SUCCESS',
        description: 'SUCCESS',
        defaultMessage: 'Success!',
    },
    PRODUCT_ADDED_SUCCESFULLY: {
        id: 'PRODUCT_ADDED_SUCCESFULLY',
        description: 'PRODUCT_ADDED_SUCCESFULLY',
        defaultMessage: 'Product added succesfully',
    },
    CHANGES_SAVED_SUCCESSFULLY: {
        id: 'CHANGES_SAVED_SUCCESSFULLY',
        description: 'CHANGES_SAVED_SUCCESSFULLY',
        defaultMessage: 'Changes saved succesfully',
    },
    SOMETHING_WENT_WRONG: {
        id: 'SOMETHING_WENT_WRONG',
        description: 'SOMETHING_WENT_WRONG',
        defaultMessage: 'Something went wrong',
    },
    PRODUCT_REMOVED_SUCCESSFULLY: {
        id: 'PRODUCT_REMOVED_SUCCESSFULLY',
        description: 'PRODUCT_REMOVED_SUCCESSFULLY',
        defaultMessage: 'Product removed succesfully',
    },
    PRODUCT_UPDATED_SUCCESSFULLY: {
        id: 'PRODUCT_UPDATED_SUCCESSFULLY',
        description: 'PRODUCT_UPDATED_SUCCESSFULLY',
        defaultMessage: 'Product updated successfully',
    },
    REGISTER_SUCCESS: {
        id: 'REGISTER_SUCCESS',
        description: 'REGISTER_SUCCESS',
        defaultMessage: 'Register Success',
    },
    RETRIEVE_CREDIT_SUCCESS: {
        id: 'RETRIEVE_CREDIT_SUCCESS',
        description: 'RETRIEVE_CREDIT_SUCCESS',
        defaultMessage: 'Your credit retrieve has been request successfully',
    },
    BUY_CREDIT_UPDATED_SUCCESS: {
        id: 'BUY_CREDIT_UPDATED_SUCCESS',
        description: 'BUY_CREDIT_UPDATED_SUCCESS',
        defaultMessage: 'Your buy credit has been updated to S$ {buyCredit}',
    },
    SHIPPING_ADDRESS_REQUIRED: {
        id: 'SHIPPING_ADDRESS_REQUIRED',
        description: 'SHIPPING_ADDRESS_REQUIRED',
        defaultMessage: 'Please fill your shipping address',
    },
    SALE_ITEM_IS_BLOCKED: {
        id: 'SALE_ITEM_IS_BLOCKED',
        description: 'SALE_ITEM_IS_BLOCKED',
        defaultMessage: "It cannot be deleted, because this item is in a customer's cart.",
    },
    FAIL_TO_FETCH_ORDER_DATA: {
        id: 'FAIL_TO_FETCH_ORDER_DATA',
        description: 'FAIL_TO_FETCH_ORDER_DATA',
        defaultMessage: 'Fail to fetch order data',
    },
    UPDATE_PRICE_FROM_PROVIDER_SUCCESS: {
        id: 'UPDATE_PRICE_FROM_PROVIDER_SUCCESS',
        description: 'UPDATE_PRICE_FROM_PROVIDER_SUCCESS',
        defaultMessage: 'Price updated successfully',
    },
    PAYMENT_CREATE_REQUEST_ERROR: {
        id: 'PAYMENT_CREATE_REQUEST_ERROR',
        description: 'PAYMENT_CREATE_REQUEST_ERROR',
        defaultMessage: 'Payment request create error',
    },
    CHECKOUT_ERROR: {
        id: 'CHECKOUT_ERROR',
        description: 'CHECKOUT_ERROR',
        defaultMessage: 'Checkout error, please try again later',
    }
})

class FloatingAlert extends Component {
    componentWillUnmount() {
        this.props.onClose && this.props.onClose()
    }
    render() {
        const { children, onClose, sucessMessage, failureMessage, warningMessage, values, className, pricing, hidden, mallContainerDiscount } = this.props

        const style = failureMessage ? 'alert__failure fadeinup' : warningMessage ? 'alert__warning fadeinup' : 'alert__success fadeinup'
        let classes = className + ' ' + style
        if (!children && !failureMessage && !sucessMessage && !warningMessage) return null
        if (!pricing) {
            setTimeout(onClose, 8000)
        }
        if (hidden) {
            classes += ' d-none'
        }
        return (
            <div className={classes} style={{ animationDelay: '0.1s' }}>
                {onClose && (
                    <span className="alert__close" onClick={onClose}>
                        &times;
                    </span>
                )}
                <span className="alert__title">{this.props.intl.formatMessage({ id: children || failureMessage || sucessMessage || warningMessage }, values ? values : {})}</span>
                <p className="alert__message" />
            </div>
        )
    }
}
export default injectIntl(FloatingAlert)
