import { LOCATION_CHANGE } from 'react-router-redux'

export const initState = {
    showMenu: false,
    showProfileModal: false,
    showLanguageModal: false,
    isLogging: false,
    language: null,
    availableLands: [
        {
            name: 'Italiano',
            code: 'it',
            localecode: 'it',
            langName: 'Italiano',
            dir: 'ltr',
            sfLang: 'it',
        },
        {
            name: 'Deutsch',
            code: 'de',
            localecode: 'de',
            langName: 'Deutsch',
            dir: 'ltr',
            sfLang: 'de',
        },
        {
            name: 'Español',
            code: 'es',
            localecode: 'es',
            langName: 'Español',
            dir: 'ltr',
            sfLang: 'es',
        },
        {
            name: 'English',
            code: 'gb',
            localecode: 'en',
            langName: 'English',
            dir: 'ltr',
            sfLang: 'en_GB',
        },
        {
            name: 'Français',
            code: 'fr',
            localecode: 'fr',
            langName: 'Français',
            sfLang: 'fr',
        },
    ],
    currentUrl: null,
}
const ui = (state = initState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, {
                showMenu: false,
                showProfileModal: false,
                currentUrl: { pathname: action.payload.location.pathname, search: action.payload.location.search },
                //TODO - update i18n url mall landing template
            })

        case 'SET_LANGUAGE':
            return Object.assign({}, state, { language: action.locale })
        case 'SHOW_LANGUAGE_MODAL':
            return Object.assign({}, state, { showLanguageModal: true })
        case 'CLOSE_LANGUAGE_MODAL':
            return Object.assign({}, state, { showLanguageModal: false })

        case 'SHOW_PROFILE_MODAL':
            return Object.assign({}, state, { showProfileModal: true })
        case 'CLOSE_PROFILE_MODAL':
            return Object.assign({}, state, { showProfileModal: false })
        case 'SHOW_MENU':
            return Object.assign({}, state, { showMenu: true })
        case 'CLOSE_MENU':
            return Object.assign({}, state, { showMenu: false })
        case 'RESET_UI':
            return Object.assign({}, state, {
                showMenu: false,
                showProfileModal: false,
                showLanguageModal: false,
            })
        case 'LOGIN_REQUEST':
            return Object.assign({}, state, { isLogging: true })
        case 'LOGIN_SUCCESS':
            return Object.assign({}, state, { isLogging: false })
        case 'CLOSE_CHECKOUT_MODAL':
            return Object.assign({}, state, { isLogging: false })
        case 'LOGIN_FAILURE':
            return Object.assign({}, state, { isLogging: false })
        default:
            return state
    }
}

export const showMenuSelector = (state) => {
    return state.ui.showMenu
}
export const showLanguageSelector = (state) => {
    return state.ui.showLanguageModal
}
export const showingProfileModal = (state) => {
    return state.ui.showProfileModal
}

// export const showingAnyModal = (state) => {
//     let showing = false
//     if (
//         impersonate.showModalSelector(state) ||
//         login.showModalSelector(state) ||
//         cart.showModalSelector(state) ||
//         cart.showCheckoutModalSelector(state) ||
//         state.ui.showProfileModal ||
//         state.ui.showSegmentationModal ||
//         state.ui.showLanguageModal ||
//         state.ui.showBundleContent
//     ) {
//         showing = true
//     }
//     return showing
// }
export const getUICallback = (state) => {
    return state.ui.callback
}
export const getLanguage = (state) => {
    return state.ui.language
}
export const getLanguageDir = (state) => {
    let lang = state.ui.availableLands.find((lang) => lang.localecode === state.ui.language)
    return lang && lang.dir
}

export const getCountry = (state) => {
    return state.ui.country
}
export const getAvailableLands = (state) => {
    return state.ui.availableLands
}
export const getCurrentUrl = (state) => {
    return state.ui.currentUrl
}

export default ui
