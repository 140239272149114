import { connect } from 'react-redux'
import LoginModal from './LoginModal'
import { getCallbackCenter, getIsLogging, getLoginCallback, mandatoryChangePassword, showModalSelector } from '../../store/login/reducers'
import { login, mandatoryChangePasswordRequest, recoverPassword } from '../../store/login/actions'
import * as ui from '../../store/ui/actions'

const mapStateToProps = state => {
    return {
        show: showModalSelector(state),
        showBigAccount: true,
        callback: getLoginCallback(state),
        isLogging: getIsLogging(state),
        // mandatoryChangePassword: mandatoryChangePassword(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogin: (username, password, callback, intl) => {
            dispatch(login(username, password, callback, intl))
        },
        onRecoverPassword: (email) => {
            dispatch(recoverPassword(email))
        },

        // onMandatoryChangePassword: (newPassword, newPasswordRepeat) => {
        //     dispatch(mandatoryChangePasswordRequest(newPassword, newPasswordRepeat))
        // },
        onClose: () => {
            dispatch(ui.closeLoginModal())
        }
    }
}

const LoginModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModal)

export default LoginModalContainer
