import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import { ChatPendingNotification, OrderNotification } from './NotificationInfo'
import { OrderMiniNotification } from '../mini-notifications/MiniNotificationInfo'
import Alerts from '../alerts/Alerts'


defineMessages({
    CHAT_PENDING_MESSAGE: {
        id: 'CHAT_PENDING_MESSAGE',
        description: 'CHAT_PENDING_MESSAGE',
        defaultMessage: 'Chat pending with {userName}',
    },
    ORDER_CONFIRMED_MESSAGE: {
        id: 'ORDER_CONFIRMED_MESSAGE',
        description: 'ORDER_CONFIRMED_MESSAGE',
        defaultMessage: 'Order has been confirmed by {userName}',
    },
    ORDER_NOT_CONFIRMED_MESSAGE: {
        id: 'ORDER_NOT_CONFIRMED_MESSAGE',
        description: 'ORDER_NOT_CONFIRMED_MESSAGE',
        defaultMessage: "Order hasn't been confirmed by {userName}",
    },
    ORDER_SENT_MESSAGE: {
        id: 'ORDER_SENT_MESSAGE',
        description: 'ORDER_SENT_MESSAGE',
        defaultMessage: 'Order has been sent by {userName}',
    },
    ORDER_RECEIVED_MESSAGE: {
        id: 'ORDER_RECEIVED_MESSAGE',
        description: 'ORDER_RECEIVED_MESSAGE',
        defaultMessage: '{userName} has received order',
    },
    ORDER_LOST_CONFIRMATION: {
        id: 'ORDER_LOST_CONFIRMATION',
        description: 'ORDER_LOST_CONFIRMATION',
        defaultMessage: 'Order has been set as lost by {userName}',
    },
    ORDER_CANCELLED_MESSAGE: {
        id: 'ORDER_CANCELLED_MESSAGE',
        description: 'ORDER_CANCELLED_MESSAGE',
        defaultMessage: 'Order has been cancelled by {userName}',
    },
    ORDER_VOTED_MESSAGE: {
        id: 'ORDER_VOTED_MESSAGE',
        description: 'ORDER_VOTED_MESSAGE',
        defaultMessage: '{userName} has voted the order',
    },
    PENDING_PICKUP: {
        id: 'PENDING_PICKUP',
        description: 'PENDING_PICKUP',
        defaultMessage: '{userName} has to pick up the order',
    },
    ORDER_EXPIRED: {
        id: 'ORDER_EXPIRED',
        description: 'ORDER_EXPIRED',
        defaultMessage: 'Order has been expired',
    },
})


const renderNotification = (notification) => {
    const config = {
        CHATS_PENDING: {
            props: {
                translationId: 'CHAT_PENDING_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: ChatPendingNotification,
        },
        ORDER_CONFIRMED: {
            props: {
                translationId: 'ORDER_CONFIRMED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderNotification,
        },
        ORDER_NOT_CONFIRMED: {
            props: {
                translationId: 'ORDER_NOT_CONFIRMED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderNotification,
        },
        ORDER_SENT: {
            props: {
                translationId: 'ORDER_SENT_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderNotification,
        },
        ORDER_RECEIVED: {
            props: {
                translationId: 'ORDER_RECEIVED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderNotification,
        },
        ORDER_RECEIVED_CONFIRMATION: {
            props: {
                translationId: 'ORDER_RECEIVED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderNotification,
        },
        ORDER_LOST_CONFIRMATION: {
            props: {
                translationId: 'ORDER_LOST_CONFIRMATION',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderNotification,
        },
        ORDER_CANCELLED: {
            props: {
                translationId: 'ORDER_CANCELLED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderNotification,
        },
        ORDER_VOTED: {
            props: {
                translationId: 'ORDER_VOTED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderNotification,
        },
        PENDING_PICKUP: {
            props: {
                translationId: 'PENDING_PICKUP',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_EXPIRED: {
            props: {
                translationId: 'ORDER_EXPIRED',
            },
            component: OrderNotification,
        },
    }
    const notificationData = config[notification.notification_type]
    return <notificationData.component
        notification={notification} {...notificationData.props}></notificationData.component>
}


class Notifications extends React.Component {

    componentDidMount() {
        this.props.fetchMyNotifications()
    }

    render() {
        const notifications = this.props.notifications ?? []
        return (
            <div className="row d-flex">
                <div>
                    <h3>
                        <Translation id="notifications" defaultMessage="Notifications" />
                    </h3>
                    <ul>
                        {notifications.map((notificationIt) => (
                            <React.Fragment key={notificationIt.id}>{renderNotification(notificationIt)}</React.Fragment>
                        ))}
                    </ul>
                    {notifications.length == 0 && <Alerts status="primary" alert="You have no pending notifications"></Alerts>}
                </div>
            </div>
        )


    }
}


export default injectIntl(Notifications)
