import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { defineMessages } from 'react-intl'

import Button from '../global/Button'
defineMessages({
    BACKEND_SERVICE_NOT_AVAILABLE: {
        id: 'BACKEND_SERVICE_NOT_AVAILABLE',
        description: 'BACKEND_SERVICE_NOT_AVAILABLE',
        defaultMessage: 'Internal Server Error'
    },
    CENTER_NOT_FOUND: {
        id: 'CENTER_NOT_FOUND',
        description: 'CENTER_NOT_FOUND',
        defaultMessage: 'Center not found'
    },
    RESERVATION_NOT_FOuND: {
        id: 'RESERVATION_NOT_FOuND',
        description: 'RESERVATION_NOT_FOuND',
        defaultMessage: 'Reservation not found'
    },
    NETWORK_ERROR: {
        id: 'NETWORK_ERROR',
        description: 'NETWORK_ERROR',
        defaultMessage: 'Network Error'
    },
    PRODUCT_NOT_FOUND: {
        id: 'PRODUCT_NOT_FOUND',
        description: 'Product not found',
        defaultMessage: 'Product not found'
    }
})
class Error extends React.Component {
    render() {
        const error = this.props.alert != null ? <Translation id={this.props.alert} /> : '404 Not Found'

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template">
                            <div>
                                <h1>Oops!</h1>
                                <h2>{error}</h2>
                                <div className="error-details">
                                    <Translation id="SORRY_PAGE_NOT_FOUND" defaultMessage="Sorry, an error has occured, Requested page not found!" />
                                </div>
                                <div className="error-actions">
                                    <div className="row">
                                        <div className="col-10 offset-1">
                                            <LocalizedLink routeId="ROUTE_HOME">
                                                <Button>
                                                    <Translation id="TAKE_ME_HOME" defaultMessage="Take me home" />
                                                </Button>
                                            </LocalizedLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Error
