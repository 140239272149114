import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'

import MenuLinks from './MenuLinks'
import ProfileLinks from './ProfileLinks'
export default class ResponsiveProfileMenu extends React.Component {
    render() {
        const userProfile = this.props.isLogged ? (
            <div>
                {/* <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'orders', open: '' }}>
                    <header className="container-fluid">
                        <div className="row ">
                            <div className="col-3 user-profile-responsive">
                                <img alt="" src={this.props.userimage} className="icon" style={{ borderRadius: this.props.userimage ? '100%' : '' }} />
                            </div>
                            <div className="col-9">{this.props.name}</div>
                        </div>
                    </header>
                </LocalizedLink> */}

                <ProfileLinks responsive={true} />
                {/* <hr /> */}
            </div>
        ) : (
            <li className="nav-item">
                <span className="nav-link" onClick={this.props.onShowUpLogin}>
                    <Translation id="LOGIN" defaultMessage="Login" />
                </span>
            </li>
        )

        return (
            <nav id="mobile-main-menu" className="d-lg-none navbar navbar-expand-lg navbar-light">
                <div className="navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {userProfile}
                        <MenuLinks responsive={true} />
                    </ul>
                </div>
            </nav>
        )
    }
}
