import React from 'react'
import Translation from '../global/Translation'

import CartLineContainer from '../cart-line/CartLineContainer'
import Spinner from '../global/Spinner'
import DisplayPrice from '../product/DisplayPrice'

const MiniCartSummary = ({
    lines,
    totalQty,
    total,
    onDelete,
    changeCartLineQty,
    shippingMethod,
    shippingMethods,
    onOpenBundleContent,
    editSegmentation,
    loadThisSegmentation,
    showSegmentationModal,
    changeCartLineSegmentation,
    coin,
    isIkeaCenter,
    calculatingLinesPrice,
    totalCoin,
    totalPrice,
    lang,
    hasFilledShippingAddress,
    verified,
    bankData
}) => {
    if(!hasFilledShippingAddress) {
        return (
            <div className="total cart-empty container-fluid">
                <div className="row">
                    <div className="label">
                        <Translation id="please_fill_your_shipping_addres" defaultMessage="Please fill your shipping address" />
                    </div>
                </div>
            </div>
        )
    }

    if(!verified) {
        return (
            <div className="total cart-empty container-fluid">
                <div className="row">
                    <div className="label">
                        <Translation id="VERIFY_YOUR_EMAIL"/>
                    </div>
                </div>
            </div>
        )
    }

    // if(!bankData || !bankData.validated) {
    //     return (
    //         <div className="total cart-empty container-fluid">
    //             <div className="row">
    //                 <div className="label">
    //                     <Translation id="FILL_FINANCIAL_INFO_TO_TRADE"/>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }
    if (totalQty <= 0) {
        return (
            <div className="total cart-empty container-fluid">
                <div className="row">
                    <div className="label">
                        <Translation id="your_cart_is_empty" defaultMessage="Your cart is empty" />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className={'item-list container-fluid'}>
                {lines.map(line => line && <CartLineContainer line={line} key={line.lineId} onlyView={line.extra && line.extra.itsFree} />)}
            </div>
            
                <div className="total container-fluid">
                    <div className="row" style={{padding:'10px'}}>
                        <div className="col">
                            <span className="label">
                                <Translation id="total_items" defaultMessage="Items" />
                            </span>
                            <span className="quantity">{totalQty}</span>
                        </div>

                
                                <div className="col text-right">
                                    <span className="label">
                                        <Translation id="total_import" defaultMessage="Import" />
                                    </span>
                             
                                    <span className="quantity">
                                        
                                            <DisplayPrice price={totalPrice} />
                                         
                                        
                                    </span>
                                </div>
                           
                    </div>
                </div>
            
        </div>
    )
}
export default MiniCartSummary
