export const initState = {
    collectionItems: [],
    expansionsList: [],
    collection: null,
    page: 0,
    pagesCount: 0,
    pageSize: 10,
    total: 0,
    error: null,
    isSending: false,
    isLoading: false,
    errors: null
}

const collection = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_COLLECTION_REQUEST':
            return { ...state, error: null, isLoading: true, collection: null, errors: null }
        case 'FETCH_COLLECTION_SUCCESS':
            return { ...state, error: null, isLoading: false, collection: action.collection, errors: null }
        case 'FETCH_COLLECTION_FAILURE':
            return { ...state, error: action.response, isLoading: false, errors: null }
        case 'FETCH_COLLECTIONS_REQUEST':
            return { ...state, error: null, isLoading: true, errors: null }
        case 'FETCH_COLLECTIONS_SUCCESS':
            const expansionList = action.expansionsList?.map(expansion => ({ value: expansion.expansionCode, label: `${expansion.expansionName} (${expansion.cardsCount})` })) ?? [];
            return {
                ...state,
                collectionItems: action.collectionItems.current_page === 1 ? action.collectionItems.data : state.collectionItems.concat(action.collectionItems.data),
                page: action.collectionItems.current_page,
                pagesCount: action.collectionItems.last_page,
                pageSize: action.collectionItems.per_page,
                total: action.collectionItems.total,
                expansionsList: [{value: null, label: 'Any'}, ...expansionList],
                isLoading: false,
                errors: null
            }
        case 'FETCH_COLLECTIONS_FAILURE':
            return { ...state, error: action.response, isLoading: false, errors: null }
        case 'ADD_TO_COLLECTION_REQUEST':
            return { ...state, isSending: true, errors: null }
        case 'ADD_TO_COLLECTION_SUCCESS':
            return { ...state, isSending: false, errors: null }
        case 'ADD_TO_COLLECTION_FAILURE':
            return { ...state, isSending: false, errors: action.errors }
        case 'EDIT_ITEM_FROM_COLLECTION_REQUEST':
            return { ...state, isSending: true, errors: null }
        case 'EDIT_ITEM_FROM_COLLECTION_SUCCESS':
            return { ...state, isSending: false, errors: null }
        case 'EDIT_ITEM_FROM_COLLECTION_FAILURE':
            return { ...state, isSending: false, errors: action.errors }
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export const findCollectionById = (state, id) => {
    if (state.collection.collection?.id == id) {
        return state.collection.collection
    }
    if (!state.collection.collectionItems || !state.collection.collectionItems.length) {
        return null
    }
    let found = state.collection.collectionItems.find((item) => {
        return item.id == id
    })
    return found
}
export const getError = (state) => {
    return state.products.error
}

export const getCollection = (state, id) => {
    return state.collection.collectionItems
}

export const getCollectionTableInfo = (state) => {
    const collectionState = state.collection
    const { page, pagesCount, pageSize, total } = collectionState
    return { page, pagesCount, pageSize, total }
}

export const getCollectionExpansionsAvailableList = (state) => {
    return state.collection.expansionsList;
}

export const isSending = (state) => {
    return state.collection.isSending
}
export const getIsLoading = (state) => {
    return state.collection.isLoading
}
export const getCollectionsErrorForms = (state) => {
    return state.collection.errors
}

export default collection
