// Language
export const setLanguage = (locale) => (dispatch, getState) => {
    dispatch({ type: 'SET_LANGUAGE', locale })
}
export const showLanguageModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_LANGUAGE_MODAL',
    })
}
export const CLOSE_LANGUAGE_MODAL = 'CLOSE_LANGUAGE_MODAL'
export const closeLanguageModal = () => {
    return { type: CLOSE_LANGUAGE_MODAL }
}
// Checkout
export const CLOSE_CHECKOUT_MODAL = 'CLOSE_CHECKOUT_MODAL'
export const closeCheckoutModal = () => {
    return { type: CLOSE_CHECKOUT_MODAL }
}

// Login
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL'
export const closeLoginModal = () => {
    return { type: CLOSE_LOGIN_MODAL }
}
export const showLoginModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_LOGIN_MODAL',
    })
}

// Cart
export const CLOSE_CART_MODAL = 'CLOSE_CART_MODAL'
export const closeCartModal = () => {
    return { type: CLOSE_CART_MODAL }
}
export const showCartModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_CART_MODAL',
    })
}

export const SHOW_CART_MODAL = 'SHOW_CART_MODAL'
export const toggleCart = (showCart) => (dispatch, getState) => {
    if (!showCart) {
        dispatch(resetUI())
        dispatch({ type: SHOW_CART_MODAL })
    } else {
        dispatch({ type: CLOSE_CART_MODAL })
    }
}

// Notifications
export const CLOSE_NOTIFICATIONS_MODAL = 'CLOSE_NOTIFICATIONS_MODAL'
export const closeNotificationsModal = () => {
    return { type: CLOSE_NOTIFICATIONS_MODAL }
}
export const showNotificationsModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_NOTIFICATIONS_MODAL',
    })
}

export const SHOW_NOTIFICATIONS_MODAL = 'SHOW_NOTIFICATIONS_MODAL'
export const toggleNotifications = (showNotifications) => (dispatch, getState) => {
    if (!showNotifications) {
        dispatch(resetUI())
        dispatch({ type: SHOW_NOTIFICATIONS_MODAL })
    } else {
        dispatch({ type: CLOSE_NOTIFICATIONS_MODAL })
    }
}

// Profile
export const CLOSE_PROFILE_MODAL = 'CLOSE_PROFILE_MODAL'
export const closeProfileModal = () => {
    return { type: CLOSE_PROFILE_MODAL }
}
export const toggleProfile = (showProfile) => (dispatch, getState) => {
    if (!showProfile) {
        dispatch(resetUI())
        dispatch({ type: 'SHOW_PROFILE_MODAL',showProfile })
    } else {
        dispatch({ type: 'CLOSE_PROFILE_MODAL',showProfile })
    }
}
export const showProfileModal = () => (dispatch, getState) => {
    dispatch(resetUI())
    dispatch({
        type: 'SHOW_PROFILE_MODAL',
    })
}

// Menu
export const toggleMenu = (showMenu) => {
    if (!showMenu) {
        return { type: 'SHOW_MENU' }
    } else {
        return { type: 'CLOSE_MENU' }
    }
}

export const CLOSE_MENU = 'CLOSE_MENU'
export const closeMenu = () => {
    return { type: CLOSE_MENU }
}

// Reset UI
export const RESET_UI = 'RESET_UI'
export const resetUI = () => {
    return { type: RESET_UI }
}
