import React from 'react'
import { OrderLink } from './OrderLink'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import { CheckoutLink } from './CheckoutLink'
import moment from 'moment/moment'

const Task = ({task}) => {
    const taskUserId = task.user_id;
    let otherUserType = 'Seller';
    let otherUserName = task.seller_name;
    let otherUserId = task.seller_id;

    if (taskUserId === task.seller_id) {
        otherUserType = 'Buyer';
        otherUserName = task.buyer_name;
        otherUserId = task.buyer_id;
    }


    if (task.checkout_id) {
        return <li>
            <CheckoutLink
                checkoutId={task.checkout_id}
                className='colored-link'>
            </CheckoutLink> ({
                moment(task.created_at)
                    .format('DD MMM HH:mm')
                    .toString()
            })
        </li>
    }

    if(!task.order_id) {
        return <li>
            <LocalizedLink routeId="ROUTE_USER_PROFILE" params={{ view: 'financial-information' }}>
                <Translation id="financial_information" defaultMessage="Financial information" />
            </LocalizedLink>
        </li>
    }

    return <li>
        <OrderLink orderId={task.order_id} className='colored-link' ></OrderLink> ({otherUserType}: {otherUserName})
    </li>
}


export const TasksInfo = ({ tasks, title }) => {

    return <div>
        <strong>{title}</strong>
        <ul>
            {
                tasks.map((task) => <Task task={task}></Task>)
            }
        </ul>
    </div>
}
