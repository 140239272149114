import React from 'react'
import Translation from '../global/Translation'
import { injectIntl } from 'react-intl'
import AlertsContainer from '../alerts/AlertsContainer'
import Spinner from '../global/Spinner'
import Select from 'react-select'
import { addToCollection, editItemFromCollection, fetchCollectionById } from '../../store/collection/actions'
import { findCollectionById, getCollectionsErrorForms, getIsLoading, isSending } from '../../store/collection/reducers'
import { connect } from 'react-redux'
import { SearchkitManager } from 'searchkit'
import Button from '../global/Button'
import {
    getAvailableLangs,
    getAvailableProductsCategories,
    getAvailableStatus,
    getCurrentCollectableContext
} from '../../store/products/reducers'
import { withRouter } from 'react-router-dom'
import { FormInput } from '../global/FormInput'
import { ItemFeaturesAndDetailsInputs } from '../shared/items/ItemFeaturesAndDetailsInputs'
import { setCollectableContextFilter } from '../../store/products/CollectableContext'
import CardSelect from '../shared/card-select/CardSelect'

const mapStateToProps = (state, ownProps) => {
    return {
        isSending: isSending(state),
        availableLangs: getAvailableLangs(state),
        availableStatus: getAvailableStatus(state),
        itemPropsOnEdit: ownProps.mode === 'edit' ? findCollectionById(state, ownProps.match.params.id) : null,
        isLoading: getIsLoading(state),
        errorsForm: getCollectionsErrorForms(state),
        collectableContext: getCurrentCollectableContext(state),
        productsCategoriesOptions: getAvailableProductsCategories(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCollectionById: (collectionId) => {
            return dispatch(fetchCollectionById(collectionId))
        },
        addToCollection: (body) => {
            dispatch(addToCollection(body))
        },
        editItemFromCollection: (body) => {
            return dispatch(editItemFromCollection(body))
        }
    }
}
const getSk = (collectableContext, token) => {
    const catalogUri = '/api/catalog/'
    const httpHeaders = token ? { Authorization: 'Bearer ' + token } : {}

    let sk = new SearchkitManager(process.env.REACT_APP_API_HOST + catalogUri, {
        searchUrlPath: '',
        timeout: 60000,
        httpHeaders
    })

    setCollectableContextFilter(sk, collectableContext);

    return sk
}


class AddToCollectionForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            productSelectedId: null,
            productSelectedCategory: 'mtg_single',
            selectedImg: null,
            isSigned: false,
            isAltered: false,
            isPlayset: false,
            isRated: false,
            isFoil: false,
            selectedStatus: null,
            selectedLang: null,
            sk: null,
            qty: 1
        }
    }

    setSk = () => {
        this.setState(
            {
                sk: getSk(this.props.collectableContext, this.props.token)
            }
            // () => {
            //     if (this.props.location.search.indexOf('promo=true') > -1 && this.props.isLogged && this.props.center !== 7500) {
            //         this.props.changeCenter(7500)
            //     }
            // }
        )
    }

    handleSubmit = (e) => {
        e.preventDefault()
        // console.log(this.state)

        let body = {
            card: this.state.productSelectedId,
            qty: this.state.qty,
            language: this.state.selectedLang.value,
            status: this.state.selectedStatus.value,
            isSigned: this.state.isSigned,
            isAltered: this.state.isAltered,
            isPlayset: this.state.isPlayset,
            isRated: this.state.isRated,
            isFoil: this.state.isFoil,
            comments: this.commentsInput.value
        }
        // console.log(body)
        if (this.props.mode == 'edit') {
            body.id = this.props.itemPropsOnEdit.id
            this.props.editItemFromCollection(body)
        } else {
            this.props.addToCollection(body)
        }
    }
    canSubmit = () => {
        if (!this.state.productSelectedId) return false
        if (!this.state.qty) return false
        if (!this.state.selectedLang) return false
        if (!this.state.selectedStatus) return false
        if (this.props.isSending) return false
        return true
    }
    completeFormWithData = () => {
        this.setState(
            {
                qty: this.props.itemPropsOnEdit.qty,
                productSelectedId: this.props.itemPropsOnEdit.product.id,
                productSelectedCategory: this.props.itemPropsOnEdit.product.productCategory,
                selectedImg: this.props.itemPropsOnEdit.product.img,
                isSigned: this.props.itemPropsOnEdit.is_signed,
                isAltered: this.props.itemPropsOnEdit.is_altered,
                isPlayset: this.props.itemPropsOnEdit.is_playset,
                isRated: this.props.itemPropsOnEdit.is_graded,
                isFoil: this.props.itemPropsOnEdit.is_foil,
                selectedStatus: this.props.availableStatus.filter((item) => item.value == this.props.itemPropsOnEdit.state)[0],
                selectedLang: this.props.availableLangs.filter((item) => item.value == this.props.itemPropsOnEdit.language)[0]
            },
            () => {
            }
        )
    }
    componentDidMount = () => {
        this.setSk()
        if (this.props.mode == 'edit') {
            this.props.fetchCollectionById(this.props.match.params.id).then((response) => {
                if (this.props.mode == 'edit' && this.props.itemPropsOnEdit) {
                    this.completeFormWithData()
                }
            })
            if (this.props.mode == 'edit' && this.props.itemPropsOnEdit) {
                this.completeFormWithData()
            }
        }
        if (this.props.mode !== 'edit') {
            this.setState({
                selectedLang: { value: 'en', label: 'English' },
                selectedStatus: { label: 'Near Mint', value: 'nearmint' }
            })
        }
        if (this.props.itemOnProductCard) {
            this.setState({
                productSelectedId: this.props.itemOnProductCard.id,
                selectedImg: this.props.itemOnProductCard.img,
                productSelectedCategory: this.props.itemOnProductCard.productCategory,
            })
        }
    }
    handleSelectProduct = (product) => {
        this.setState({
            productSelectedId: product.id,
            selectedImg: product.img,
            productSelectedCategory: product.productCategory,
        })
    }

    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={true}></Spinner>
        }
        if (!this.state.sk) {
            return null
        }
        
        const { isLogging } = this.props

        const spinner = isLogging ? <Spinner isLoading={isLogging} /> : null

        return (
            <div>
                {/* {!this.props.onlyForm && <GoBack></GoBack>} */}
                <div className="collection">
                    <form onSubmit={this.handleSubmit} className="row form-inline">
                        <AlertsContainer />
                        {spinner}
                        {!this.props.onlyForm && (
                            <CardSelect
                                formMode={this.props.mode}
                                productsCategoriesOptions={this.props.productsCategoriesOptions}
                                selectedImg={this.state.selectedImg}
                                productSelectedId={this.state.productSelectedId}
                                onlySingle={true}
                                intl={this.props.intl}
                                sk={this.state.sk}
                                handleSelectProduct={this.handleSelectProduct}
                            />
                        )}

                        <div className={`col-12  ${this.props.onlyForm ? 'col-lg-12' : 'col-lg-5'}`}>
                            <div className="row mb-3">
                                <div className="col-4 col-lg-4">
                                    <FormInput
                                        name="qty"
                                        id="qty"
                                        type="number"
                                        showRequired={false}
                                        min={1}
                                        required={true}
                                        label={<Translation id="qty" defaultMessage="Qty" />}
                                        errors={this.props.errorsForm}
                                        value={this.state.qty}
                                        onChange={(e) => this.setState({ qty: e.target.value })}
                                    />
                                </div>

                                <div className="col-8 col-lg-4">
                                    <label htmlFor="language">
                                        <Translation id="language" defaultMessage="Language" />
                                    </label>
                                    <Select
                                        options={this.props.availableLangs}
                                        value={this.state.selectedLang}
                                        onChange={(selectedOption) => {
                                            this.setState({ selectedLang: selectedOption })
                                        }}
                                        placeholder={
                                            this.props.intl.formatMessage({
                                                id: 'select',
                                            }) + '...'
                                        }
                                    />
                                </div>

                                <div className="col-12 col-lg-4">
                                    <label htmlFor="status">
                                        <Translation id="status" defaultMessage="Status" />
                                    </label>
                                    <Select
                                        options={this.props.availableStatus}
                                        value={this.state.selectedStatus}
                                        onChange={(selectedOption) => {
                                            this.setState({ selectedStatus: selectedOption })
                                        }}
                                        placeholder={
                                            this.props.intl.formatMessage({
                                                id: 'select',
                                            }) + '...'
                                        }
                                    />
                                </div>
                            </div>

                            <ItemFeaturesAndDetailsInputs
                                isStockBlocked={false}
                                isSigned={this.state.isSigned}
                                isPlayset={this.state.isPlayset}
                                isAltered={this.state.isAltered}
                                isRated={this.state.isRated}
                                isFoil={this.state.isFoil}
                                toggleIsSigned={() => this.setState({ isSigned: !this.state.isSigned })}
                                toggleIsPlayset={() => this.setState({ isPlayset: !this.state.isPlayset })}
                                toggleIsAltered={() => this.setState({ isAltered: !this.state.isAltered })}
                                toggleIsRated={() => this.setState({ isRated: !this.state.isRated })}
                                toggleIsFoil={() => this.setState({ isFoil: !this.state.isFoil })}
                                productCategory={this.state.productSelectedCategory}
                            />

                            <div className="form-group row">
                                <label htmlFor="comments">
                                    <Translation id="comments" defaultMessage="Comments" />
                                </label>
                                <input
                                    type="card"
                                    id="inputComments"
                                    maxLength="30"
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder={this.props.intl.formatMessage({ id: 'COMMENTS_PLACEHOLDER' })}
                                    defaultValue={this.props.itemPropsOnEdit ? this.props.itemPropsOnEdit.comments : null}
                                    ref={(node) => {
                                        this.commentsInput = node
                                    }}
                                />
                            </div>

                            {this.props.mode == 'edit' ? (
                                this.canSubmit() ? (
                                    <Button type="submit" onClick={this.handleSubmit}>
                                        <Translation id="edit" defaultMessage="Edit"></Translation>
                                    </Button>
                                ) : (
                                    <Button disabled>
                                        <Translation id="edit" defaultMessage="Edit"></Translation>
                                    </Button>
                                )
                            ) : this.canSubmit() ? (
                                <Button type="submit" onClick={this.handleSubmit}>
                                    <Translation id="add_to_collection" defaultMessage="Add to collection"></Translation>
                                </Button>
                            ) : (
                                <Button disabled>
                                    <Translation id="add_to_collection" defaultMessage="Add to collection"></Translation>
                                </Button>
                            )}
                        </div>
                        {/* <input type="submit" className="bt bt-inverted loginbutton" value={this.props.intl.formatMessage({ id: 'add_to_collection' })} /> */}
                    </form>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddToCollectionForm)))
