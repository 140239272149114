import React from 'react'
import { ChatPendingMiniNotification, OrderMiniNotification, PendingTaskMiniNotification } from './MiniNotificationInfo'
import { defineMessages } from 'react-intl'


defineMessages({
    HAS_PENDING_TASKS: {
        id: 'HAS_PENDING_TASKS',
        description: 'HAS_PENDING_TASKS',
        defaultMessage: 'Has pending tasks !'
    },
})
const RenderNotification = (props) => {

    const config = {
        CHATS_PENDING: {
            props: {
                translationId: 'CHAT_PENDING_MESSAGE',
                translationParams: {
                    userName: props.notification.sender_user_name
                }
            },
            component: ChatPendingMiniNotification
        },
        ORDER_CONFIRMED: {
            props: {
                translationId: 'ORDER_CONFIRMED_MESSAGE',
                translationParams: {
                    userName: props.notification.sender_user_name
                }
            },
            component: OrderMiniNotification
        },
        ORDER_NOT_CONFIRMED: {
            props: {
                translationId: 'ORDER_NOT_CONFIRMED_MESSAGE',
                translationParams: {
                    userName: props.notification.sender_user_name
                }
            },
            component: OrderMiniNotification
        },
        ORDER_SENT: {
            props: {
                translationId: 'ORDER_SENT_MESSAGE',
                translationParams: {
                    userName: props.notification.sender_user_name
                }
            },
            component: OrderMiniNotification
        },
        ORDER_RECEIVED: {
            props: {
                translationId: 'ORDER_RECEIVED_MESSAGE',
                translationParams: {
                    userName: props.notification.sender_user_name
                }
            },
            component: OrderMiniNotification
        },
        ORDER_LOST_CONFIRMATION: {
          props: {
              translationId: 'ORDER_LOST_CONFIRMATION',
              translationParams: {
                  userName: props.notification.sender_user_name
              }
          },
          component: OrderMiniNotification
        },
        ORDER_RECEIVED_CONFIRMATION: {
            props: {
                translationId: 'ORDER_RECEIVED_MESSAGE',
                translationParams: {
                    userName: props.notification.sender_user_name
                }
            },
            component: OrderMiniNotification
        },
        ORDER_CANCELLED: {
            props: {
                translationId: 'ORDER_CANCELLED_MESSAGE',
                translationParams: {
                    userName: props.notification.sender_user_name
                }
            },
            component: OrderMiniNotification
        },
        ORDER_VOTED: {
            props: {
                translationId: 'ORDER_VOTED_MESSAGE',
                translationParams: {
                    userName: props.notification.sender_user_name
                }
            },
            component: OrderMiniNotification
        },
        PENDING_PICKUP: {
            props: {
                translationId: 'PENDING_PICKUP',
                translationParams: {
                    userName: props.notification.sender_user_name
                }
            },
            component: OrderMiniNotification
        },
    
        HAS_PENDING_TASKS: {
            props: {
                translationId: 'HAS_PENDING_TASKS',
                qtyPendingTask: props.notification.qtyPendingTask
            },
            component: PendingTaskMiniNotification
        }
    }

    const notificationData = config[props.notification.notification_type] ?? null
    if (!notificationData) {
        return null
    }
    return <notificationData.component
        notification={props.notification} {...notificationData.props} >
    </notificationData.component>

}
export default RenderNotification
