import React from 'react'
import { OrderLink } from '../tasks/OrderLink'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import moment from 'moment'

export const ChatPendingNotification = (({notification, translationId, translationParams = {}}) => {
    return <li>
        <Translation id={translationId} values={{...translationParams}}/> <LocalizedLink className='colored-link' routeId={'ROUTE_USER_DASHBOARD'} params={{ view: 'chats', id: notification.chat_id }}>#CHAT</LocalizedLink>
    </li>
})

export const OrderNotification = ({notification, translationId, translationParams = {}}) => {
    return <li>
        <Translation id={translationId} values={{...translationParams}}/>  <OrderLink className='colored-link' orderId={notification.order_id}></OrderLink> ({ moment(notification.created_at).format('DD/MM/YYYY HH:mm').toString() })
    </li>
}
