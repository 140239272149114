import { fetchCredit } from './../store/login/actions';
import { getAddCreditErrors } from '../store/login/reducers'
import { FormInput } from '../components/global/FormInput'
import { addCreditByGateway } from '../store/cart/actions'
import { getBankData, getUserId, getUserName } from '../store/login/reducers'
import React, { useState,useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import Button from '../components/global/Button'
import { setBuyCredit } from '../store/login/actions'
import { getAddCreditUrl } from './AddCreditScreen'
import Alerts from '../components/alerts/Alerts'


const AddCreditByPaymentGatewayScreen = (props) => {
    const dispatch = useDispatch()
    const errorsForm = useSelector((state) => getAddCreditErrors(state))
    const userName = useSelector((state) => getUserName(state))
    const userId = useSelector((state) => getUserId(state))
    const bankData = useSelector((state) => getBankData(state))
    const [amount, setCreditToChange] = useState(0)
    const onSubmit = (values, action) => {
        dispatch(setBuyCredit(values))
    }
    useEffect(() => { dispatch(fetchCredit())}, [])


    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 ">
                        <h2>
                            <Translation
                                id="add_credit_by_payment_gateway_title"
                                defaultMessage="Add credit by payment gateway ({gatewayName})"
                                values={{ gatewayName: 'Stripe' }}
                            />
                        </h2>
                        <Translation id="minimum_1_dollar" defaultMessage="Minimum 1 dollar" />
                    </div>
                </div>

                <div className="row price mt-3">
                    <div className="col-6">
                        <FormInput
                            name="amount"
                            id="price"
                            type="number"
                            required={true}
                            showRequired={false}
                            style={{
                                flexDirection: 'row',
                                background: '#ffc466',
                                // padding: '8px',
                                paddingLeft: '16px',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                // gridTemplateColumns: '1fr 1fr',
                                // justifyContent: 'center',
                            }}
                            label={'Amount: '}
                            errors={errorsForm}
                            defaultValue={amount}
                            onChange={(e) => setCreditToChange(e.target.value)}
                            placeholder={props.intl.formatMessage({ id: 'PLACEHOLDER_ADD_BUY_CREDIT' })}
                        />
                    </div>
                    <div className="col-6">
                        <Button onClick={() => dispatch(addCreditByGateway(getAddCreditUrl(), amount))} disabled={amount < 1}>
                            Go to payment gateway
                        </Button>
                    </div>
                </div>

                <div className="row mt-3">
                    <Alerts status="info" alert={`Will receive your credit immediately, under small fee.`}></Alerts>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(AddCreditByPaymentGatewayScreen)))
