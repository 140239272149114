import React from 'react'
import { connect, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter,Route,Switch } from 'react-router-dom'
import OrdersContainer from '../components/orders/OrdersContainer'
import { getBankData } from '../store/login/reducers'
import Alerts from '../components/alerts/Alerts'
import { getHasFilledShippingAddress } from './../store/login/reducers'


const BuysScreen = (props, intl) => {
    //OBSOLETE
    const bankData = useSelector((state) => getBankData(state))
    const addressData = useSelector((state) => getHasFilledShippingAddress(state))
    const routeId = this.props.intl.formatMessage({ id: 'ROUTE_USER_PROFILE' }).replace(':view?', 'shipping-information')
    if (!addressData) {
        return (
            <div className="col-12">
                <Alerts alert={'SHIPPING_ADDRESS_REQUIRED'} status={'warning'} routeId={routeId} />
            </div>
        )
    }

    return (
        <div className="container-fluid">
            {/* <h2>add to collection</h2> */}
            <OrdersContainer type="BUY"></OrdersContainer>
        </div>
    )
}

export default withRouter(injectIntl(connect()(BuysScreen)))
