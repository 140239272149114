import React from 'react'
import Translation from '../global/Translation'

import { defineMessages, injectIntl } from 'react-intl'

import logo from '../../logo.png'

import AlertsContainer from '../alerts/AlertsContainer'
import Spinner from '../global/Spinner'

defineMessages({
    RECOVER_PASSWORD_SUCCESS: {
        id: 'RECOVER_PASSWORD_SUCCESS',
        description: 'RECOVER_PASSWORD_SUCCESS',
        defaultMessage: 'Recover password email sent',
    },
    RECOVER_PASSWORD_FAILURE: {
        id: 'RECOVER_PASSWORD_FAILURE',
        description: 'RECOVER_PASSWORD_FAILURE',
        defaultMessage: 'Something went wrong',
    },
    RECOVER_PASSWORD: {
        id: 'recover_password',
        description: 'recover_password',
        defaultMessage: 'Recover password',
    }
})
class ForgotPasswordForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = { forgotPassword: null }
    }
    handleSubmit = e => {
        e.preventDefault()
        this.props.onRecoverPassword(this.emailInput.value)
    }
    render() {
        const { isLogging } = this.props

        const spinner = isLogging ? <Spinner isLoading={isLogging} /> : null

        return (
            <div className="form-signin">
                <form onSubmit={this.handleSubmit}>
                    <AlertsContainer />
                    {spinner}
                    {/* <img alt="" id="profile-img" className="d-block d-lg-none profile-img-card" src={logo} style={{ marginBottom: '30px' }} /> */}
                    <label htmlFor="email">
                        <Translation id="email" defaultMessage="Email" />
                    </label>
                    <input
                        type="email"
                        id="inputEmail"
                        className="form-control"
                        placeholder="Email"
                        ref={node => {
                            this.emailInput = node
                        }}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                this.handleSubmit(e)
                            }
                        }}
                    />
                    <div id="remember" className="checkbox" />
                    <input type="submit" className="bt bt-inverted loginbutton" value={this.props.intl.formatMessage({ id: 'recover_password' })} />

                    <div className="row links">
                        <div className="col-12 col-md-7 offset-md-5 forgot-password">
                            <span onClick={this.props.showLogin} style={{cursor: 'pointer'}}>
                                <Translation id="LOGIN" defaultMessage="Login" />
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default injectIntl(ForgotPasswordForm)
