import React from 'react'
import Spinner from '../global/Spinner'
import Translation from '../global/Translation'
// import IconButtonsContainer from './IconButtonsContainer'
import pristine from '../../assets/img/icons/product/condition/pristine.png'
import { injectIntl } from 'react-intl'
import { useSelector, connect } from 'react-redux';
import { getAvailableStatus } from '../../store/products/reducers'

const DisplayStatus = ({status}) => {
    const availableStatus = useSelector((state) => getAvailableStatus(state))
    const statusItem = availableStatus.find(item => {
        return item.value == status
    })
    // console.log(status)
    // // console.log(`../../../assets/img/icons/product/condition/${status}.png`)
        return (
            <div className="badge" style={{background: statusItem.badgeColor}}>
                {statusItem.label}
            </div>
        )
    //OLD Status
    // return (
    //         <div>
    //             <img src={statusItem.img} />{' '}{statusItem.label}
    //         </div>
    //     )

   

}

export default injectIntl(connect()(DisplayStatus))
