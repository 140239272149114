import React from 'react'
import { useSelector } from 'react-redux'
import { getUserProfileLogoUrl } from '../../store/login/reducers'

export const ProfileLogo = ({ userName }) => {
    let initials = ''

    if (userName) {
        let userNameSplit = userName.split(' ')
        initials = userNameSplit[0].charAt(0).toUpperCase()
        if (userNameSplit?.length > 1) {
            initials += userNameSplit[1].charAt(0).toUpperCase()
        }
    }

    const userProfileLogoUrl = useSelector((state, ) => getUserProfileLogoUrl(state, userName))

    return (
        <div className='col-4 col-xl-2  mb-3 mb-md-0 d-flex justify-content-center align-items-center'>
            {userProfileLogoUrl ? (
                <img className='circle' style={{ height: '4.3rem', width: '4.3rem' }} src={userProfileLogoUrl} />
            ) : (
                <span className='circle' style={{ height: '4.3rem', width: '4.3rem' }}>
                    <span className='initials'>{initials}</span>
                </span>
            )}
        </div>
    )
}
