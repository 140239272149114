import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import MiniCartSummary from './MiniCartSummary'
import confirm from '../global/confirm'

class MiniCart extends React.Component {
    componentDidMount() {
        this.props.fetchCart(this.props.selectedCenter)
    }
    confirmEmptyCart = () => {
        confirm(this.props.intl.formatMessage({ id: 'CONFIRM' })).then(
            confirm => {
                this.props.emptyCart()
            },
            cancel => {}
        )
    }
    
    render() {
        const {
            lines,
            totalQty,
            total,
            onDelete,
            changeCartLineQty,
            show,
            onClose,
            lang
        } = this.props
        if (!lines) {
            return ''
        }
        const buttons = (
            <div className="row no-gutters">
                <div className="col-4 col-md-4 p-1">
                    <Button onClick={() => this.confirmEmptyCart()}>
                        <Translation id="empty" defaultMessage="Empty" />
                    </Button>
                </div>

                <div className="col-8 col-md-8 p-1">
                        <LocalizedLink routeId="ROUTE_CART">
                            <Button inverted datacy="modal_minicarrito__go_checkout">
                                <Translation id="checkout" defaultMessage="Checkout" />
                            </Button>
                        </LocalizedLink>
                </div>
            </div>
        )

        return (
            <div>
                <div className="modal-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
                <div id="mini-cart-container" className="bg-color-header" style={{ display: show ? 'block' : 'none' }}>
                    <div id="mini-cart">
                        <header className="container-fluid">
                            <div className="row">
                                <div className="col-9 my-auto">
                                    <div className="go-back" onClick={onClose}>
                                        <i className={'fal fa-chevron-left'} />
                                        <span className="label">
                                            <Translation id="back" defaultMessage="Back" />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-3 my-auto text-right">
                                    <div className="bt-close" onClick={onClose}>
                                        <i className="fal fa-times" />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div>
                            <MiniCartSummary {...this.props} />
                        </div>
                        <footer className="container-fluid"> {totalQty > 0 && buttons}</footer>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(MiniCart)
