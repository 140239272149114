// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for .env: reportWebVitals(// console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './assets/scss/app.scss'
import 'material-icons/iconfont/material-icons.css'

import { ConnectedRouter, connectRouter } from 'connected-react-router'
import { LOCATION_CHANGE, routerMiddleware } from 'react-router-redux'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import cart from './store/cart/reducers'
import products from './store/products/reducers'
import collection from './store/collection/reducers'
import sale from './store/sale/reducers'
import buy from './store/buy/reducers'
import orders from './store/orders/reducers'
import error from './store/error/reducers'
import login, { getIsLogged, getToken, getUserId } from './store/login/reducers'
import maintenanceMode from './store/maintenance-mode/reducers'
import suggestion from './store/suggestion/reducers'
import user from './store/user/reducers'
// chats
import chats, { getChat } from './store/chats/reducers'
import ui from './store/ui/reducers'
import floatingAlerts from './store/floating-alerts/reducers'
import createHistory from 'history/createBrowserHistory'
import toastNotifications from './store/toast-notifications/reducers'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js'

import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-table-6/react-table.css'

import { loadingBarMiddleware, loadingBarReducer } from 'react-redux-loading-bar'
import ConnectedIntlProvider from './i18n'
// import Raven from "raven-js";
import { composeWithDevTools } from 'redux-devtools-extension'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import pendingTasks from './store/pending-tasks/reducers'
import notifications from './store/notifications/reducers'
import { createSocketConnection } from './webSocket/WebSocketServer'
import { clearChatUnreadCounter, increaseUnreadMessageDistinctActiveChat } from './store/chats/actions'
import { userCreditChanged } from './store/login/actions'

const history = createHistory()

Sentry.init({
    dsn: 'https://d38bfbe309a841d5984abeda1bf8d0d4@o1424633.ingest.sentry.io/6772801',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
})


const middlewares = [
    thunk,
    // analyticsEventsMiddleware,
    // promiseMiddleware(),
    routerMiddleware(history),
    loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE', LOCATION_CHANGE]
    })
]
if (process.env.NODE_ENV !== 'production') {
    middlewares.push(
        createLogger({
            collapsed: true,
            predicate: (getState, action) => !action.type.includes('loading-bar')
        })
    )
}

const websocketMiddleware = (store) => (next) => (action) => {
    const result = next(action)
    const afterIsLogged = getIsLogged(store.getState())

    if (!afterIsLogged) {
        return
    }

    if (afterIsLogged && !window.Echo) {
        const userId = getUserId(store.getState())
        const userToken = getToken(store.getState())

        createSocketConnection(userId, userToken)

        window.Echo.private(`Notifications.User.${userId}`)
            .listen('.notification.created', (notification) => {
                store.dispatch({ type: 'NOTIFICATION_CREATED_RECEIVED', notification })
            })
            .listen('.notification.deleted', (notificationDeleted) => {
                store.dispatch({ type: 'NOTIFICATION_DELETED', notificationDeletedId: notificationDeleted.id })
            })

        window.Echo.private(`PendingTask.User.${userId}`)
            .listen('.pendingTask.created', (pendingTask) => {
                store.dispatch({ type: 'PENDING_TASK_CREATED_RECEIVED', pendingTask })
            })
            .listen('.pendingTask.deleted', (pendingTaskDeleted) => {
                store.dispatch({ type: 'PENDING_TASK_DELETED', pendingTaskDeletedId: pendingTaskDeleted.id })
            })


        // escuchar los mensajes de los chats
        window.Echo.private(`Chat.${userId}`)
            .listen('.message.created', (message) => {
                const currentChat = getChat(store.getState())
                if (currentChat && currentChat.chatId === message.chat_id) {
                    store.dispatch({ type: 'ADD_MESSAGE_CHAT_SUCCESS', message })
                } else {
                    store.dispatch(increaseUnreadMessageDistinctActiveChat(message.chat_id))
                }
            })
            .listen('.messages.read', (messagesReadInfo) => {
                store.dispatch(clearChatUnreadCounter(messagesReadInfo.chatId, messagesReadInfo.readMessagesId));
            });

        // escuchar los mensajes de los chats
        window.Echo.private(`User.${userId}`)
            .listen('.credit.changed', (creditInfo) => {
                store.dispatch(userCreditChanged(creditInfo.userId, creditInfo.creditBefore, creditInfo.creditAfter))
            })
    }

    return result
}

middlewares.push(websocketMiddleware)

const migration = (state, currentVersion) => {
    if (state && state._persist.version < currentVersion) return Promise.resolve()
    else return Promise.resolve(state)
}

const loginConfig = {
    key: 'login',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 0.02,
    migrate: migration,
    blacklist: ['errorsBuyCredit', 'errorsBankData', 'shippingAddressErrors', 'errorsCreateAccount']
}

const productConfig = {
    key: 'login',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 0.02,
    migrate: migration,
    whitelist: ['collectableContext'],
}
let reducer = combineReducers({
    error,
    cart,
    products:persistReducer(productConfig, products),
    chats,
    collection,
    sale,
    buy,
    orders,
    login: persistReducer(loginConfig, login),
    user,
    ui,
    floatingAlerts,
    router: connectRouter(history),
    loadingBar: loadingBarReducer,
    toastNotifications,
    suggestion,
    pendingTasks,
    maintenanceMode,
    notifications
})

const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
    predicate: (getState, action) => !action.type.includes('loading-bar')
})

const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)))
const persistor = persistStore(store)

export default store
ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ConnectedRouter history={history}>
                <ConnectedIntlProvider />
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)
