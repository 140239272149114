import React from "react";

import PropTypes from "prop-types";
import { confirmable } from "react-confirm";
import Button from "./Button";
import { defineMessages } from "react-intl";
import Translation from "./Translation";
defineMessages({
  CONFIRM: {
    id: "CONFIRM",
    description: "CONFIRM",
    defaultMessage: "Are you sure?",
  },
});
const ConfirmDialog = ({
  show,
  proceed,
  dismiss,
  cancel,
  confirmation,
  options,
}) => {
  return (
      <div>
          <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
          <div id="confirm-container" className="confirm" style={{ display: show ? 'block' : 'none' }}>
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-12 container">
                          <header className="container-fluid">
                              <div className="row">
                                  <div className="col-12">
                                      <div>
                                          <h2>{confirmation}</h2>
                                          <div className="row">
                                              <div className="col-6 col-md-3 offset-md-3 text-capitalize">
                                                  <Button onClick={() => cancel('arguments will be passed to the callback')} secondary datacy="confirm_dialog__cancel">
                                                      {options.textCancel ? options.textCancel : 'CANCEL'}
                                                  </Button>
                                              </div>
                                              <div className="col-6 col-md-3 text-capitalize">
                                                  <Button onClick={() => proceed('same as cancel')} secondary datacy="confirm_dialog__ok">
                                                      {options.textOk ? options.textOk : 'OK'}
                                                  </Button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </header>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
};

ConfirmDialog.propTypes = {
  show: PropTypes.bool, // from confirmable. indicates if theyarn start dialog is shown or not.
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
  confirmation: PropTypes.string, // arguments of your confirm function
  options: PropTypes.object, // arguments of your confirm function
};

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmDialog);
