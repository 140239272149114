import * as api from '../api'
import { getLanguage } from '../ui/reducers'
import { getToken } from '../login/reducers'

export const fetchUserPublicInfoById = (userNameSlug) => (dispatch, getState) => {

    dispatch({type: 'FETCH_USER_PUBLIC_INFO_BY_ID_REQUEST', userNameSlug})
    return api.fetchUserPublicInfoById(userNameSlug)
        .then( (response ) => {
            dispatch({type:'FETCH_USER_PUBLIC_INFO_BY_ID_SUCCESS', data: response.data})
        }).catch( (error) => {
            api.errorHandler(dispatch, error.response, ['USER_NOT_FOUND']);
            dispatch({type: 'FETCH_USER_PUBLIC_INFO_BY_ID_FAILURE', response: error.response})
    })
}
export const fetchUserPublicSaleItems = (userId) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_USER_PUBLIC_SALE_ITEMS_REQUEST' })
    return api
        .fetchUserPublicSaleItems(userId)
        .then((response) => {
            return dispatch({ type: 'FETCH__USER_PUBLIC_SALE_ITEMS_SUCCESS', data: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH__USER_PUBLIC_SALE_ITEMS_FAILURE', response: error.response })
        })
}

export const fetchUserCommentsById = (userNameSlug) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_USER_PUBLIC_COMMENTS_REQUEST', userNameSlug })
    return api
        .fetchUserPublicComments(userNameSlug)
        .then((response) => {
            return dispatch({ type: 'FETCH_USER_PUBLIC_COMMENTS_SUCCESS', data: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_USER_PUBLIC_COMMENTS_FAILURE', response: error.response })
        })
}

export const fetchCreditRetrieveIngress = () => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_CREDIT_RETRIEVE_INGRESS_REQUEST' })
    return api
        .fetchCreditRetrieveIngress(getToken(getState()))
        .then((response) => {
            return dispatch({ type: 'FETCH_CREDIT_RETRIEVE_INGRESS_SUCCESS', data: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_CREDIT_RETRIEVE_INGRESS_FAILURE', response: error.response })
        })
}







//
// export const fetchUserItemsById = (userId) => (dispatch, getState) => {
//
//     dispatch({type: 'FETCH_USER_ITEMS_BY_ID_REQUEST'})
//     return api.fetchUserById(userId)
//         .then( (response ) => {
//             dispatch({type:'FETCH_USER_ITEMS_BY_ID_SUCCESS', user:response.data})
//         }).catch( (error) => {
//             dispatch({type: 'FETCH_USER_ITEMS_BY_ID_FAILURE', response: error.response})
//         })
// }

