import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import RenderNotifications from './RenderNotifications'
import LocalizedLink from '../global/LocalizedLink'

class MiniNotifications extends React.Component {


    render() {
        const { show, notifications, qtyPendingTasks, onClose } = this.props

        return (
            <div>
                <div className='modal-overlay' style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
                <div id='mini-notifications-container' className='bg-color-header'
                     style={{ display: show ? 'block' : 'none' }}>
                    <div id='mini-notifications'>
                        <header className='container-fluid'>
                            <div className='row'>
                                <div className='col-9 my-auto'>
                                    <div className='go-back' onClick={onClose}>
                                        <i className={'fal fa-chevron-left'} />
                                        <span className='label'>
                                            <Translation id='back' defaultMessage='Back' />
                                        </span>
                                    </div>
                                </div>
                                <div className='col-3 my-auto text-right'>
                                    <div className='bt-close' onClick={onClose}>
                                        <i className='fal fa-times' />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div>
                            <div>
                                {notifications.length + qtyPendingTasks <= 0 &&
                                    <div className='container-fluid'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <Translation id='all_your_notifications_has_been_read'
                                                             defaultMessage='All your notifications has been read ' />
                                            </div>
                                        </div>
                                    </div>

                                }

                                {notifications.length > 0 &&
                                    notifications.map((notificationIt) => (
                                        <React.Fragment key={notificationIt.id}>
                                            <RenderNotifications notification={notificationIt} />
                                        </React.Fragment>
                                    ))
                                }

                                { qtyPendingTasks > 0 &&
                                    <RenderNotifications notification={{'notification_type':'HAS_PENDING_TASKS','qtyPendingTask': qtyPendingTasks}} />
                                }

                                {notifications.length + qtyPendingTasks > 0 &&
                                    <div className='container-fluid'>
                                        <div className='card bg-color-primary'>
                                            <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                           params={{ view: '', id: '' }}>
                                                <div className='card-body text-center'>

                                                    <Translation id='view_all_notifications'
                                                                 defaultMessage='View all notifications' />

                                                </div>
                                            </LocalizedLink>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <footer className='container-fluid'></footer>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(MiniNotifications)
