import React from 'react'

const PrivatePolicyScreen = (props) => {
    return (
        <div className="mb-3 container-fluid">
            <div>
                <h1>Cards & Collections Privacy Policy</h1>
                <p>
                    At Cards & Collections, we are committed to safeguarding your personal information and ensuring that it is used appropriately. We also want you to feel at ease knowing that your privacy is protected.
                </p>
                <p>
                    To maintain your trust, we want you to understand what personal information we collect, how we use it, and how you are protected by Singapore laws and the Personal Data Protection Act.
                </p>
                <p>
                    The term "personal information" refers to any information that can be used to identify or contact you, such as your name, address, email address, telephone number, etc. Please note that unless otherwise indicated, this Privacy Policy governs your experience on this website.
                </p>
                <h2>Information that we collect</h2>
                <p>
                    Various types of information are collected by us. Among the examples are:
                    <ul>
                        <li>Information about you, such as your name, address, telephone number, or e-mail address; </li>
                        <li>Information regarding purchases, such as the items you purchase or sell,billing and shipping addresses, and contact information (such as for receipts or order updates); </li>
                        <li>Information regarding your profile and account, including Contact, Purchase, and Preference information, as well as your account password, items in your online shopping cart or saved for future purchases, credit balances, and other information; </li>
                        <li>If your device settings allow us to collect location information, we will collect the location information of your device when you use our mobile site.</li>
                        <li>Information about your phone, tablet, computer, or device, as well as information about your online browsing activity (collectively, "automatically collected information").</li>
                    </ul>
                </p>
                <h2>What do we do with your information</h2>
                <p>
                    Our goal is to provide our customers with the most satisfying possible experience by using the personal information we collect.
                </p>
                <p>
                    Our goal is to provide our customers with the most satisfying possible experience by using the personal information we collect. 	In addition to providing, you with information about our products, responding to your feedback and complaints, and determining your satisfaction with our services, we may collect and use your information.
                </p>
                <p>
                    Additionally, we use your personal information to conduct our daily business operations. This includes security, payment processing, analytics, fraud detection and prevention, service improvement, making backups, and complying with legal requirements.
                </p>
                <h2>How We Share Your Information</h2>
                <p>
                    To better serve you, we may share your personal information with third-party providers who assist us in administering our operations. Our third-party service providers are contractually prohibited from using your information for any purpose other than to assist us in serving your request.
                </p>
                <p>
                    When we have reason to believe that identifying you, contacting you, protecting you or us from fraud or theft, managing information security, or bringing legal action is necessary to protect your rights or the rights of Cards & collections or others, we may, in certain instances, disclose your personal information. You may also request that we disclose your personal information when you ask us to do so or when a law requires or permits it.
                </p>
                <p>
                    For more details about our Data Protection Notice, you may click here (Data Protection Notice). You may also visit the Personal Data Protection Commission Singapore website at https://www.pdpc.gov.sg to get a better understanding of the guidelines provided by the Personal Data Protection Commission of the Republic of Singapore.
                </p>
                <p>
                    We hope you have a wonderful experience at Cards & Collections!
                </p>
            </div>
        </div>
    )
}

export default PrivatePolicyScreen