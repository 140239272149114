import React from 'react'

const TOSScreen = (props) => {
    return (
        <div className="mb-3 container-fluid">
            <h1>Cards & Collections Terms of Service</h1>
            <p>
                We are delighted to welcome you  <a href ="/">www.cardsandcollections.com</a> (hereinafter referred to as Cards & Collections).
                When you visit or shop at www.cardsandcollections.com, you will be presented with a variety of products and services.
                By using <a href ="/">www.cardsandcollections.com</a> or its related sites and pages, you agree to abide by the following terms.
                Your continued use of the website implies that you accept the responsibility of knowing and following the terms, even if they may change at any time and without prior notice.
            </p>
            <h2>Service specification</h2>
            <ol>
                <li>
                    The online trading platform is a virtual marketplace where collectible card games are offered for sale, sold, and bought.
                    The supplier acts simply as an agent to establish contracts between buyers and sellers.
                    Users of the online trading platform have the opportunity to list, buy and sell collectible cards and manage their sales through the online trading platform managed by Cards & Collections.
                    Cards & Collections is not part of the contracts established between the users of the online sales platform that it manages.
                    The fulfilment of the contracts established through the online sales platform is carried out exclusively between users.
                </li>
                <li>
                    It is necessary to register for free in order to use the online trading platform.
                    User use is only permitted in accordance with legal regulations and these legal conditions.
                </li>
                <li>
                    All items offered for sale on the platform must be authentic.
                    The sale of counterfeits is strictly prohibited.
                    Counterfeit items sold through the platform may be confiscated and destroyed by Cards & Collections without compensation for evidentiary purposes.
                    The same applies to the return of counterfeits by voiding the contract.
                    Cards & Collections expressly reserves the right to take other legal action, in particular to file criminal complaints.
                </li>
            </ol>
            <h2>Register</h2>
            <ol>
                <li>
                    In order to use the digital platform at <a href="/">www.cardsandcollections.com </a>it is necessary to open a user account.
                    Registration to use the platform is free.
                </li>
                <li>
                    When registering you must indicate your name, your postal address, your email address, you must also choose a username and password.
                    In addition, you must confirm that you have read and accepted the general conditions and that you have received information about the cancellation policy.
                    Cards & Collections will then send you an email with an activation code to verify your email address.
                    Cards & Collections reserves the right to deny registration to a user without justification.
                </li>
                <li>
                    The password you choose must be kept confidential.
                    You will have to ensure that the data to access your user account does not reach third parties who may use your user account.
                    In case you lose your password or suspect that a third party has used your user account without your consent, you must inform Cards & Collections about it.
                    To protect your account from unauthorized access by third parties, you should change your account password regularly.
                </li>
                <li>
                    All natural and legally competent persons over the age of 18, as well as legal persons, can register as a user.
                    In the case of legal persons, registration must be carried out with an authorized representative.
                    Registering an account for third parties without consent, as well as having more than one account for a single user, is strictly prohibited.
                    Cards & Collections reserves the right to request the relevant evidence (other than business registrations, opening license or other types of business licences) before the activation of the user account.
                </li>
                <li>
                    The data requested during registration must be entered completely and correctly.
                    Changes to personal data must be communicated and corrected by the user in the user profile.
                    This applies specifically to the cessation of business activity, as well as to cases of insolvency or lack of liquidity of a company.
                    User accounts are not transferable.
                </li>
                <li>
                    Cards & Collections reserves the right to make use of the service provided depending on certain requirements such as verification of the user's personal data, the number of positive or negative votes or the payment of the agreed commission.
                </li>
            </ol>
            <h2>Rates and currency exchange Cards & Collections</h2>
            <ol>
                <li>
                    It is free to register on the platform, buy and put products on sale.
                </li>
                <li>
                    If a sales contract is entered into through the platform, Cards & Collections will charge the seller a commission based on the value of the product that will be retained directly.
                    Cards & Collections' right to collect this commission will begin at the time the contract between the buyer and the seller is concluded.
                    This right to charge a commission will continue to exist even if the contract between the users is not finally executed or does not satisfy both users, unless Cards & Collections is responsible for it.
                    The right to charge the commission will apply regardless of the fulfilment and subsequent processing of the contract between the users.
                    In particular, this right of Cards & Collections will not depend on the payment of the price agreed between the buyer and the seller.
                    The amount of the commission will be based on the provider's Fee Table applicable at the time of sale.
                </li>
            </ol>
            <h2>Contract period</h2>
            <ol>
                <li>The contract between Cards & Collections and the user is entered into for an indefinite period. The user can terminate said contract at any time by notifying the provider fourteen days before the end of the month. The notification must be made in writing.</li>
                <li>Cards & Collections may terminate the contract at any time by giving notice fourteen days before the end of the month. The right to block user accounts is not affected.</li>
                <li>The extraordinary right to terminate the contract between both parties will be maintained.</li>
            </ol>
            <h2>Establishment of the contract between users</h2>
            <ol>
                <li>Listing products for sale on the platform provided by the provider constitutes a proposal to establish a legal contract.</li>
                <li>If a buyer accepts such a proposal by adding one or more products to his shopping cart and confirming the purchase, a legally valid contract will be established between the buyer and the seller.</li>
                <li>The purchase price must be paid within one week after the establishment of said contract. The seller shall have the right to cancel the order after this period has expired. Cards & Collections may cancel an order that has not been paid for four weeks after the commercial agreement.</li>
                <li>he seller is obliged to ship the order within one week after the payment is made. In addition, you must send it using the shipping method chosen by the buyer. The buyer will have the right to cancel the order after this period has expired without any cost. The supplier can cancel an order that has not been shipped four weeks after the trade agreement.</li>
                <li>The respective price of the item is the final price, including any applicable TAX. Shipping costs are not included in the price of the item</li>
                <li>The comments section exists solely for the exchange of complementary information about the product itself. All information in the comment field must be correct and must not contradict the product information.</li>
                <li>You are obliged to always indicate the correct location of the item (that is, the physical place where the items for sale start from) in your offer of sale. If the buyer incurs additional expenses through your fault, you are obliged to compensate him.</li>
            </ol>
            <h2>Rights of use to upload content</h2>
            <ol>
                <li>You grant Cards & Collections transferable and unlimited rights of use, in terms of space and time, for the content that you post on the online trading platform to the extent necessary to operate the online trading platform.</li>
                <li>Specifically, you grant Cards & Collections the right to upload content related to the trading platform and to duplicate the content as necessary (copies on servers, etc.). In addition, you grant Cards & Collections the right to edit content to better present it on the website. Editing may mean shortening or supplementing texts, cropping uploaded images or reducing them. In addition, Cards & Collections has the right to reproduce, communicate and publish the content available in the highest possible quality through different devices and different networks (public and private), especially the right to transfer the content available by fixed or mobile devices (example: mobile phones, etc.) or from other users. The right of public reproduction specifically includes the right to transmit content on the website.</li>
                <li>If you upload content to the online trading platform, you are allowing other users to use your content. Uploaded text can be read and uploaded images can be viewed. This means that such content is inevitably stored in the terminal memory of another user's device.</li>
            </ol>
            <h2>Illegal content</h2>
            <ol>
                <li>
                    You agree to Cards & Collections not to upload illegal content to the online trading platform. Consequently, you will not contribute, offer or allow access to advertising content (particularly photos, videos, texts, graphics, logos, etc.) that contain:
                    or slander or defamation regardless of who is affected by such content,
                    or pornography, glorification of violence, abuse, immorality or violation of the law for the protection of minors,
                    or content protected by law (for example: copyright, trademark, patent law or utility model law) without being authorized to do so, or
                    or content that acts or assists in unfair competition conduct, including campaigns to attract customers.
                </li>
                <li>
                    Likewise, you are prohibited from publicly disclosing or reproducing the contents of the digital trading platform or of other users, unless this public disclosure and reproduction is contemplated in the good uses of the online trading platform or that the other user agrees to its disclosure and public reproduction. In addition, you are prohibited from using hyperlinks in your offers. Cards & Collections reserves the right to remove such links immediately. Offering fictitious products in order to offer services outside the platform or to get votes is prohibited.
                </li>
            </ol>
            <h2>Cards & Collections Liability</h2>
            <ol>
                <li>Cards & Collections is responsible for the legal provisions with the following exceptions: if your damages are the result of a loss of data, Cards & Collections will not be responsible, provided that the damages could have been avoided by you by means of periodic saving and full of relevant data. The liability of Cards & Collections for damages not intrinsic to the contract is excluded. This would not apply if the damage that impairs life, body or health, was caused intentionally or by gross negligence, as a result of the absence of the guaranteed properties or a culpable violation of a serious contractual obligation, of compliance with the contract in the first place and on whose performance the contracting parties can regularly rely. Liability in accordance with product liability legislation is excluded. However, to the extent permitted by law, the supplier is not responsible for product errors or incorrect or misleading information provided by the manufacturer, as well as data taken from the manufacturer regarding individual products.</li>
                <li>Cards & Collections is not responsible for the correctness, quality, completeness, reliability, type or credibility of the content published by the user. These do not represent in any case the opinion of the provider and, in particular, the provider does not consider them to be its property. The provider is also not responsible for the formation, implementation and fulfilment of contracts between users. It only provides the trading platform for the sole purpose of posting offers.</li>
                <li>The provider is not responsible in case of possible damage or misuse of personal data that may arise from the use of an application managed by third parties.</li>
            </ol>
            <h2>User responsibility</h2>
            <ol>
                <li>You are responsible for any rights granted, contributions, content and offers from your user profile.</li>
                <li>In the event that Cards & Collections services are used by a third party, you must indemnify Cards & Collections fully and upon request for liability and costs, including legal fees, for failing to fulfill your obligations under the grant of rights. Cards & Collections will immediately inform you of the lawsuit and give you the opportunity to defend yourself. It will also apply to claims by third parties when the content violates the rights of third parties regardless of authorship, especially in terms of rights of use and exploitation contemplated by copyright law.</li>
                <li>You are responsible, with respect to Cards & Collections and third parties, for your own contributions, comments, observations and any content of the user profile, specifically regarding the accuracy and veracity of the content. If another user or a third party takes legal action against Cards & Collections for immoral contributions, comments, observations, user profile content or offers, you must indemnify Cards & Collections for any liability and for the costs of the aforementioned scope.</li>
                <li>The use of the online trading platform through an application managed by third parties and similar programs or applications must be done solely at your own risk.</li>
            </ol>
            <h2>User account and Cards & Collections credits</h2>
            <ol>
                <li>Cards & Collections makes available to registered members a user account on the digital platform for the purchase, storage and transfer of credits to Cards & Collections. Credits can only be used to make purchases on the digital platform. The use of this account is free for the user. The user account is a clearing account for credits purchased by the user or transferred to the user and functions as a credit account.</li>
                <li>You can load credits in your user account by purchasing said credits from the provider and paying the corresponding amount. With the credits credited to your user account you will be able to make payments to the provider for the amount of the account balance.</li>
            </ol>
            <h2>Changes to these legal conditions</h2>
            <ol>
                <li>Cards & Collections reserves the right to modify these legal conditions at any time.</li>
                <li>Changes and new legal conditions will be notified to you by email. The new legal conditions will be considered accepted if you do not object within 6 weeks of receiving the email. This objection must be submitted in writing. In this email, Cards & Collections will inform you separately about your possibility of objection, the deadline, the form and the consequences of your inactivity.</li>
            </ol>
            <h2>Electronic communications</h2>
            <p>By using Cards & Collections and sending us emails, text messages, and other electronic communications, you may be communicating with us via electronic means. You consent to receiving communications from us electronically, such as e-mails, texts, mobile push notifications, or notices and messages on this site. You can retain copies of these communications for your records. All agreements, notices, disclosures, and other communications we provide to you electronically satisfy any legal requirement that they be in writing.</p>
            <h2>Copyright, Trademarks, Patents, and Licenses</h2>
            <p>
                Cards & Collections owns all the content found on the site, including graphics, text, buttons, logos, videos, audio, downloadable files, pricing information, and card databases.
            </p>
            <p>
                Furthermore, the graphics, logos, page headers, and button icons on this site are trademarks or trade dress of Cards & Collections in Singapore and other countries. It is prohibited to use this material outside of Cards & Collections without express written permission, especially if it has the potential to cause confusion among customers.
            </p>
            <p>
                Cards & Collections may also use trademarks that are not its own, such as the Magic: The Gathering logo. The materials are the property of their respective owners, who may or may not be affiliated with Cards & Collections.
            </p>
            <h2>Access and Your Account</h2>
            <p>
                You are responsible for maintaining the confidentiality of your account and password and restricting access to your account if you create an account on Cards & Collections. In addition, you agree to accept responsibility for all activities that occur under your account or password, including any payments due on purchases.
            </p>
            <p>
                Cards & Collections does sell products that are intended for ages thirteen (13) and up, but it sells them to adults who can purchase them with a credit card or other permitted payment method. You may only use Cards & Collections under the supervision of a parent or legal guardian if you are under the age of eighteen (18). At its sole discretion, Cards & Collections may refuse service, terminate accounts, remove or edit content, or cancel orders.
            </p>
            <p>
                Transactions on the SITE are processed in Singapore dollars (SGD).
            </p>
            <h2>Limitations of Liability</h2>
            <p>
                All information, content, materials, products and services provided by Cards & Collections are provided "as is" and "as available". Cards & Collections not make any representations or warranties as to the performance or operation of Cards & Collections services. This is in addition to any information, content, materials or products available to you. All risks associated with your use of Cards & Collections are your own.
            </p>
            <p>
                Cards & Collections disclaims all warranties to the fullest extent permitted by law. Specifically, Cards & Collections does not warrant that its servers or electronic communications are free of viruses or other harmful components. Moreover, Cards & Collections is not liable for any damages or losses caused by its information, content, materials, or products.
            </p>
            <h2>Applicable Law and Governing Jurisdiction</h2>
            <p>
                In using Cards & Collections, you agree that federal law and the laws of Singapore will govern these Terms of Service.
                In addition, you agree that any dispute between Cards & Collections and you.
                Any questions regarding these Terms of Service may be sent directly to our customer service team via email at the following address: <a href="mailto:support@cardsandcollections.com">support@cardsandcollections.com</a>
            </p>
        </div>
    )
}

export default TOSScreen