const initState = {
    isSending: false,
    status: null,
    alert: null
}

const suggestion = (state = initState, action) => {
    switch (action.type) {
        case 'SEND_SUGGESTION_REQUEST':
            return Object.assign({}, state, { isSending: true })
        case 'SEND_SUGGESTION_SUCCESS':
            return Object.assign({}, state, { isSending: false, alert: action.alert, status: 200 })
        case 'SEND_SUGGESTION_FAILURE':
            return Object.assign({}, state, { isSending: false, alert: `${action.alert}`, status: action.status })
        case 'DEL_ALERT_SUGGESTION':
            return Object.assign({}, state, { alert: null, status: null })
        default:
            return state
    }
}

export const getIsSending = state => {
    return state.suggestion.isSending
}
export const getStatus = state => {
    return state.suggestion.status
}
export const getAlert = state => {
    return state.suggestion.alert
}
export default suggestion
