import React from 'react'
import Translation from '../global/Translation'

import Spinner from '../global/Spinner'
import Alerts from '../alerts/Alerts'
import { defineMessages } from 'react-intl'

import Select from 'react-select'

defineMessages({
    CUSTOMER_REQUIRED: {
        id: 'WRITE_YOUR_SUGGESTION_HERE',
        description: 'WRITE_YOUR_SUGGESTION_HERE',
        defaultMessage: 'Write your suggestion here'
    },
    SUBJECT: {
        id: 'subject',
        description: 'subject',
        defaultMessage: 'Subject'
    }
})

export default class Suggestion extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            validateCountCharacters: false,
            countCharacters: 0
        }
    }
    getTipology = () => {
        let options = ['Portfolio', 'Checkout', 'Personal Area']
        return options.map(val => {
            return { value: val, label: val }
        })
    }
    canSendSuggestion = () => {
        return 50 <= this.state.countCharacters && this.state.countCharacters <= 1200
    }
    handleSubmit = e => {
        e.preventDefault()
        if (this.canSendSuggestion()) {
            let suggestion =
                // 'Type: ' +
                // this.props.userType +
                // '\nImpersonate by: ' +
                // this.props.impersonatedBy +
                // '\nCenterId: ' +
                // this.props.centerId +
                '\n\n\n' +
                this.suggestionInput.value

            this.props.sendSuggestion(this.nameInput.value, this.emailInput.value, this.subjectInput.value, this.state.tipology.label, suggestion).then(() => {
                this.suggestionInput.value = ''
                this.setState({ countCharacters: 0 })
            })
        }
    }
    render() {
        let { isSending } = this.props
        const spinner = isSending ? <Spinner isLoading={isSending} /> : null
        return (
            <section className="suggestions">
            <div className="mb-3 container-fluid">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <div className="suggestions-form">
                                <div className="row">
                                    <div className="col-10 offset-1">
                                        <h4>
                                            <Translation id="msg_suggestion_mailbox" defaultMessage="Suggestion mailbox" />
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-10 offset-1">
                                        {spinner}
                                        <Alerts alert={this.props.alert} status={this.props.status} />
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row pb-3">
                                                <div className="col-6">
                                                    <input
                                                        name="email"
                                                        id="input_email"
                                                        required
                                                        type="email"
                                                        className="form-control"
                                                        placeholder={this.props.intl.formatMessage({ id: 'email' })}
                                                        ref={node => {
                                                            this.emailInput = node
                                                        }}
                                                        data-cy="suggestions-input_email"
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        name="name"
                                                        id="input_name"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={this.props.intl.formatMessage({ id: 'name' })}
                                                        ref={node => {
                                                            this.nameInput = node
                                                        }}
                                                        data-cy="suggestions-input_name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row pb-3">
                                                <div className="col-6">
                                                    <input
                                                        name="subject"
                                                        id="input_name"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={this.props.intl.formatMessage({ id: 'subject' })}
                                                        ref={node => {
                                                            this.subjectInput = node
                                                        }}
                                                        data-cy="suggestions-input_subject"
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <Select
                                                        required                                                        
                                                        placeholder="Select a tipology"
                                                        value={this.state.tipology}
                                                        onChange={value => {
                                                            this.setState({ tipology: value })
                                                        }}
                                                        options={this.getTipology()}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row pb-3">
                                                <div className="col-12">
                                                    <textarea
                                                        minlength="50"
                                                        maxlength="1200"
                                                        name="suggestion"
                                                        id="textarea-suggestion"
                                                        className="form-control"
                                                        required
                                                        onFocus={this.props.deleteAlert}
                                                        placeholder={this.props.intl.formatMessage({ id: 'WRITE_YOUR_SUGGESTION_HERE' })}
                                                        onChange={node =>
                                                            this.setState({
                                                                countCharacters: node.target.value.length
                                                            })
                                                        }
                                                        ref={node => {
                                                            this.suggestionInput = node
                                                        }}
                                                        data-cy="suggestions-text-area"
                                                    />
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    <div className="suggestion__label_count">
                                                        <Translation id="minimum_characters" defaultMessage="Minimum {val} characters" values={{ val: 50 }} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <div className="suggestion__the_count">
                                                        <span
                                                            className={`current ${
                                                                1200 >= this.state.countCharacters && this.state.countCharacters >= 50
                                                                    ? 'validate'
                                                                    : this.state.countCharacters > 1200
                                                                    ? 'error'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {this.state.countCharacters}
                                                        </span>{' '}
                                                        / <span className="count_max">1200</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 buttons">
                                                    <input
                                                        type="submit"
                                                        className="bt bt-inverted "
                                                        data-cy="btn-send-suggestions"
                                                        value={this.props.intl.formatMessage({ id: 'send' })}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
