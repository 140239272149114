import { totalPrice } from './../../store/cart/reducers';
import { connect } from 'react-redux'
import MiniCart from './MiniCart'
import { removeCartLine, changeCartLineQty, changeCartLineSegmentation, emptyCart, fetchCart } from '../../store/cart/actions'
import {
    totalSelector,
    qtySelector,
    showModalSelector,
    getLines,
} from '../../store/cart/reducers'
import * as ui from '../../store/ui/actions'
import { injectIntl } from 'react-intl'

import { getBankData, getHasFilledShippingAddress, getUserType, getVerified } from '../../store/login/reducers'
import {  getLanguage } from '../../store/ui/reducers'

const mapStateToProps = state => {
    let language = getLanguage(state)

    return {
        lines: getLines(state),
        totalPrice: totalPrice(state),
        totalQty: qtySelector(state),
        show: showModalSelector(state),
        lang: getLanguage(state),
        hasFilledShippingAddress: getHasFilledShippingAddress(state),
        verified: getVerified(state),
        bankData: getBankData(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCart: centerId => {
            dispatch(fetchCart(centerId))
        },
        // onDelete: lineId => {
        //     dispatch(removeCartLine(lineId))
        // },
        // changeCartLineQty: (lineId, qty) => {
        //     dispatch(changeCartLineQty(lineId, qty))
        // },
        onClose: () => {
            dispatch(ui.closeCartModal())
        },
        emptyCart: () => {
            dispatch(emptyCart())
        }
    }
}

const MiniCartContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MiniCart)

export default injectIntl(MiniCartContainer)
