import { getLoyaltiesOfUser } from './../../store/login/reducers'
import React from 'react'
import Spinner from '../global/Spinner'
import Translation from '../global/Translation'
import { injectIntl } from 'react-intl'
import { useSelector, connect } from 'react-redux'
import { getAvailableLangs } from '../../store/products/reducers'

const DisplayPrice = ({ price, intl, item = null, showZero = false }) => {
    const loyaltiesOfUser = useSelector((state) => getLoyaltiesOfUser(state))
    let discountAvailableOnThisItem = null

    if (item) {
        discountAvailableOnThisItem = loyaltiesOfUser.find((loyalty) => {
            return loyalty.seller_id == item.user_id
        })
    }

    let numberFormat = new Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    if (!showZero) {
        if (!price || price == 0) {
            return <span className='price'>-</span>
        }
    }
    let previousPrice = price
    if (discountAvailableOnThisItem) {
        price = price - (price * discountAvailableOnThisItem.discount / 100)
    }

    return (
        <span className='price'>
                {previousPrice > 0 && discountAvailableOnThisItem && <small style={{
                    textDecoration: 'line-through',
                    marginRight: '8px'
                }}>S${numberFormat.format(previousPrice)}</small>}
            S${numberFormat.format(price)}
            {/* {' '}{langItem.label} */}
            </span>
    )
}

export default injectIntl(connect()(DisplayPrice))
