import { connect } from 'react-redux'
import MiniNotifications from './MiniNotifications'
import { injectIntl } from 'react-intl'
import { fetchMyNotifications } from '../../store/notifications/actions'
import { closeNotificationsModal } from '../../store/ui/actions'
import { getNotifications, showModalSelector } from '../../store/notifications/reducers'
import { getUserName } from '../../store/login/reducers'
import { fetchPendingTasks } from '../../store/pending-tasks/actions'
import { getPendingTasks, qtyPendingTasksSelector } from '../../store/pending-tasks/reducers'

const mapStateToProps = state => {

    return {
        notifications: getNotifications(state),
        qtyPendingTasks: qtyPendingTasksSelector(state),
        show: showModalSelector(state),
        userName: getUserName(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchNotifications: () => {
            dispatch(fetchMyNotifications())
        },
        fetchPendingTasks : () => {
            dispatch(fetchPendingTasks())
        },
        onClose: () => {
            dispatch(closeNotificationsModal())
        },
        // emptyNotifications: () => {
        //     dispatch(emptyNotifications())
        // }
    }
}

const MiniNotificationsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MiniNotifications)

export default injectIntl(MiniNotificationsContainer)
