import React from 'react'
import { defineMessages } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { getIsBusiness, getAllowPickup } from '../../../../store/login/reducers'
import { updateBusinessConfig } from '../../../../store/login/actions'
import { useFormik } from 'formik'
import Translation from '../../../global/Translation'
import Button from '../../../global/Button'

const BusinessConfigForm = (props) => {
    const dispatch = useDispatch()
    const isBusiness = useSelector((state) => getIsBusiness(state))
    const allowPickup = useSelector((state) => getAllowPickup(state))

    const initialValues = {
        allowPickup: allowPickup ? allowPickup : false,
    }

    const onSubmit = (values, action) => {
        dispatch(updateBusinessConfig(values))
        action.resetForm({
            values: {
                allowPickup: values.allowPickup,
            },
        })
    }

    const handleClickSubmit = (event) => {
        formik.submitForm()
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
    })

    if (!isBusiness) {
        return null
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="mb-3">
                    <div className="offset-md-1 col-10">
                        <form className="form">
                            <div className="row">
                                <h3>
                                    <Translation id='businessConfig' defaultMessage='Business Config' />
                                </h3>
                                <div className="card">
                                    <div className="card-body">                                                            
                                        <div className="form-check">
                                            <label htmlFor="allowPickup" className="form-check-label">
                                                <Translation id="ALLOW_PICK_UP" defaultMessage="Allow pick up in shop" />
                                            </label>
                                            <input
                                                type="checkbox"
                                                id="allowPickup"
                                                name="allowPickup"
                                                className="form-check-input"
                                                value={formik.values.allowPickup}
                                                checked={formik.values.allowPickup}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                            
                                        <div className="mb-3">
                                            <Button type="submit" disabled={!formik.dirty || !formik.isValid} onClick={handleClickSubmit}>
                                                <Translation id="update" defaultMessage="Update" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BusinessConfigForm
