import React from 'react'
import Translation from '../global/Translation'

import Button from '../global/Button'
import Alerts from './Alerts'

class Maintenance extends React.Component {
    componentDidMount() {
        // this.props.fetchMaintenance()
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template">
                            <div>
                                <h1>
                                    <Translation id="we_are_under_maintenance" defaultMessage="We are under maintenance" />
                                </h1>
                                <h2>
                                    <Translation id="candc_will_be_back_soon" defaultMessage="Cards & Collections will be back soon!" />
                                </h2>

                                <Translation id="thanks_for_your_patience" defaultMessage="Thanks for your patience" />
                                {/* {this.props.maintenanceScheduledText && (
                                    <div>
                                        <Alerts status="primary" alert={<div dangerouslySetInnerHTML={{ __html: this.props.maintenanceScheduledText }} />} />
                                    </div>
                                )} */}
                                <div className="logo-maintenance_wrapper">
                                    <i className="fal fa-cogs fa-9x" />
                                </div>

                                <div>
                                    <Button onClick={() => window.location.reload()}>
                                        <Translation id="reload" defaultMessage="Reload" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Maintenance
