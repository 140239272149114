import { connect } from 'react-redux'
import { getUserName } from '../../store/login/reducers'
import { push } from 'react-router-redux'
import Tasks from './Tasks'
import { getPendingTasks } from '../../store/pending-tasks/reducers'
import { fetchPendingTasks } from '../../store/pending-tasks/actions'

const mapStateToProps = (state, { match }) => {
    return {
        userName: getUserName(state),
        pendingTasks: getPendingTasks(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => Promise.resolve(dispatch(push(url))),
        fetchPendingTasks: () => dispatch(fetchPendingTasks())
    }
}


const TasksContainer = connect(mapStateToProps, mapDispatchToProps)(Tasks)

export default TasksContainer
