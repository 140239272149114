import React from 'react'
import { injectIntl } from 'react-intl'
import LocalizedLink from '../components/global/LocalizedLink'
import Translation from '../components/global/Translation'
import { useSelector } from 'react-redux'
// este fichero fue un primer intento de aviso de completar el registro.
// Por aroa se opta por hacer una vista en el back pq era mas sencillo
const ThanksYouForVerifyScreen = props => {

    const name = useSelector(state => state.login.name)

    const email = process.env.REACT_APP_EMAIL_SUPPORT;

    return (
        <div>
            <div className="container-fluid registerForm mb-5" id="registerForm">
                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <div className="q-top d-none d-xl-block">
                                <i className="fas fa-thumbs-up" style={{ padding: '0 15px' }}></i>
                                Your registration is now complete
                            </div>
                            <div className="row my-3">
                                <div className="col-12 lab">
                                    <h2 className="text-center">What´s up , {name} !</h2>
                                    <p className="col-10 offset-1 mt-3">Hey Buddy! Welcome to the Cards & Collections community.</p>
                                    <p className="col-10 offset-1 mt-3">Now your registration is complete !</p>

                                    <p className="col-10 offset-1 mt-3">
                                        If you have any question, contact us at <a href={'mailto:' + email}>{email}</a>
                                        <br />
                                    </p>
                                    <h2 className="col-10 offset-1 mt-3">Enjoy the experience !</h2>
                                    <LocalizedLink routeId="ROUTE_HOME" className="col-10 offset-1 mt-3">
                                        Back to main page
                                    </LocalizedLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default (injectIntl((ThanksYouForVerifyScreen)))