import React from "react";

import { Link } from "react-router-dom";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";

import { getCurrentUrl } from "../../store/ui/reducers";

const mapStateToProps = (state) => {
  return { currentUrl: getCurrentUrl(state) };
};

const LocalizedLink = ({
  currentUrl,
  intl,
  routeId,
  params = {},
  children,
  className,
  queryString,
  target,
  onClick,
  enableActiveClass = true
}) => {
  const route = Object.keys(params).reduce(function (previous, value) {
    let optional = previous.replace(":" + value + "?", params[value]);
    return optional.replace(":" + value, params[value]);
  }, intl.formatMessage({ id: routeId }));
  let url = route;
  let active = url === currentUrl.pathname;
  if (queryString !== undefined) {
    url = route + queryString;
    // active = active && currentUrl.search.startsWith(queryString) && currentUrl.search !== ''
    active =
      active && currentUrl.search === queryString && currentUrl.search !== "";
  } else {
    active = active && currentUrl.search === "";
  }
  // Quitar barra (/) final
  url = url.replace(new RegExp("[/]+$"), "");
  return (
    <Link
      to={url}
      className={`${className} ${active && enableActiveClass ? " active" : ""}`}
      target={target}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
defineMessages({});
export default injectIntl(connect(mapStateToProps, null)(LocalizedLink));
