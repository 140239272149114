import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import LocalizedLink from '../global/LocalizedLink'
import Translation from '../global/Translation'

defineMessages({
    CLICK_HERE: {
        id: 'CLICK_HERE',
        description: 'CLICK_HERE',
        defaultMessage: 'Click here for more information'
    },
    FILL_FINANCIAL_INFO_TO_TRADE:{
        id: 'FILL_FINANCIAL_INFO_TO_TRADE',
        description: 'FILL_FINANCIAL_INFO_TO_TRADE',
        defaultMessage: 'Fill your financial information to trade'
    },

});

const SubheaderAlerts = ({ alert, status, values, routeId, params }) => {
    let cssClasses = 'alert mb-1 mt-1 candc-alert '
    let icon = ''
    if (status === 'warning') {
        cssClasses += 'alert-warning'
        icon = 'campaign'
    } else if (status === 'info') {
        cssClasses += 'alert-info'
        icon = 'campaign'
    } else if (status === 'primary') {
        cssClasses += 'alert-primary'
        icon = 'campaign'
    } else if (status >= 200 && status < 300) {
        cssClasses += 'alert-success'
        icon = 'check_circle'
    } else if (status >= 300) {
        cssClasses += 'alert-danger'
        icon = 'report'
    } else {
        cssClasses += 'alert-dark'
        icon = 'report'
    }

    const error =
        alert != null ? (
            <div className={cssClasses} role="alert"
                 style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', }}>
                <span className="material-icons-outlined" style={{
                    fontSize: '32px',
                    borderRight: '2px solid black',
                    marginRight: '8px',
                    paddingRight: '8px'
                }}>
                    {icon}
                </span>
                <Translation id={alert} values={values ? values : {}} />
                &nbsp;
                <LocalizedLink routeId={routeId} className="colored-link" params={params}>
                    <Translation
                        id={"CLICK_HERE"}
                        values={values ? values : {}}
                    />
                </LocalizedLink>
            </div>
        ) : (
            ''
        )

    return <div>{error}</div>
}

class SubHeader extends React.Component
{

    render() {

        if(this.props.isLogged && !this.props.verified)
        {
            return <React.Fragment><SubheaderAlerts alert={"VERIFY_YOUR_EMAIL"} status={"warning"} routeId={"ROUTE_RESEND_VERIFICATION_MAIL_PAGE"} /></React.Fragment>
        }

        if(this.props.isLogged &&  !this.props.hasFilledShippingAddress)
        {
            const routeId = this.props.intl.formatMessage({ id: 'ROUTE_USER_PROFILE' }).replace(':view?', 'shipping-information')
            return <React.Fragment><SubheaderAlerts alert={"SHIPPING_ADDRESS_REQUIRED"} status={"warning"} routeId={routeId} /></React.Fragment>
        }

        if(this.props.isLogged && (!this.props.bankData || !this.props.bankData.validated))
        {
            const routeId = this.props.intl.formatMessage({ id: 'ROUTE_USER_PROFILE' }).replace(':view?', 'financial-information')
            return <React.Fragment><SubheaderAlerts alert={"FILL_FINANCIAL_INFO_TO_TRADE"} status={"warning"} routeId={routeId} /></React.Fragment>
        }

        return ''

    }
}

export default injectIntl(SubHeader)

// <LocalizedLink routeId="ROUTE_PRODUCT" params={{ id: line.productId }}>
// <span className="title">{line.product.name}</span>
// </LocalizedLink>