import { getProductFieldsByCategory } from './../../../store/products/reducers';
import { useSelector } from 'react-redux';
import Translation from '../../global/Translation'
import React from 'react'

export const ItemFeaturesAndDetailsInputs = ({ isStockBlocked, isSigned, isPlayset, isAltered, isRated, isFoil, toggleIsSigned, toggleIsPlayset, toggleIsAltered, toggleIsRated, toggleIsFoil,productCategory}) => {
    const fields = useSelector((state) => getProductFieldsByCategory(state))
    return (
        <div
            className="features-and-details row"
            style={{
                borderRadius: '8px',
                border: '2px solid lightgray',
                marginTop: '16px',
                marginBottom: '16px',
                padding: '16px',
            }}
        >
            <div className="col-12 col-lg-4">Features and details</div>
            <div className="col">
                {fields[productCategory].includes('signed') &&
                <div className="form-check">
                    <input name="isSigned" type="checkbox" disabled={isStockBlocked} onChange={toggleIsSigned} checked={isSigned} />
                    <label htmlFor="signed">
                        <Translation id="signed" defaultMessage="Signed" />
                    </label>
                </div>}
                {fields[productCategory].includes('playset') &&
                <div className="form-check">
                    <input name="isPlayset" type="checkbox" disabled={isStockBlocked} onChange={toggleIsPlayset} checked={isPlayset} />
                    <label htmlFor="playset">
                        <Translation id="playset" defaultMessage="Playset" />
                    </label>
                </div>}
                {fields[productCategory].includes('altered') &&
                <div className="form-check">
                    <input name="isAltered" type="checkbox" disabled={isStockBlocked} onChange={toggleIsAltered} checked={isAltered} />
                    <label htmlFor="altered">
                        <Translation id="altered" defaultMessage="Altered" />
                    </label>
                </div>}
            </div>
            <div className="col">
                {fields[productCategory].includes('rated') &&
                <div className="form-check">
                    <input name="isRated" type="checkbox" disabled={isStockBlocked} onChange={toggleIsRated} checked={isRated} />
                    <label htmlFor="rated">
                        <Translation id="rated" defaultMessage="Rated" />
                    </label>
                </div>
                }
                {fields[productCategory].includes('foil') &&
                <div className="form-check">
                    <input name="isFoil" type="checkbox" disabled={isStockBlocked} onChange={toggleIsFoil} checked={isFoil} />
                    <label htmlFor="foil">
                        <Translation id="foil" defaultMessage="Foil" />
                    </label>
                </div>
                }
            </div>
        </div>
    )
}
