import { fetchCredit } from '../login/actions'
import * as api from '../api'
import { getToken } from '../login/reducers'
import { getLanguage } from '../ui/reducers'
import { push } from 'react-router-redux'


export const fetchOrders = (productId) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_ORDERS_REQUEST' })
    return api
        .fetchOrders(getToken(getState()))
        .then((response) => {
            return dispatch({ type: 'FETCH_ORDERS_SUCCESS', orders: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_ORDERS_FAILURE', response: error.response })
        })
}
export const fetchSales = (productId) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_ORDERS_REQUEST' })
    return api
        .fetchSales(getToken(getState()))
        .then((response) => {
            return dispatch({ type: 'FETCH_ORDERS_SUCCESS', orders: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_ORDERS_FAILURE', response: error.response })
        })
}
export const fetchBuys = (productId) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_ORDERS_REQUEST' })
    return api
        .fetchBuys(getToken(getState()))
        .then((response) => {
            return dispatch({ type: 'FETCH_ORDERS_SUCCESS', orders: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_ORDERS_FAILURE', response: error.response })
        })
}
export const fetchOrderById = (id) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_ORDER_BY_ID_REQUEST' })
    return api
        .fetchOrderById(getToken(getState()),id)
        .then((response) => {
            return dispatch({ type: 'FETCH_ORDER_BY_ID_SUCCESS', order: response.data })
        })
        .catch((error) => {
            dispatch(push('/dashboard/transactions'))
            return dispatch({ type: 'FETCH_ORDER_BY_ID_FAILURE', response: error.response })
        })
}


export const changeOrderStatus = (orderId,action,payload = null) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'CHANGE_ORDER_STATUS_REQUEST',action,payload })
    return api
        .changeOrderStatus(getToken(getState()), orderId,action,payload)
        .then((response) => {
            dispatch(fetchCredit())
            return dispatch({ type: 'CHANGE_ORDER_STATUS_SUCCESS', order: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'CHANGE_ORDER_STATUS_FAILURE', response: error.response })
        })
}

