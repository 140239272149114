import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import ChatsContainer from '../components/chats/ChatsContainer'

const ChatScreen = (props, intl) => {
    return (
        <div className='container-fluid'>
            <ChatsContainer mode={'edit'}></ChatsContainer>
        </div>
    )
}

export default withRouter(injectIntl(connect()(ChatScreen)))
