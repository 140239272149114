import React from 'react'
import paynow from '../../assets/img/paynow.jpg'
import Button from './Button'

export const DownloadPaynowImage = () => {
    const downloadQRCode = (e) => {
        e.preventDefault()

        let anchor = document.createElement("a");
        anchor.href = paynow;
        anchor.download = `Paynow Cards And Collections.jpg`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    return (
        <div>
            <p>
                <img style={{ width: '100%' }} src={paynow} />
            </p>
            <Button onClick={downloadQRCode}>
                Download Paynow QR code
            </Button>
        </div>
    )

}