import jwtDecode from 'jwt-decode'
import { defineMessages } from 'react-intl'

const initState = {
    successMessage: null,
    failureMessage: null,
    values: null,
}
//ACCOUNT_CREATED_SUCCESSFULLY
//CART_LINE_ADDED_SUCCESS
//CART_LINE_REMOVED_SUCCESS
//EMPTY_CART_SUCCESS
//CART_TYPE_INCOMPATIBLE_EXCEPTION
//SUCCESS
//PRODUCT_ADDED_SUCCESFULLY
//CHANGES_SAVED_SUCCESSFULLY
//SOMETHING_WENT_WRONG
//PRODUCT_REMOVED_SUCCESSFULLY
//PRODUCT_UPDATED_SUCCESSFULLY
//REGISTER_SUCCESS
//RETRIEVE_CREDIT_SUCCESS
//BUY_CREDIT_UPDATED_SUCCESS  `Se ha actualizado credit de compra a S$${action.buyCredit}`
const floatingAlerts = (state = initState, action) => {
    //please define error messages translations at floatingAlert component
    switch (action.type) {
        case 'SIMPLE_LOGIN_SUCCESS':
            const decoded = jwtDecode(action.token)
            return { ...state, successMessage: decoded.accountCreated ? 'ACCOUNT_CREATED_SUCCESSFULLY' : null, values: null, failureMessage: null }
        case 'CLOSE_FLOATING_ALERT':
            return { ...state, successMessage: null, failureMessage: null, values: null }
        case 'FLOATING_ALERTS_ADD_SUCCESS_MESSAGE':
            return { ...state, successMessage: action.successMessage, values: action.values, failureMessage: null }
        case 'FLOATING_ALERTS_ADD_FAILURE_MESSAGE':
            return { ...state, successMessage: null, values: action.values, failureMessage: action.failureMessage }
        case 'ADD_CART_LINE_SUCCESS':
            return { ...state, successMessage: 'CART_LINE_ADDED_SUCCESS', values: action.values, failureMessage: null }
        case 'REMOVE_CART_LINE':
            return { ...state, successMessage: 'CART_LINE_REMOVED_SUCCESS', values: action.values, failureMessage: null }
        case 'EMPTY_CART':
            return { ...state, successMessage: 'EMPTY_CART_SUCCESS', values: action.values, failureMessage: null }
        case 'ADD_CART_LINE_FAILURE':
        case 'CHANGE_CART_LINE_QTY_FAILED':
            let values = {}
            // console.log(action.payload)
            if (action.payload.statusText === 'CART_TYPE_INCOMPATIBLE_EXCEPTION') {
                return { ...state, failureMessage: 'CART_TYPE_INCOMPATIBLE_EXCEPTION' }
            }
            return { ...state }
        case 'RESEND_VERIFICATION_EMAIL_SUCCESS':
            return { ...state, successMessage: 'Success', values: null, failureMessage: null }
        case 'RESEND_VERIFICATION_EMAIL_FAILURE':
            return { ...state, successMessage: null, values: null, failureMessage: 'ERROR_SENDING_EMAIL' }
        case 'SEND_ATTACHMENT_MESSAGE_CHAT_FAILED':
            return { ...state, successMessage: null, values: null, failureMessage: action.failureMessage }
        case 'ADD_TO_SALE_SUCCESS':
        case 'ADD_TO_BUY_SUCCESS':
        case 'ADD_TO_COLLECTION_SUCCESS':
            return { ...state, successMessage: 'PRODUCT_ADDED_SUCCESFULLY' }
        case 'UPDATE_PERSONAL_INFORMATION_SUCCESS':
        case 'CHANGE_PASSWORD_SUCCESS':
        case 'UPDATE_SHIPPING_INFORMATION_SUCCESS':
        case 'UPDATE_FINANCIAL_INFORMATION_SUCCESS':
        case 'UPDATE_BUSINESS_CONFIG_SUCCESS':
            return { ...state, successMessage: 'CHANGES_SAVED_SUCCESSFULLY', values: null, failureMessage: null }
        case 'SET_BUY_CREDIT_SUCCESS':
            return { ...state, successMessage: `BUY_CREDIT_UPDATED_SUCCESS`, values: { buyCredit: action.buyCredit }, failureMessage: null }
        case 'UPDATE_PERSONAL_INFORMATION_FAILURE':
        case 'UPDATE_SHIPPING_INFORMATION_FAILURE':
        case 'UPDATE_FINANCIAL_INFORMATION_FAILURE':
        case 'UPDATE_BUSINESS_CONFIG_FAILURE':
            return { ...state, successMessage: null, values: null, failureMessage: 'SOMETHING_WENT_WRONG' }
        case 'REMOVE_FROM_COLLECTION_SUCCESS':
        case 'REMOVE_FROM_SALE_SUCCESS':
            return { ...state, successMessage: 'PRODUCT_REMOVED_SUCCESSFULLY' }
        case 'CREATE_ACCOUNT_SUCCESS':
            return { ...state, successMessage: 'REGISTER_SUCCESS', values: null, failureMessage: null }
        case 'EDIT_ITEM_FROM_SALE_SUCCESS':
        case 'UPDATE_QTY_ITEM_OF_SALE_SUCCESS':
        case 'EDIT_ITEM_FROM_COLLECTION_SUCCESS':
        case 'UPDATE_QTY_ITEM_OF_COLLECTION_SUCCESS':
        case 'UPDATE_QTY_ITEM_OF_BUY_SUCCESS':
            return { ...state, successMessage: 'PRODUCT_UPDATED_SUCCESSFULLY' }
        case 'CREATE_ACCOUNT_FAILURE':
        case 'UPDATE_QTY_ITEM_OF_COLLECTION_FAILURE':
        case 'UPDATE_QTY_ITEM_OF_SALE_FAILURE':
        case 'SET_BUY_CREDIT_FAILURE':
            return { ...state, failureMessage: 'SOMETHING_WENT_WRONG' }
        case 'RETRIEVE_CREDIT_FAILURE':
            return { ...state, failureMessage: action.failureMessage }
        case 'RETRIEVE_CREDIT_SUCCESS':
            return { ...state, successMessage: 'RETRIEVE_CREDIT_SUCCESS' }
        case 'UPDATE_BANK_VALIDATION_SUCCESS':
            return { ...state, successMessage: action.successMessage, values: null, failureMessage: null }
        case 'UPDATE_BANK_VALIDATION_FAILURE':
            return { ...state, successMessage: null, values: null, failureMessage: action.failureMessage }
        case 'SUBMIT_CHECKOUT_FAILURE':
            return { ...state, successMessage: null, values: null, failureMessage: action.errorCode }
        case 'SHIPPING_ADDRESS_REQUIRED':
            return { ...state, successMessage: null, values: null, failureMessage: action.failureMessage }
        case 'REMOVE_FROM_SALE_FAILURE':
        case 'REMOVE_FROM_BUY_FAILURE':
            return { ...state, successMessage: null, values: null, failureMessage: action.errorCode }
        case 'FETCH_ORDER_BY_ID_FAILURE':
            return { ...state, successMessage: null, values: null, failureMessage: 'FAIL_TO_FETCH_ORDER_DATA' }
        case 'UPDATE_PRICE_FROM_PROVIDER_SUCCESS':
            return { ...state, successMessage: 'UPDATE_PRICE_FROM_PROVIDER_SUCCESS', values: null, failureMessage: null }
        case 'UPDATE_PRICE_FROM_PROVIDER_FAILURE':
            return { ...state, successMessage: null, values: null, failureMessage: action.errorCode }
        case 'PAY_PENDING_CREDIT_FAILURE':
            return { ...state, successMessage: null, values: null, failureMessage: 'PAYMENT_CREATE_REQUEST_ERROR' }
        default:
            return state
    }
}

export const getSuccessMessage = (state) => {
    return state.floatingAlerts.successMessage
}
export const getFailureMessage = (state) => {
    return state.floatingAlerts.failureMessage
}
export const getValues = (state) => {
    return state.floatingAlerts.values
}
export default floatingAlerts
