import { connect } from 'react-redux'
import Maintenance from './Maintenance'
import { getMaintenanceScheduled, getMaintenanceScheduledText } from '../../store/ui/reducers'
// import { fetchMaintenance } from '../../store/ui/actions'

const mapStateToProps = (state, { match }) => {
    return {
        // maintenanceScheduled: getMaintenanceScheduled(state),
        // maintenanceScheduledText: getMaintenanceScheduledText(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // fetchMaintenance: () => {
        //     dispatch(fetchMaintenance())
        // }
    }
}
const ErrorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Maintenance)

export default ErrorContainer
