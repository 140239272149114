import React from 'react'

const Modal = props => {
    const { title, children, footer, onClose, size, customClass, showCloseButton = true, showBody = true } = props

    let dialogClasses = ''

    if (size === 'small') {
        dialogClasses += ' modal-sm'
    } else if (size === 'large') {
        dialogClasses += ' modal-lg'
    }
    if (customClass) {
        dialogClasses += ' ' + customClass
    }

    return (
        <div>
            <div className='full-overlay' onClick={onClose} />
            <div className='modal fade show' tabIndex='-1' role='dialog' data-backdrop='static'
                 style={{ display: 'block' }}>
                <div className={'modal-dialog' + dialogClasses} data-cy='global__modal_dialog' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' data-cy='modal__title'>
                                {title}
                            </h5>
                            {showCloseButton && (
                                <div className='close' aria-label='Close' onClick={onClose}>
                                    <span aria-hidden='true' className='material-icons-outlined'
                                                                style={{ fontSize: '32px' }}>
                                        close
                                    </span>
                                    {/*<span aria-hidden='true'>&times;</span>*/}
                                </div>
                            )}
                        </div>
                        {showBody && (
                            <div className='modal-body'>{children}</div>
                        )}
                        <div className='modal-footer'>{footer}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
