import React from 'react'
import OrderFinishedContainer from '../components/order-finished/OrderFinishedContainer'

const OrderFinishedScreen = (props) => (
    <div className="container">
            <OrderFinishedContainer {...props} />
    </div>
)

export default OrderFinishedScreen
