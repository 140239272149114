import { payMyPendingCredit } from './../../store/cart/actions';
import { getCredit, getIsBusiness } from './../../store/login/reducers';
import { connect } from 'react-redux'
import { getOrderActions, getOrderHeadById, getOrderLinesById } from '../../store/orders/reducers'
import { changeOrderStatus, fetchOrderById } from '../../store/orders/actions'
import Order from './Order'
import { getUserId, getUserName } from '../../store/login/reducers'

const mapStateToProps = (state, { match }) => {
    return {
        id: match.params.id,
        orderLines: getOrderLinesById(state, match.params.id),
        order: getOrderHeadById(state, match.params.id),
        orderActions: getOrderActions(state, match.params.id),
        userId: getUserId(state),
        userName: getUserName(state),
        credit: getCredit(state),
        isBusiness: getIsBusiness(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrderById: (id) => {
            return dispatch(fetchOrderById(id))
        },
        changeOrderStatus: (id, action, payload = null) => {
            return dispatch(changeOrderStatus(id, action, payload))
        },
        payMyPendingCredit: (url) => {
            dispatch(payMyPendingCredit(url))
        },
    }
}

const OrderContainer = connect(mapStateToProps, mapDispatchToProps)(Order)

export default OrderContainer
