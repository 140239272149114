import { showingProfileModal } from './../../store/ui/reducers';
import { connect } from 'react-redux'
import ResponsiveProfileMenu from './ResponsiveProfileMenu'
import { getIsLogged, getIsAdmin, getUserName, getName, getUserType, getEmail } from '../../store/login/reducers'
// import { logout } from '../../store/login/actions'
import * as ui from '../../store/ui/actions'
import { getIsAdding } from '../../store/cart/reducers'
import { showMenuSelector } from '../../store/ui/reducers'

const mapStateToProps = (state) => {
    return {
        username: getUserName(state),
        name: getName(state),
        // userimage: getUserImage(state),
        isLogged: getIsLogged(state),
        isAdmin: getIsAdmin(state),
        isAdding: getIsAdding(state),
        showMenu: showingProfileModal(state),
        userType: getUserType(state),
        email: getEmail(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowUpLogin: () => {
            dispatch(ui.showLoginModal())
        },
        onShowUpCart: () => {
            dispatch(ui.showCartModal())
        },
        onShowUpNotifications: () => {
            dispatch(ui.showNotificationsModal())
        }
        // onLogout: () => {
        //     dispatch(logout())
        // },
    }
}
const ResponsiveProfileMenuContainer = connect(mapStateToProps, mapDispatchToProps)(ResponsiveProfileMenu)

export default ResponsiveProfileMenuContainer
