import React, { Component } from 'react'
import isEqual from 'react-fast-compare'
import { clearCurrentChat, createChat, fetchChat, sendMessage, uploadAttachmentMessage } from '../../store/chats/actions'
import { connect } from 'react-redux'
import {
    getChat,
    getCurrentChatRecipientName,
    getMessages,
    getUnreadActiveChatMessages
} from '../../store/chats/reducers'
import { withRouter } from 'react-router-dom'
import { defineMessages, injectIntl } from 'react-intl'
import { getToken, getUserId } from '../../store/login/reducers'
import { ChatMessage } from './ChatMessage'

defineMessages({
    message: {
        id: 'message',
        description: 'message',
        defaultMessage: 'message'
    }
})

const mapStateToProps = state => {
    return {
        chat: getChat(state),
        userId: getUserId(state),
        messages: getMessages(state),
        unreadMessages: getUnreadActiveChatMessages(state),
        userToken: getToken(state),
        currentChatRecipientName: getCurrentChatRecipientName(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchChat: ({ chatId }) => {
            return dispatch(fetchChat({ chatId }))
        },
        createChat: ({ chatId, trackingCode }) => dispatch(createChat({ chatId, trackingCode })),
        sendMessage: (chatId, message) => {
            return dispatch(sendMessage(chatId, message))
        },
        uploadAttachmentMessage: (chatId, file) => {
            return dispatch(uploadAttachmentMessage(chatId, file))
        },
        clearCurrentChat: () => {
            dispatch(clearCurrentChat())
        }
    }
}

class Chat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            message: '',
            chatId: 0
        }
        this.messageBox = React.createRef()
        this.conversationArea = React.createRef()
        this.attachmentMessageRef = React.createRef()
    }

    handleChange = event => {
        // const element = this.messageBox.current
        // const area = this.conversationArea.current
        // const offset = element.offsetHeight - element.clientHeight
        // element.style.height = 'auto'
        // element.style.height = element.scrollHeight + offset + 'px'
        // area.style.height = 'auto'
        // area.style.height = area.scrollHeight + offset + 'px'
        this.setState({ [event.target.name]: event.target.value })
    }

    componentDidMount = () => {
    }
    componentDidUpdate = prevProps => {
        if (
            prevProps.chat.chatId !== this.props.chat.chatId &&
            typeof this.props.chat.chatId !== 'undefined' &&
            this.props.chat.chatId !== -1
        ) {
            this.setState({ chatId: this.props.chat.chatId, isOpen: true })
            if (this.state.isOpen && this.props.chat && this.props.chat.chatId) {
                this.props.clearCurrentChat();
                this.props.fetchChat({
                    chatId: this.props.chat.chatId,
                    orderId: this.props.chat.orderId
                }).then(() => {
                    if (this.props.unreadMessages) {
                        this.setState({ isOpen: true }, () => {
                            this.messagesEnd.scrollIntoView()
                        })
                    }
                })
            }
        }

        if (!isEqual(prevProps.chat.chat, this.props.chat.chat)) {
            setTimeout(() => {
                this.scrollIntoView()
            }, 500)
        }
    }
    componentWillUnmount() {
        this.props.clearCurrentChat();
    }

    handleSubmit = event => {
        event.preventDefault()
        if (this.state.message === '' || !this.state.chatId) return null
        this.props.sendMessage(this.state.chatId, this.state.message).then(() => {
            this.scrollIntoView()
        })
        this.setState({ message: '' })
        this.messageBox.current.value = ''
    }

    openAttachmentDialog = event => {
        event.preventDefault()
        this.attachmentMessageRef.current.click()
    }

    uploadAttachment = event => {
        event.stopPropagation()
        event.preventDefault()
        if (!event.target.files.length) {
            return
        }
        let file = event.target.files[0]
        this.props.uploadAttachmentMessage(this.state.chatId, file)
        this.attachmentMessageRef.current.value = null
    }
    scrollIntoView = () => {
        const el = this.conversationArea.current
        el && el.scrollTo(0, el.scrollHeight)
    }
    render() {
        let recipientName = this.props.currentChatRecipientName ?? ' - '

        return (
            <div>
                <div className='chat'>
                    {this.state.isOpen && (
                        <div>
                            <div
                                className='cardDetail--chat'
                                style={
                                    this.props.relative && {
                                        position: 'relative',
                                        width: '100%',
                                        right: 'unset',
                                        bottom: 'unset',
                                        top: 'unset',
                                        left: 'unset'
                                    }
                                }>
                                <div className='chat__head'>
                                    <h2 className='chat__title'>
                                        <span>
                                            {recipientName}
                                        </span>
                                        {!this.props.relative && (
                                            <div className='chat__close'
                                                 onClick={() => this.setState({ isOpen: false })} />
                                        )}
                                    </h2>
                                </div>
                                <div className='chat__conversation'>
                                    <div className='conversation'>
                                        <div className='conversation__container' ref={this.conversationArea}>
                                            {this.props.chat.chat.map(
                                                (message, index) => (<ChatMessage
                                                    key={index + '-message'}
                                                    isOwnMessage={message.user_sender_id === this.props.userId}
                                                    messageData={message}
                                                    userToken={this.props.userToken}
                                                />)
                                            )}

                                            <div
                                                className='messages-end'
                                                style={{ float: 'left', clear: 'both' }}
                                                ref={el => {
                                                    this.messagesEnd = el
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div className='conversation__action'>
                                                <textarea
                                                    className='action__message'
                                                    name='message'
                                                    placeholder={this.props.intl.formatMessage({ id: 'message' })}
                                                    ref={this.messageBox}
                                                    value={this.state.value}
                                                    onChange={this.handleChange}
                                                    onKeyPress={e => {
                                                        if (e.key === 'Enter') {
                                                            this.handleSubmit(e)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <span style={{ display: 'none' }}>
                                                <input ref={this.attachmentMessageRef}
                                                       type='file'
                                                       style={{ display: 'none' }}
                                                       accept='.png,.jpg,.jpeg,.mp4'
                                                       onChange={(e) => this.uploadAttachment(e)}
                                                />
                                            </span>
                                            <div className='action__send me-2'
                                                 onClick={e => this.openAttachmentDialog(e)}>
                                                <i className='fa fa-paperclip'></i>
                                            </div>
                                            <div className='action__attachment' onClick={e => this.handleSubmit(e)}>
                                                <i className='fa fa-paper-plane' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='backdrop' />
                        </div>
                    )}
                    {!this.props.relative && (
                        <button className='chat__button' title='Chat' onClick={() => this.openSupport()}>
                            {/* {this.props.messages > 0 &&  */}
                            {this.props.unreadMessages ? (
                                <span className='message__badge'>{this.props.unreadMessages}</span>
                            ) : null}
                            {/* } */}
                            <i className='fa fa-comments' />
                        </button>
                    )}
                </div>
            </div>
        )
    }
}

export default injectIntl(withRouter((Chat = connect(mapStateToProps, mapDispatchToProps)(Chat))))
