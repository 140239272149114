import Echo from "laravel-echo";
import Pusher from 'pusher-js';

export const generateNotificationChannelNameByUserId = (userId) => {
    return `Notifications.User.${userId}`;
}

export const generatePendingTaskChannelNameByUserId = (userId) => {
    return `PendingTask.User.${userId}`;
}

class WebSocketDisconnected extends Error {
    constructor(message = 'WebSocketDisconnected') {
        super(message);
        this.name = message;
        this.fecha = new Date();
    }
}

export function createSocketConnection(userId, bearerToken) {
    if (window.Echo) {
        return;
    }
    
    const key = process.env.REACT_APP_WS_KEY;
    let wsHost = process.env.REACT_APP_WS_HOST;
    let wsPath = null;
    const authEndpoint = process.env.REACT_APP_API_HOST + '/api/broadcasting/auth';
    const wsPort = process.env.REACT_APP_WS_HTTP_PORT;
    const wssPort = process.env.REACT_APP_WS_HTTPS_PORT;
    const forceTLS = process.env.NODE_ENV === 'production';

    if (process.env.NODE_ENV === 'production') {
        wsHost = `${window.location.hostname}`;
        wsPath = '/ws';
    }

    window.Echo = new Echo({
        broadcaster: 'pusher',
        cluster: 'candc1',
        key,
        wsHost,
        wsPath,
        wsPort,
        wssPort,
        forceTLS,
        disableStats: true,
        authEndpoint,
        auth:        {
            headers: {
                Authorization: 'Bearer ' + bearerToken,
            },
        },
    });
}


export function deleteSocketConnection(userId) {
    if (window.Echo) {
        window.Echo.leaveChannel(generateNotificationChannelNameByUserId(userId))
        window.Echo.leaveChannel(generatePendingTaskChannelNameByUserId(userId))
        delete window.Echo
        window.location.reload()
    }
}