import React, { useState } from 'react'
import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import { getSaleItems, getSaleItemsExpansionsAvailableList, getSaleItemsTableInfo, productsWithoutPrice } from '../store/sale/reducers'
import { deleteItemFromSale, fetchSaleItems, fetchSaleItemsFromUser, updatePriceFromPriceProvider, updateQtyItemFromSale } from '../store/sale/actions'
import Button from '../components/global/Button'
import confirm from '../components/global/confirm'
import LocalizedLink from '../components/global/LocalizedLink'
import Select from 'react-select'
import {
    getBooleanOptions,
    getRarityOptions,
    getAvailableLangsIncludingAny,
    getAvailableStatusIncludingAny,
    getAvailableProductsCategories,
    getCurrentCollectableContext,
} from '../store/products/reducers'
import { getBankData, getIsBusiness, getIsLogged, getUserId, getUserName, getVerified } from '../store/login/reducers'
import { addToCart } from '../store/cart/actions'
import { getUserPublicInfo } from '../store/user/reducers'
import { fetchUserPublicInfoById } from '../store/user/actions'
import { getIsAdding } from '../store/cart/reducers'
import Alerts from '../components/alerts/Alerts'
import { RenderSaleItemMobile } from '../components/sale/list/RenderSaleItemMobile'
import { showLoginModal } from '../store/ui/actions'
import Modal from '../components/global/Modal'
import InfiniteScroll from 'react-infinite-scroll-component'
import debounce from 'lodash/debounce'
import { getDefaultProductCategoryByCurrentContext } from '../store/products/CollectableContext'

defineMessages({
    SEARCH_BY_NAME_OR_WHATEVER: {
        id: 'SEARCH_BY_NAME_OR_WHATEVER',
        description: 'SEARCH_BY_NAME_OR_WHATEVER',
        defaultMessage: 'Search by name or whatever',
    },
})

const mapStateToProps = (state) => {
    return {
        userIdLogged: getUserId(state),
        userName: getUserName(state),
        isLogged: getIsLogged(state),
        verified: getVerified(state),
        saleItems: getSaleItems(state),
        saleTableInfo: getSaleItemsTableInfo(state),
        productsCategoriesOptions: getAvailableProductsCategories(state),
        availableLangs: getAvailableLangsIncludingAny(state),
        availableStatus: getAvailableStatusIncludingAny(state),
        booleanOptions: getBooleanOptions(state),
        expansionOptions: getSaleItemsExpansionsAvailableList(state),
        rarityOptions: getRarityOptions(state),
        userListInfoDistinctCurrentUser: getUserPublicInfo(state),
        addingToCart: getIsAdding(state),
        bankData: getBankData(state),
        isBusiness: getIsBusiness(state),
        collectableContext: getCurrentCollectableContext(state),
        productsWithoutPrice: productsWithoutPrice(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const userToFilter = ownProps.match.params.id
    return {
        fetchDistinctUserPublicInfo: () => {
            return dispatch(fetchUserPublicInfoById(userToFilter))
        },
        fetchSaleItems: (newPage, pageSize, filters) => {
            if (userToFilter) {
                dispatch(fetchSaleItemsFromUser(userToFilter, newPage, pageSize, filters))
            } else {
                dispatch(fetchSaleItems(newPage, pageSize, filters))
            }
        },
        deleteItemFromSale: (itemId) => {
            return dispatch(deleteItemFromSale(itemId))
        },
        updateQtyItemFromSale: (itemId, qty) => {
            return dispatch(updateQtyItemFromSale(itemId, qty))
        },
        addToCart: (productId, qty, locale) => {
            return dispatch(addToCart(productId, qty, locale))
        },
        onShowUpLogin: () => {
            dispatch(showLoginModal())
        },
        updatePriceFromPriceProvider: (provider, operation, value) => {
            return dispatch(updatePriceFromPriceProvider(provider, operation, value))
        },
    }
}
const confirmDeleteItem = (itemId, item, onDeleteItem, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_DELETE_ITEM_FROM_SALE' })).then(
        (confirm) => {
            return onDeleteItem(itemId)
        },
        (cancel) => {
            return Promise.reject()
        }
    )
}
const getDefaultFilters = () => {
    return {
        search: '',
        productCategory: getDefaultProductCategoryByCurrentContext(),
        selectedLang: {
            value: null,
            label: 'Any',
        },
        selectedExpansion: {
            value: null,
            label: 'Any',
        },
        selectedCondition: null,
        priceMin: 0.0,
        priceMax: 0.0,
        isFoil: {
            value: null,
            label: 'Any',
        },
        isSigned: {
            value: null,
            label: 'Any',
        },
        isPlayset: {
            value: null,
            label: 'Any',
        },
        isAltered: {
            value: null,
            label: 'Any',
        },
    }
}

const ProviderPriceModal = ({ productsWithoutPrice, onClose, onClick }) => {
    const [values, setValues] = useState({
        provider: 'cardkingdom',
        operation: 'percentage',
        value: 0.0,
    })

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }

    const SendButton = ({ onClick, values }) => {
        return (
            <Button type="submit" secondary value="Update price" onClick={() => onClick(values)}>
                <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER_CARDKINGDOM" defaultMessage="Update price from cardkingdom" />
            </Button>
        )
    }

    return (
        <Modal title="Update price from price provider" onClose={onClose} footer={<SendButton onClick={onClick} values={values} />}>
            <div className="row">
                <div className="col-12">
                    <ul>
                        <li>
                            <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER_P1" defaultMessage="Not all products are available in the price provider" />
                        </li>
                        <li>
                            <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER_P2" defaultMessage="It will update the price of all products that are available" />
                        </li>
                        <li>
                            <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER_P3" defaultMessage="It will not update the price of products that are not available" />
                        </li>
                        <li>
                            <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER_P4" defaultMessage="Please, review the price of the products that are not updated" />
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <form id="updatePriceFromProviderForm">
                    <div className="mb-3">
                        <label className="form-label" htmlFor="price">
                            <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER_SET_PROVIDER" defaultMessage="Set price provider" />
                        </label>
                        <select className="form-select" aria-label="provider" name="provider" disabled defaultValue={'cardkingdom'}>
                            <option value="cardkingdom">Cardkingdom</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="operation">
                            <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER_SET_OPERATION" defaultMessage="Set operation" />
                        </label>
                        <select className="form-select" aria-label="operation" name="operation" disabled defaultValue={'percentage'}>
                            <option value="percentage">+ %</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="value">
                            <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER_SET_VALUE" defaultMessage="Set value" />
                        </label>
                        <input className="form-control" type="number" id="value" name="value" value={values.value} onChange={handleChange} min="-100" max="100" step="0.1" />
                    </div>
                </form>
                {productsWithoutPrice.length > 0 &&
                    productsWithoutPrice.map((product) => {
                        return (
                            <div key={product.product_id}>
                                <div className="alert alert-warning" role="alert">
                                    <div className="row">
                                        <div className="col-10">
                                            <Translation
                                                id="UPDATE_PRICE_FROM_PRICE_PROVIDER_PRODUCT_NOT_FOUND"
                                                defaultMessage="Product {name} not found in the price provider"
                                                values={{ name: product.name }}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} target="_blank" params={{ view: 'edit-item-sale', id: product.id }}>
                                                <div className="border-left-line">
                                                    <span className="material-icons-outlined" style={{ padding: '16px' }}>
                                                        call_made
                                                    </span>
                                                </div>
                                            </LocalizedLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </Modal>
    )
}

class SaleItemsScreen extends React.Component {
    booleanOptions = [
        {
            label: 'Any',
            value: null,
        },
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ]
    state = {
        filters: {
            ...getDefaultFilters(),
        },
        showProviderPriceModal: false,
        shouldDisplayProductCategoryFilter: false,
    }

    constructor() {
        super()
        this.searchSaleItems = debounce(this.searchSaleItems.bind(this), 400)
    }

    componentDidMount() {
        if(this.props.isBusiness) this.setState({ shouldDisplayProductCategoryFilter: true })
        if (!this.isUserListEqualToCurrentLogged()) {
            this.props.fetchDistinctUserPublicInfo()
        }
        this.setState({
            shouldDisplayProductCategoryFilter: this.shouldDisplayProductCategoryFilter(),
        })
        this.searchSaleItems()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.collectableContext !== this.props.collectableContext) {
            this.resetFiltersValue(() => this.searchSaleItems())
        }
        if (prevProps.userListInfoDistinctCurrentUser?.isBusiness !== this.props.userListInfoDistinctCurrentUser?.isBusiness || this.props.isBusiness !== prevProps.isBusiness) {
            this.setState(
                {
                    shouldDisplayProductCategoryFilter: this.shouldDisplayProductCategoryFilter(),
                },
                () => this.renderFilters()
            )
        }
    }

    shouldDisplayProductCategoryFilter = () => {
        const shouldDisplayProductCategoryFilter = (this.isUserListEqualToCurrentLogged() && this.props.isBusiness) || this.props.userListInfoDistinctCurrentUser?.isBusiness
        return Boolean(shouldDisplayProductCategoryFilter)
    }

    resetFiltersValue(callback = null) {
        this.setState(
            {
                ...this.state,
                filters: {
                    ...getDefaultFilters(),
                },
            },
            () => {
                if (callback) {
                    callback()
                }
            }
        )
    }

    isUserListEqualToCurrentLogged() {
        //Dont put triple equal on this line
        return this.props.match.params.id ? this.props.match.params.id == this.props.userIdLogged : true
    }

    isUserItemEqualToCurrentLogged(item) {
        return this.props.userIdLogged == item.user_id
    }

    canAddItem() {
        return this.props.isLogged && this.isUserListEqualToCurrentLogged()
    }

    searchSaleItems(page = 1, pageSize = 10) {
        let filters = {}
        Object.entries(this.state.filters).forEach(([key, value]) => {
            if (value) {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    value = value.value
                }
                filters[key] = value
            }
        })
        this.props.fetchSaleItems(page, pageSize, filters)
    }

    renderFilters = () => {
        return (
            <form className="card-body" onSubmit={(event) => this.sendFilters(event)}>
                <div className="row">
                    <div className="col-12 col-lg-3 col-xl-2">
                        <label htmlFor="name" className="form-label">
                            <Translation id="name" defaultMessage="Name" />
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder={this.props.intl.formatMessage({ id: 'SEARCH_BY_NAME_OR_WHATEVER' })}
                            value={this.state.filters.search}
                            autoComplete="off"
                            onChange={(e) =>
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            search: e.target.value,
                                        },
                                    },
                                    () => this.searchSaleItems()
                                )
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-2">
                        <label htmlFor="expansion" className="form-label">
                            <Translation id="expansion" defaultMessage="Expansion" />
                        </label>
                        <Select
                            options={this.props.expansionOptions}
                            value={this.state.filters.selectedExpansion}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            selectedExpansion: selectedOption,
                                        },
                                    },
                                    () => this.searchSaleItems()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    {this.state.shouldDisplayProductCategoryFilter && (
                        <div className="col-12 col-lg-4 col-xl-2">
                            <label htmlFor="qty" className="form-label">
                                <Translation id="PRODUCT_CATEGORY" defaultMessage="Product category" />
                            </label>
                            <Select
                                className=""
                                options={this.props.productsCategoriesOptions}
                                value={this.state.filters.productCategory}
                                onChange={(selectedOption) => {
                                    this.setState(
                                        {
                                            filters: {
                                                ...this.state.filters,
                                                productCategory: selectedOption,
                                            },
                                        },
                                        () => this.searchSaleItems()
                                    )
                                }}
                                placeholder={
                                    this.props.intl.formatMessage({
                                        id: 'select',
                                    }) + '...'
                                }
                            />
                        </div>
                    )}
                    <div className="col-12 col-lg-3 col-xl-1">
                        <label htmlFor="qty" className="form-label">
                            <Translation id="language" defaultMessage="Language" />
                        </label>
                        <Select
                            className=""
                            options={this.props.availableLangs}
                            value={this.state.filters.selectedLang}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            selectedLang: selectedOption,
                                        },
                                    },
                                    () => this.searchSaleItems()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-2">
                        <label htmlFor="qty" className="form-label">
                            <Translation id="condition" defaultMessage="Condition" />
                        </label>
                        <Select
                            className=""
                            options={this.props.availableStatus}
                            value={this.state.filters.selectedCondition}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            selectedCondition: selectedOption,
                                        },
                                    },
                                    () => this.searchSaleItems()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-2">
                        <div className="d-flex flex-row">
                            <div>
                                <label className="form-label">Price Min</label>
                                <input
                                    type="number"
                                    value={this.state.filters.priceMin}
                                    min={0.0}
                                    step={0.01}
                                    className="form-control"
                                    id="minPrice"
                                    onChange={(event) => {
                                        this.setState(
                                            {
                                                filters: {
                                                    ...this.state.filters,
                                                    priceMin: event.target.value,
                                                },
                                            },
                                            () => this.searchSaleItems()
                                        )
                                    }}
                                />
                            </div>
                            <div>
                                <label className="form-label">Price Max</label>
                                <input
                                    type="number"
                                    value={this.state.filters.priceMax}
                                    min={0.0}
                                    step={0.01}
                                    className="form-control"
                                    id="maxPrice"
                                    onChange={(event) => {
                                        this.setState(
                                            {
                                                filters: {
                                                    ...this.state.filters,
                                                    priceMax: event.target.value,
                                                },
                                            },
                                            () => this.searchSaleItems()
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 col-xl-1">
                        <label htmlFor="foil" className="form-label">
                            <Translation id="foil" defaultMessage="Foil" />
                        </label>
                        <Select
                            className=""
                            options={this.booleanOptions}
                            value={this.state.filters.isFoil}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            isFoil: selectedOption,
                                        },
                                    },
                                    () => this.searchSaleItems()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-2 col-xl-1">
                        <label htmlFor="signed" className="form-label">
                            <Translation id="signed" defaultMessage="Signed" />
                        </label>
                        <Select
                            className=""
                            options={this.booleanOptions}
                            value={this.state.filters.isSigned}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            isSigned: selectedOption,
                                        },
                                    },
                                    () => this.searchSaleItems()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-2 col-xl-1">
                        <label htmlFor="playset" className="form-label">
                            <Translation id="playset" defaultMessage="Playset" />
                        </label>
                        <Select
                            className=""
                            options={this.booleanOptions}
                            value={this.state.filters.isPlayset}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            isPlayset: selectedOption,
                                        },
                                    },
                                    () => this.searchSaleItems()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-2 col-xl-1">
                        <label htmlFor="isAltered" className="form-label">
                            <Translation id="altered" defaultMessage="Altered" />
                        </label>
                        <Select
                            className=""
                            options={this.booleanOptions}
                            value={this.state.filters.isAltered}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            isAltered: selectedOption,
                                        },
                                    },
                                    () => this.searchSaleItems()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-1">
                        <label htmlFor="clear" className="form-label">
                            {/* <Translation id="clear_the_filters" defaultMessage="Empty" /> */}
                            &nbsp;
                        </label>
                        <Button
                            icon="trash"
                            secondary
                            onClick={() => {
                                this.resetFiltersValue(() => this.searchSaleItems(1, 10))
                            }}
                        >
                            <Translation id="clear" defaultMessage="Clear" />
                        </Button>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        if (this.props.isLogged && this.isUserListEqualToCurrentLogged() && !this.props.bankData?.validated) {
            return (
                <div className="col-12">
                    <Alerts alert={'FILL_FINANCIAL_INFO_TO_TRADE'} status={'warning'} />
                </div>
            )
        }
        const saleItems = this.props.saleItems ?? []
        //Dont put triple equal on this line
        const userListIsSameWhichMe = this.props.match.params.id ? this.props.match.params.id == this.props.userIdLogged : true

        const title = this.isUserListEqualToCurrentLogged() ? 'My sale list' : `${this.props.userListInfoDistinctCurrentUser?.name || ''} sale list`
        return (
            <div>
                <div className="container-fluid">
                    {this.props.isBusiness && this.state.showProviderPriceModal && (
                        <ProviderPriceModal
                            productsWithoutPrice={this.props.productsWithoutPrice}
                            onClose={() => this.setState({ showProviderPriceModal: false })}
                            onClick={({ provider, operation, value }) => {
                                this.props
                                    .updatePriceFromPriceProvider(provider, operation, value)
                                    .then(() => this.searchSaleItems(this.props.saleTableInfo.page, this.props.saleTableInfo.pageSize))
                                    .finally(() => this.props.productsWithoutPrice.length === 0 && this.setState({ showProviderPriceModal: false }))
                            }}
                        />
                    )}
                    <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="col-12 col-md-4">
                            <h2>{title}</h2>
                        </div>
                        {this.canAddItem() && (
                            <div className="col-12 col-md-8 ">
                                <div className="row" style={{ justifyContent: 'end', alignItems: 'center' }}>
                                    {this.props.isBusiness && (
                                        <div className="col-12 col-md-3">
                                            <Button
                                                secondary
                                                className="h-100"
                                                onClick={() => {
                                                    this.setState({ showProviderPriceModal: true })
                                                }}
                                            >
                                                <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER" defaultMessage="Update price" />
                                            </Button>
                                        </div>
                                    )}
                                    <div className="col-12 col-md-3 col-lg-2">
                                        <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} params={{ view: 'add-to-sale', id: '' }}>
                                            <Button icon="plus-circle">
                                                <Translation id="NEW_ITEM" defaultMessage="New item" />
                                            </Button>
                                        </LocalizedLink>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="container-fluid">
                    <h4 className="card-title">
                        <Translation id="filters" defaultMessage="Filters" />
                    </h4>
                    <div className="mt-3 mb-3">
                        <div className="d-none d-lg-block">{this.renderFilters()}</div>
                        <div className="d-lg-none row">
                            <div className="col-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder={this.props.intl.formatMessage({ id: 'SEARCH_BY_NAME_OR_WHATEVER' })}
                                    value={this.state.filters.search}
                                    autoComplete="off"
                                    onChange={(e) =>
                                        this.setState(
                                            {
                                                filters: {
                                                    ...this.state.filters,
                                                    search: e.target.value,
                                                },
                                            },
                                            () => this.searchSaleItems()
                                        )
                                    }
                                />
                            </div>

                            <div className="col-4">
                                <Button onClick={() => this.setState({ showFilters: true })}>
                                    <span className="material-icons-outlined" style={{ fontSize: '32px' }}>
                                        filter_alt
                                    </span>
                                </Button>
                            </div>
                            {this.state.showFilters && (
                                <Modal title={<Translation id="filters" defaultMessage="Filters" />} onClose={() => this.setState({ showFilters: false })}>
                                    {this.renderFilters()}
                                </Modal>
                            )}
                        </div>
                    </div>
                    <div className="card-list">
                        <InfiniteScroll
                            dataLength={saleItems.length}
                            next={() => this.props.saleTableInfo.page < this.props.saleTableInfo.pagesCount && this.searchSaleItems(this.props.saleTableInfo.page + 1, 10)}
                            hasMore={this.props.saleTableInfo.page < this.props.saleTableInfo.pagesCount}
                            loader={<h4>Loading...</h4>}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>You have seen it all</b>
                                </p>
                            }
                        >
                            {saleItems.map((item) => {
                                return (
                                    <RenderSaleItemMobile
                                        key={item.id}
                                        item={item}
                                        intl={this.props.intl}
                                        onShowUpLogin={this.props.onShowUpLogin}
                                        isLogged={this.props.isLogged}
                                        userName={this.props.userName}
                                        bankData={this.props.bankData}
                                        isVerified={this.props.verified}
                                        isUserItemEqualToCurrentLogged={this.isUserListEqualToCurrentLogged(item)}
                                        updateQty={(id, qty) => {
                                            this.props
                                                .updateQtyItemFromSale(id, qty)
                                                .then(() => this.searchSaleItems(this.props.saleTableInfo.page, this.props.saleTableInfo.pageSize))
                                        }}
                                        addToCart={(id, qty) =>
                                            this.props
                                                .addToCart(id, qty, this.props.intl)
                                                .then(() => this.searchSaleItems(this.props.saleTableInfo.page, this.props.saleTableInfo.pageSize))
                                        }
                                        deleteSaleItem={(id, original) =>
                                            confirmDeleteItem(id, original, this.props.deleteItemFromSale, this.props.intl).then(() =>
                                                this.searchSaleItems(this.props.saleTableInfo.page, this.props.saleTableInfo.pageSize)
                                            )
                                        }
                                    />
                                )
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SaleItemsScreen)))
