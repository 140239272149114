import { fetchCredit } from './../store/login/actions';
import { getCredit } from './../store/login/reducers'
import React,{useEffect} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import Button from '../components/global/Button'
import LocalizedLink from '../components/global/LocalizedLink'

export const getAddCreditUrl = () => {
    return `${window.location.origin}/dashboard/add-credit`
}

const AddCreditScreen = (props) => {
    const dispatch = useDispatch()
    const credit = useSelector((state) => getCredit(state))
    useEffect(() => { dispatch(fetchCredit())}, [])

    // if(!bankData || !bankData.validated) {
    //    return  <Alerts alert={"FILL_FINANCIAL_INFO_TO_TRADE"} status={"warning"} />
    // }

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 my-2'>
                        <h2>
                            <Translation id='current_credit'
                                         defaultMessage='Current credit' /> S${parseFloat(credit).toFixed(2)}
                        </h2>
                    </div>
                </div>

                <div className='row my-2'>
                    <h4>
                        <Translation
                            id='select_one_method'
                            defaultMessage='Please select one of the following options to credit your Cards And Collections account:'
                        />
                    </h4>
                </div>

                <div className='row my-4 d-flex flex-column gap-4 col-12'>
                    <div>
                        <h4>
                            <Translation
                                id='add_instant_immediately'
                                defaultMessage='Add credit immediately (With fee)'
                            />
                        </h4>

                        <div className='card'>
                            <div className='card-header'>Credit immediately by payment gateway (Stripe)</div>
                            <div className='card-body'>
                                <h5 className='card-title'>Receive credit immediately on your Cards and Collections
                                    Account</h5>
                                <p className='card-text'>Immediately with fee</p>
                                <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                               params={{ view: 'add-credit-by-payment-gateway', id: '' }}
                                               className=''>
                                    <div className='col-4 col-sm-3 col-lg-2'>
                                        <Button>
                                            Stripe
                                        </Button>
                                    </div>
                                </LocalizedLink>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h4>
                            <Translation
                                id='add_credit_without_fee'
                                defaultMessage='Add credit Up to 3 working days (Without fee)'
                            />
                        </h4>
                        <div className='d-flex flex-column flex-lg-row gap-2 gap-lg-4'>

                            <div className='card  w-100'>
                                <div className='card-header'>Credit by Paynow</div>
                                <div className='card-body'>
                                    <h5 className='card-title'>Receive Credit on Your Cards and Collections
                                        Account</h5>
                                    <p className='card-text'>Up to 3 working days, no fees.</p>
                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                   params={{ view: 'add-credit-by-mobile', id: '' }} className=''>
                                        <div className='col-4 col-sm-3 col-lg-5 col-xl-4'>
                                            <Button>
                                                Paynow
                                            </Button>
                                        </div>
                                    </LocalizedLink>
                                </div>
                            </div>

                            <div className='card  w-100'>
                                <div className='card-header'>Credit by wire transfer</div>
                                <div className='card-body'>
                                    <h5 className='card-title'>Receive Credit on Your Cards and Collections
                                        Account</h5>
                                    <p className='card-text'>Up to 3 working days, no fees.</p>
                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                   params={{ view: 'add-credit-by-bank', id: '' }} className=''>
                                        <div className='col-4 col-sm-3 col-lg-5 col-xl-4'>
                                            <Button>
                                                Wire transfer
                                            </Button>
                                        </div>
                                    </LocalizedLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(AddCreditScreen)))
