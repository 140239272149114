import React from 'react'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import logo from '../../logo-horizontal.svg'
import { getIsLogged } from '../../store/login/reducers'
import { connect } from 'react-redux'
import Alerts from '../alerts/Alerts'

const mapStateToProps = (state) => {
    return {
        isLogging: getIsLogged(state),
    }
}

class Footer extends React.Component {
    render() {
        return (
            <div>
                <footer className="footer" id="footer">
                    <div className="container-fluid">
                        <div className="row pt-3 pb-3 px-4" id="footer-up">
                            <div className="col-12 col-md-6 col-lg-3">
                                <img alt="cards and collections logo" className="pb-3 filter-white img-fluid" src={logo} style={{ color: 'white', width: '260px' }} />
                                <p>Cards and Collections is a collectible marketplace, where you can manage your collection, sell and buy items. </p>
                                <p>Explore C&amp;C database and expand your collection</p>
                                <Alerts status="primary" alert={`Trading only available in Singapure area`}></Alerts>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                                <div>
                                    <Translation id="ADD_BUY_AND_SELL" defaultMessage="Add, buy and sell" />
                                </div>
                                <div className="text-white pb-3">
                                    <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'add-collection' }}>
                                        <Translation id="ADD_TO_COLLECTION" defaultMessage="Add to collection" />
                                    </LocalizedLink>
                                </div>
                                <div className="text-white pb-3">
                                    <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'want-to-sell' }}>
                                        <Translation id="WANT_TO_SELL" defaultMessage="Want to sell" />
                                    </LocalizedLink>
                                </div>
                                <div className="text-white pb-3">
                                    <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'want-to-buy' }}>
                                        <Translation id="WANT_TO_BUY" defaultMessage="Want to buy" />
                                    </LocalizedLink>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                                <div>
                                    <Translation id="INFORMATION" defaultMessage="Information" />
                                </div>
                                <div className="text-white pb-3">
                                    <LocalizedLink routeId={'ROUTE_STATIC_PAGE'} params={{ view: 'faq' }}>
                                        <Translation id="FAQS" defaultMessage="FAQs" />
                                    </LocalizedLink>
                                </div>
                                <div className="text-white pb-3">
                                    <LocalizedLink routeId={'ROUTE_STATIC_PAGE'} params={{ view: 'how-to-use' }}>
                                        <Translation id="HOW_TO_USE_CAC" defaultMessage="How to use C&C" />
                                    </LocalizedLink>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                                <div>
                                    <Translation id="GET_IN_TOUCH" defaultMessage="Get in touch" />
                                </div>
                                {this.props.isLogging && (
                                    <div className="text-white pb-3">
                                        <LocalizedLink routeId="ROUTE_SUGGESTIONS">
                                            <Translation id="SUGGESTION" defaultMessage="Suggestion" />
                                        </LocalizedLink>
                                    </div>
                                )}

                                <div className="text-white pb-3">
                                    <a href="mailto:support@cardsandcollections.com" target="_blank" rel="noreferrer">
                                        <Translation id="Contact" defaultMessage="Contact" />
                                    </a>
                                </div>
                            </div>
                            {/* No hay enlaces a redes sociales, no hay iconos de redes sociales en material-icons 
                            <div className="col-12 col-md-6 col-lg-3 text-right" >
                                <p><i className="fa-brands fa-facebook"></i></p>
                                
                            </div> */}
                        </div>
                        <div className="row pb-2 px-4">
                            <div className="col-12 pb-md-3 col-lg-4">
                                <span className="col text-white">
                                    © Copyright {new Date().getFullYear()} C&C. <Translation id="ALL_RIGHTS_RESERVED" defaultMessage="All rights reserved" />{' '}
                                </span>
                            </div>
                            <div className="col-12 col-lg-8 text-sm-start text-lg-end text-white">
                                <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'terms-of-service' }}>
                                    <u>
                                        <Translation id="TERMS_OF_SERVICE" />
                                    </u>
                                </LocalizedLink>
                                ,{' '}
                                <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'private-policy' }}>
                                    <u>
                                        <Translation id="PRIVATE_POLICY" definedMessage="Private Policy" />
                                    </u>
                                </LocalizedLink>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default connect(mapStateToProps)(Footer)
