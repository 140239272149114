import React, { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../../../global/Translation'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '../../../global/Button'
import {
    getEmail,
    getIsBusiness,
    getSendNotifications,
    getUserName,
    getVatNumber,
    getVatPrefix
} from '../../../../store/login/reducers'
import { updatePersonalInformation } from '../../../../store/login/actions'

const PersonalInformationForm = (props) => {

    const dispatch = useDispatch()
    const email = useSelector(state => getEmail(state))
    const name = useSelector(state => getUserName(state))
    const isBusiness = useSelector(state => getIsBusiness(state))
    const vatPrefix = useSelector(state => getVatPrefix(state))
    const vatNumber = useSelector(state => getVatNumber(state))
    const sendNotifications = useSelector(state => getSendNotifications(state))

    const initialValues = {
        name: name,
        email: email,
        vatPrefix: vatPrefix,
        vatNumber: vatNumber,
        isBusiness: isBusiness,
        sendNotifications: sendNotifications
    }

    const validationSchema = Yup.object({
        email: Yup.string()
            .email(props.intl.formatMessage({ id: 'INVALID_EMAIL_ADDRESS' }))
            .required(props.intl.formatMessage({ id: 'REQUIRED' })),
        vatPrefix: Yup.string().nullable().when('isBusiness', {
            is: true,
            then: Yup.string().required(props.intl.formatMessage({ id: 'REQUIRED' }))
        }),
        vatNumber: Yup.string().nullable().when('isBusiness', {
            is: true,
            then: Yup.string().required(props.intl.formatMessage({ id: 'REQUIRED' }))
        })
    })

    const onSubmit = (values, action) => {
        dispatch(updatePersonalInformation(values))
        action.resetForm({
            values: {
                name: name,
                email: values.email,
                vatPrefix: values.vatPrefix,
                vatNumber: values.vatNumber,
                isBusiness: values.isBusiness,
                sendNotifications: values.sendNotifications
            }
        })
    }

    const handleClickSubmit = event => {
        formik.submitForm()
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    //  console.log(formik.values)

    return (
        <React.Fragment>
            <form className='form'>
                <div className='mb-3'>
                    <label htmlFor='name' className='form-label'>
                        <Translation id='USER_NAME' defaultMessage='User name' />
                    </label>
                    <input
                        className='form-control'
                        type='text'
                        id='name'
                        name='name'
                        required='required'
                        maxLength='10'
                        value={formik.values.name}
                        disabled
                    />
                </div>

                <div className='mb-3'>
                    <label htmlFor='registration_email' className='form-label'>
                        <Translation id='EMAIL' defaultMessage='Email' /> *
                    </label>
                    <input
                        type='email'
                        id='registration_email'
                        name='email'
                        required='required'
                        className='form-control'
                        placeholder={props.intl.formatMessage({ id: 'A_VALID_AND_EXISTING_EMAIL' })}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div style={{ color: 'red' }}>{formik.errors.email}</div>
                    ) : null
                    }
                </div>

                {isBusiness && (
                    <React.Fragment>
                        <div className='mb-3'>

                            <label htmlFor='registration_vatNumber'
                                   className='form-label'>
                                <Translation id='VAT_NUMBER' defaultMessage='Vat number' /> *
                            </label>
                            <div className='row mb-3'>

                                <div className='col-3'>
                                    <select className='form-select' id='registration_vatPrefix'
                                            name='vatPrefix'
                                            value={formik.values.vatPrefix}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                    >
                                        <option value=''>*</option>
                                        <option value='AT'>AT</option>
                                        <option value='BE'>BE</option>
                                        <option value='BG'>BG</option>
                                        <option value='HR'>HR</option>
                                        <option value='CZ'>CZ</option>
                                        <option value='EE'>EE</option>
                                        <option value='DE'>DE</option>
                                        <option value='EL'>EL</option>
                                        <option value='IN'>IN</option>
                                        <option value='LT'>LT</option>
                                        <option value='ES'>ES</option>
                                        <option value='SE'>SE</option>
                                        <option value='GB'>GB</option>
                                        <option value='CY'>CY</option>
                                        <option value='HU'>HU</option>
                                        <option value='LU'>LU</option>
                                        <option value='RO'>RO</option>
                                        <option value='SK'>SK</option>
                                        <option value='DK'>DK</option>
                                        <option value='FI'>FI</option>
                                        <option value='LV'>LV</option>
                                        <option value='FR'>FR</option>
                                        <option value='SI'>SI</option>
                                        <option value='IT'>IT</option>
                                        <option value='NL'>NL</option>
                                        <option value='IE'>IE</option>
                                        <option value='PL'>PL</option>
                                        <option value='PT'>PT</option>
                                        <option value='MT'>MT</option>
                                    </select>
                                    {formik.touched.vatPrefix && formik.errors.vatPrefix ? (
                                        <div
                                            style={{ color: 'red' }}>{formik.errors.vatPrefix}</div>
                                    ) : null
                                    }
                                </div>
                                <div className='col-9'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        id='registration_vatNumber'
                                        name='vatNumber'
                                        placeholder={props.intl.formatMessage({ id: 'NUMBERS_AND_LETTERS' })}
                                        pattern='[A-Za-z0-9]*'
                                        value={formik.values.vatNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.vatNumber && formik.errors.vatNumber ? (
                                        <div
                                            style={{ color: 'red' }}>{formik.errors.vatNumber}</div>
                                    ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <div className='mb-3'>
                    <div className='form-check'>
                        <label htmlFor='send_notifications' className='form-check-label'>
                            <Translation id='send_notifications' defaultMessage='Send notifications' />
                        </label>
                        <input
                            type="checkbox"
                            id='send_notifications'
                            name='sendNotifications'
                            className="form-check-input"
                            value={formik.values.sendNotifications}
                            checked={formik.values.sendNotifications}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                </div>
                <div className='mb-3'>
                    <Button type='submit' disabled={!formik.dirty || !formik.isValid}
                            onClick={handleClickSubmit}>
                        <Translation id='update' defaultMessage='Update' />
                    </Button>
                </div>
            </form>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(PersonalInformationForm)))
