import { fetchCredit } from './../store/login/actions';
import { getUserName, getUserId, getBankData } from '../store/login/reducers'
import React, {useEffect} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import { setBuyCredit } from '../store/login/actions'
import { getBuyCredit } from '../store/login/reducers'
import Alerts from '../components/alerts/Alerts'
import { DownloadPaynowImage } from '../components/global/DownloadPaynowImage'


const AddCreditByMobileScreen = (props) => {
    const dispatch = useDispatch()
    const buyCredit = useSelector((state) => getBuyCredit(state))
    const userName = useSelector((state) => getUserName(state))
    const userId = useSelector((state) => getUserId(state))
    const bankData = useSelector((state) => getBankData(state))
    const onSubmit = (values, action) => {
        dispatch(setBuyCredit(values))
    }
    useEffect(() => { dispatch(fetchCredit())}, [])

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 bg-light'>
                        <h2>
                            <Translation id='add_credit_by_mobile' defaultMessage='Add credit by Paynow' />
                        </h2>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <Translation
                            id='add-credit-paynow'
                            defaultMessage='Add credit to your Cards and Collections account by paynow to this number'
                        />
                        &nbsp;<strong>96 21 34 39</strong>
                    </div>
                </div>
                <div className='row'>
                    <Alerts
                        status='warning'
                        alert={`Attention: Always enter ${userName}-${userId} as transfer details! Please, do not enter any other information in this field. `}
                    ></Alerts>
                </div>
                <div className='row'>
                    <div style={{width: '350px'}}>
                        <DownloadPaynowImage  />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(AddCreditByMobileScreen)))
