import { connect, useSelector } from 'react-redux'
import SubHeader from './SubHeader'
import { getBankData, getHasFilledShippingAddress, getIsLogged, getVerified } from '../../store/login/reducers'
import { injectIntl } from 'react-intl'

const mapStateToProps = (state) => {
    return {
        isLogged: getIsLogged(state),
        hasFilledShippingAddress: getHasFilledShippingAddress(state),
        verified: getVerified(state),
        bankData: getBankData(state)
    }
}

const SubHeaderContainer = connect(
    mapStateToProps
)(SubHeader)

export default injectIntl(SubHeaderContainer)
