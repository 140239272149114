import { RenderSaleItemMobile } from './../sale/list/RenderSaleItemMobile'
import { defineMessages, injectIntl } from 'react-intl'
import React from 'react'
import Spinner from '../global/Spinner'
import Translation from '../global/Translation'
import Modal from '../global/Modal'
import Button from '../global/Button'
import DisplayPrice from '../product/DisplayPrice'
import Alerts from '../alerts/Alerts'
import { PublicProfileLink } from '../global/PublicProfileLink'
import { Votes } from '../../screens/PublicProfileScreen'
import paynow from '../../assets/img/paynow.jpg'
import { DownloadPaynowImage } from '../global/DownloadPaynowImage'

defineMessages({
    'buyer.lost': {
        id: 'buyer.lost',
        description: 'buyer.lost',
        defaultMessage: 'Order is lost, we are sorry, contact with the seller',
    },
    'seller.lost': {
        id: 'seller.lost',
        description: 'seller.lost',
        defaultMessage: 'Order is lost, we are sorry.',
    },
    'buyer.confirm': {
        id: 'buyer.confirm',
        description: 'buyer.confirm',
        defaultMessage: 'Pending buyer confirmation',
    },
    'buyer.recibir': {
        id: 'buyer.recibir',
        description: 'buyer.recibir',
        defaultMessage: 'Pending until buyer confirm arrival',
    },
    'buyer.pay': {
        id: 'buyer.pay',
        description: 'buyer.pay',
        defaultMessage: 'Pending until buyer completes payment',
    },
    'buyer.vote': {
        id: 'buyer.vote',
        description: 'buyer.vote',
        defaultMessage: 'Wait buyer vote',
    },
    'seller.confirm': {
        id: 'seller.confirm',
        description: 'seller.confirm',
        defaultMessage: 'Waiting for the seller to confirm the shipment'
    },
    'seller.send': {
        id: 'seller.send',
        description: 'seller.send',
        defaultMessage: 'Seller did not send the order',
    },
    'seller.no_confirmada': {
        id: 'seller.no_confirmada',
        description: 'seller.no_confirmada',
        defaultMessage: 'Seller wants to cancel the order',
    },
    'buyer.cancel': {
        id: 'buyer.cancel',
        description: 'buyer.cancel',
        defaultMessage: 'Wait until buyer confirm order cancellation',
    },
    'transaction.unpaid': {
        id: 'transaction.unpaid',
        description: 'transaction.unpaid',
        defaultMessage: 'Unpaid',
    },
    'transaction.pay': {
        id: 'transaction.pay',
        description: 'transaction.pay',
        defaultMessage: 'Pay',
    },
    'transaction.paid': {
        id: 'transaction.paid',
        description: 'transaction.paid',
        defaultMessage: 'Paid',
    },
    'transaction.pending_confirm': {
        id: 'transaction.pending_confirm',
        description: 'transaction.pending_confirm',
        defaultMessage: 'Pending Confirm',
    },
    'transaction.not_relist': {
        id: 'transaction.not_relist',
        description: 'transaction.not_relist',
        defaultMessage: 'No relist',
    },
    'transaction.cancel': {
        id: 'transaction.cancel',
        description: 'transaction.cancel',
        defaultMessage: 'Accept',
    },

    'transaction.not_cancel': {
        id: 'transaction.not_cancel',
        description: 'transaction.not_cancel',
        defaultMessage: 'Reject',
    },
    'transaction.lost': {
        id: 'transaction.lost',
        description: 'transaction.lost',
        defaultMessage: 'Lost',
    },
    'transaction.cancel_finished': {
        id: 'transaction.cancel_finished',
        description: 'transaction.cancel_finished',
        defaultMessage: 'Cancelled',
    },
    'transaction.finished': {
        id: 'transaction.finished',
        description: 'transaction.finished',
        defaultMessage: 'The order has been completed',
    },
    'transaction.cancelled': {
        id: 'transaction.cancelled',
        description: 'transaction.cancelled',
        defaultMessage: 'Cancellation requested',
    },
    'transaction.expired': {
        id: 'transaction.expired',
        description: 'transaction.expired',
        defaultMessage: 'Expired',
    },
    'transaction.vote': {
        id: 'transaction.vote',
        description: 'transaction.vote',
        defaultMessage: 'Vote',
    },
    'transaction.received': {
        id: 'transaction.received',
        description: 'transaction.received',
        defaultMessage: 'Received',
    },
    'transaction.confirm': {
        id: 'transaction.confirm',
        description: 'transaction.confirm',
        defaultMessage: 'Confirm',
    },
    'transaction.not_confirm': {
        id: 'transaction.not_confirm',
        description: 'transaction.not_confirm',
        defaultMessage: 'Reject',
    },
    'transaction.send': {
        id: 'transaction.send',
        description: 'transaction.send',
        defaultMessage: 'Send',
    },
    'transaction.relist': {
        id: 'transaction.relist',
        description: 'transaction.relist',
        defaultMessage: 'List again',
    },
    'transaction.no_relist': {
        id: 'transaction.no_relist',
        description: 'transaction.no_relist',
        defaultMessage: 'No list',
    },
    'order.buying_info_message': {
        id: 'order.buying_info_message',
        description: 'order.buying_info_message',
        defaultMessage: 'You are buying those items from ',
    },
    'order.selling_info_message': {
        id: 'order.selling_info_message',
        description: 'order.selling_info_message',
        defaultMessage: 'You are selling those items to ',
    },
})

export const  getCheckoutUrlByCheckoutId = (checkoutId) => {
    return `${window.location.origin}/checkout-summary/${checkoutId}`
}

const OrderTitle = ({
                        currentUserId,
                        buyerId,
                        buyerName,
                        buyerNameSlug,
                        sellerId,
                        sellerName,
                        sellerNameSlug,
                        orderId
                    }) => {
    const buyerTitle = (
        <h5>
            <Translation id='order.buying_info_message' /> <PublicProfileLink userNameSlug={sellerNameSlug}
                                                                              label={sellerName} /> {` (#${orderId})`}
        </h5>
    )
    const sellerTitle = (
        <h5>
            <Translation id='order.selling_info_message' /> <PublicProfileLink userNameSlug={buyerNameSlug}
                                                                               label={buyerName} /> {` (#${orderId})`}
        </h5>
    )
    if (currentUserId === sellerId) {
        return sellerTitle
    }
    return buyerTitle
}

export const BuyerPayModal = ({order, credit, onClose,payMyPendingCredit}) => {
    const downloadQRCode = (e) => {
        e.preventDefault()

        let anchor = document.createElement("a");
        anchor.href = paynow;
        anchor.download = `Paynow Cards And Collections.jpg`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    return (
        <Modal title={<Translation id="add_credit_by_paynow" defaultMessage="Add Credit by Paynow"></Translation>} onClose={onClose}>
            <div className="container">
                <p>Please make the payment and remember to put those details:</p>
                <ul>
                    <li>
                        Phone number: <strong>96 21 34 39</strong>
                    </li>
                    <li>
                        Concept: <strong>{order.buyer_name}</strong>
                    </li>
                    <li>
                        Quantity:{' '}
                        <strong>
                            <DisplayPrice price={Math.abs(credit)}></DisplayPrice>
                        </strong>
                    </li>
                </ul>
                <Button onClick={() => payMyPendingCredit(getCheckoutUrlByCheckoutId(order.id))}>Pay</Button>
                {/* <DownloadPaynowImage /> */}
            </div>
        </Modal>
    )
}

class Order extends React.Component {
    componentDidMount = () => {
        this.props.fetchOrderById(this.props.id)
    }

    constructor(props) {
        super(props)
        this.state = {
            needMessage: false,
            needPayment: false,
            messageEntered: null,
            needVotes: false,
            votesEntered: null,
            needTrackingNumber: false,
            trackingNumberEntered: null
        }
    }

    changeOrderStatus = (id, action) => {
        //REVIEW: aqui hay un bug si abres el modal de votos y cierras y vuelves a abrir se envia la valoracion automaticamente.
        if (action === 'no_confirm' && !this.state.messageEntered) {
            this.setState({ needMessage: true })
        } else if (action === 'pay') {
            this.setState({ needPayment: true })

        } else if (action === 'vote' && !this.state.votesEntered) {
            this.setState({
                needVotes: true,
                votesEntered: {
                    vote_seller_general: 5,
                    vote_seller_description: 5,
                    vote_seller_packaging: 5,
                    vote_seller_comments: ''
                }
            })
        } else if (action === 'send' && this.isOrderCertified() && !this.state.trackingNumberEntered) {
            this.setState({ needTrackingNumber: true, trackingNumberEntered: null })

        } else {
            let payload = null
            if (action === 'vote') {
                payload = this.state.votesEntered
            } else if (action === 'no_confirm') {
                payload = this.state.messageEntered
            } else if (action === 'send' && this.isOrderCertified()) {
                payload = { tracking_number: this.state.trackingNumberEntered }
            }
            this.props.changeOrderStatus(id, action, payload)
        }
    }

    isOrderSale = () => {
        return this.props.order.type === 'SALE'
    }

    isOrderBuy = () => {
        return this.props.order.type === 'BUY'
    }

    isOrderCertified = () => {
        return this.props.order?.shipping_method?.type?.toLowerCase() === 'certified'
    }

    render() {
        if (!this.props.order) return <Spinner isLoading={true}></Spinner>

        const showOrderPaymentConfirmationMessageToStore = ((Object.keys(this.props.orderActions?.actions ?? {})[0] ?? null) === 'paid') && this.props.isBusiness && this.props.order?.shipping_method?.type?.toLowerCase() === 'pickup'

        return (
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="col-12 col-md-6 card">
                        <OrderTitle
                            currentUserId={this.props.userId}
                            buyerId={this.props.order.buyer_id}
                            buyerName={this.props.order.buyer_name}
                            buyerNameSlug={this.props.order.buyer_name_slug}
                            sellerId={this.props.order.seller_id}
                            sellerName={this.props.order.seller_name}
                            sellerNameSlug={this.props.order.seller_name_slug}
                            orderId={this.props.order.id}
                        />
                        <div className="card-body">
                            <h5 className="card-title">
                                <Translation id="shipping_address" defaultMessage="Shipping address" />
                            </h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <Translation id="receptor" defaultMessage="Receptor" />: {this.props.order.shipping_address_snapshot.full_name}{' '}
                                    {this.props.order.shipping_address_snapshot.first_surname} {this.props.order.shipping_address_snapshot.second_surname}
                                </li>
                                <li className="list-group-item">
                                    <Translation id="address" defaultMessage="Address" />: {this.props.order.shipping_address_snapshot.address}
                                </li>
                                <li className="list-group-item">
                                    <Translation id="city" defaultMessage="City" />: {this.props.order.shipping_address_snapshot.location}{' '}
                                    {this.props.order.shipping_address_snapshot.zip_code}
                                </li>
                                <li className="list-group-item">
                                    <Translation id="country" defaultMessage="Country" />: {this.props.order.shipping_address_snapshot.country?.name}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 card">
                        <div className="card-body">
                            <h5 className="card-title">
                                <Translation id="SUMMARY" defaultMessage="Summary" />
                            </h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <Translation id="ITEMS" defaultMessage="Items" />: {this.props.order.total_articles}
                                </li>
                                {this.isOrderSale() && (
                                    <React.Fragment>
                                        <li className="list-group-item">
                                            <Translation id="ITEMS_COST" defaultMessage="Items cost" />: <DisplayPrice price={this.props.order.total_withouth_shipping_taxes} />
                                        </li>
                                        <li className="list-group-item">
                                            <Translation id="SHIPPING_TAXES_COST" defaultMessage="Shipping taxes cost" />:{' '}
                                            <DisplayPrice price={this.props.order.total_with_shipping_taxes - this.props.order.total_withouth_shipping_taxes} />
                                        </li>

                                        <div
                                            className={
                                                this.props.order.total_with_shipping_taxes_with_discount && this.props.userId == this.props.order.buyer_id
                                                    ? 'list-group-item'
                                                    : 'card-footer'
                                            }
                                            style={{
                                                textDecoration:
                                                    this.props.order.total_with_shipping_taxes_with_discount && this.props.userId == this.props.order.buyer_id
                                                        ? 'line-through'
                                                        : null,
                                            }}
                                        >
                                            <Translation id="TOTAL_WITH_SHIPPING_TAXES_COST" defaultMessage="Total (with shipping taxes)" />:{' '}
                                            <DisplayPrice price={this.props.order.total_with_shipping_taxes} />
                                        </div>
                                        {this.props.order.total_with_shipping_taxes_with_discount && this.props.userId == this.props.order.buyer_id && (
                                            <React.Fragment>
                                                <li className="list-group-item">
                                                    <Translation id="discount_applied" defaultMessage="Discount applied" />:{' '}
                                                    <DisplayPrice price={this.props.order.total_with_shipping_taxes - this.props.order.total_with_shipping_taxes_with_discount} />
                                                </li>
                                                <div className="card-footer">
                                                    <Translation id="TOTAL_WITH_DISCOUNT" defaultMessage="Total (with discount)" />:{' '}
                                                    <DisplayPrice price={this.props.order.total_with_shipping_taxes_with_discount} />
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                                {this.isOrderBuy() && (
                                    <React.Fragment>
                                        <div className="card-footer">
                                            <Translation id="TOTAL_COST" defaultMessage="Total cost" />: <DisplayPrice price={this.props.order.total_with_shipping_taxes} />
                                        </div>
                                    </React.Fragment>
                                )}

                                {this.isOrderCertified() && (
                                    <li className="list-group-item mt-2">
                                        <Translation id="tracking_number" />:{' '}
                                        <a target="_blank" href={`https://www.singpost.com/track-items?trackingid=${this.props.order.tracking_number}`}>
                                            <span className="tracking_number">{this.props.order.tracking_number}</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                            <div className="row mt-2">
                                {this.props.orderActions.actions &&
                                    Object.entries(this.props.orderActions.actions).map((action, index) => {
                                        return (
                                            <div className="col" key={index}>
                                                {this.state.needMessage && (
                                                    <Modal
                                                        title={<Translation id="write_your_review" defaultMessage="Make your review"></Translation>}
                                                        onClose={() => this.setState({ needMessage: false })}
                                                    >
                                                        <form
                                                            onSubmit={(event) => {
                                                                event.preventDefault()
                                                                this.changeOrderStatus(this.props.id, action[0])
                                                            }}
                                                            className="form-inline"
                                                        >
                                                            <div className="row form-inline">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="reason" style={{ width: '240px' }}>
                                                                        <Translation id="reason_label" defaultMessage="Enter the reason"></Translation>
                                                                    </label>
                                                                    <input
                                                                        id="reason"
                                                                        name="reason"
                                                                        autoComplete="off"
                                                                        type="text"
                                                                        onChange={(e) => this.setState({ messageEntered: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col">
                                                                    <input
                                                                        className="bt mt-2 mr-2 ml-2"
                                                                        disabled={this.props.isSending}
                                                                        type="submit"
                                                                        value={this.props.intl.formatMessage({ id: 'SUBMIT' })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </Modal>
                                                )}
                                                {this.state.needTrackingNumber && (
                                                    <Modal
                                                        title={<Translation id="write_tracking_number" defaultMessage="Write tracking number"></Translation>}
                                                        onClose={() => this.setState({ needTrackingNumber: false })}
                                                    >
                                                        <form
                                                            onSubmit={(event) => {
                                                                event.preventDefault()
                                                                this.changeOrderStatus(this.props.id, action[0])
                                                            }}
                                                            className="form-inline"
                                                        >
                                                            <div className="row form-inline">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="tracking_number" style={{ width: '240px' }}>
                                                                        <Translation id="tracking_number" defaultMessage="Tracking number"></Translation>
                                                                    </label>

                                                                    <input
                                                                        type="text"
                                                                        required
                                                                        id="tracking_number"
                                                                        name="tracking_number"
                                                                        autoComplete="off"
                                                                        onChange={(e) => this.setState({ trackingNumberEntered: e.target.value })}
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <input
                                                                            className="bt mt-2 mr-2 ml-2"
                                                                            disabled={this.props.isSending}
                                                                            type="submit"
                                                                            value={this.props.intl.formatMessage({ id: 'SUBMIT' })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </Modal>
                                                )}

                                                {this.state.needPayment && (
                                                    <BuyerPayModal
                                                        order={this.props.order}
                                                        credit={this.props.credit}
                                                        onClose={() => this.setState({ needPayment: false })}
                                                        payMyPendingCredit={(url) => this.props.payMyPendingCredit(url)}
                                                    />
                                                )}

                                                {this.state.needVotes && (
                                                    <Modal
                                                        title={<Translation id="write_your_review" defaultMessage="Make your review"></Translation>}
                                                        onClose={() => this.setState({ needVotes: false })}
                                                    >
                                                        <form
                                                            onSubmit={(event) => {
                                                                event.preventDefault()
                                                                this.changeOrderStatus(this.props.id, action[0])
                                                            }}
                                                            className="form-inline"
                                                        >
                                                            <div className="row form-inline">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="vote_seller_general" style={{ width: '240px' }}>
                                                                        <Translation id="vote_seller_general_label" defaultMessage="General rating" />
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        min="1"
                                                                        max="5"
                                                                        name="vote_seller_general"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={this.state.votesEntered.vote_seller_general}
                                                                        style={{ width: 'auto' }}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                votesEntered: {
                                                                                    ...this.state.votesEntered,
                                                                                    vote_seller_general: e.target.value,
                                                                                },
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="vote_seller_description" style={{ width: '240px' }}>
                                                                        <Translation id="vote_seller_description_label" defaultMessage="Description rating" />
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        min="1"
                                                                        max="5"
                                                                        name="vote_seller_description"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={this.state.votesEntered.vote_seller_description}
                                                                        style={{ width: 'auto' }}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                votesEntered: {
                                                                                    ...this.state.votesEntered,
                                                                                    vote_seller_description: e.target.value,
                                                                                },
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="vote_seller_packaging" style={{ width: '240px' }}>
                                                                        <Translation id="vote_seller_packaging_label" defaultMessage="Packing rating" />
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        min="1"
                                                                        max="5"
                                                                        name="vote_seller_packaging"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={this.state.votesEntered.vote_seller_packaging}
                                                                        style={{ width: 'auto' }}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                votesEntered: {
                                                                                    ...this.state.votesEntered,
                                                                                    vote_seller_packaging: e.target.value,
                                                                                },
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="vote_seller_comments">
                                                                        <Translation id="vote_seller_comments_label" defaultMessage="Comments" />
                                                                    </label>
                                                                    <input
                                                                        maxLength="150"
                                                                        name="vote_seller_comments"
                                                                        value={this.state.votesEntered.vote_seller_comments}
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                votesEntered: {
                                                                                    ...this.state.votesEntered,
                                                                                    vote_seller_comments: e.target.value,
                                                                                },
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <input
                                                                            className="bt mt-2 mr-2 ml-2"
                                                                            disabled={this.props.isSending}
                                                                            type="submit"
                                                                            value={this.props.intl.formatMessage({ id: 'SUBMIT' })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </Modal>
                                                )}

                                                <Button disabled={this.props.isSending} onClick={() => this.changeOrderStatus(this.props.id, action[0])}>
                                                    {this.props.intl.formatMessage({
                                                        id: action[1].label,
                                                    })}
                                                </Button>
                                            </div>
                                        )
                                    })}
                            </div>
                            {showOrderPaymentConfirmationMessageToStore && (
                                <Alerts
                                    alert={
                                        'If you are a store user and the order is pickup, you can validate the payment of the customer, but remember to check the operation with a screenshot or bank movement'
                                    }
                                    status="warning"
                                ></Alerts>
                            )}
                            {this.props.orderActions.no_action && <Alerts alert={this.props.orderActions.no_action.message}></Alerts>}
                            {this.props.order.payload && <Alerts alert={this.props.order.payload} status="warning"></Alerts>}
                        </div>
                    </div>
                </div>
                {this.props.orderActions.no_action && (
                    <React.Fragment>
                        <div className="row mb-3">
                            <div className="col-12 card">
                                <div className="card-body">
                                    <div className="d-md-flex justify-content-between align-items-center">
                                        <div className="votes-info">
                                            <div>
                                                <Translation id="vote_seller_general" defaultMessage="General" />
                                            </div>
                                            {this.props.order && this.props.order.vote_seller_general && <Votes votes={this.props.order.vote_seller_general} />}
                                        </div>
                                        <div className="votes-info">
                                            <div>
                                                <Translation id="vote_seller_descriptio" defaultMessage="Description" />
                                            </div>
                                            {this.props.order && this.props.order.vote_seller_description && <Votes votes={this.props.order.vote_seller_description} />}
                                        </div>
                                        <div className="votes-info">
                                            <div>
                                                <Translation id="vote_seller_packaging" defaultMessage="Packaging" />
                                            </div>
                                            {this.props.order && this.props.order.vote_seller_packaging && <Votes votes={this.props.order.vote_seller_packaging} />}
                                        </div>
                                        <div className="votes-info">
                                            <div>
                                                <Translation id="vote_seller_comments" defaultMessage="Comments" />
                                            </div>
                                            {this.props.order && this.props.order.vote_seller_comments && (
                                                <span className="d-lg-block"> {this.props.order.vote_seller_comments} </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <div className="card-list">
                    {this.props.orderLines &&
                        this.props.orderLines.map((original) => <RenderSaleItemMobile key={original.id} item={original} intl={this.props.intl} showActions={false} />)}
                </div>
            </div>
        )
    }
}

export default injectIntl(Order)
