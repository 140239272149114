export const initState = {
    currentUser: null,
    tradingInfo: null,
    votes: null,
    saleItems: null,
    buyItems: null,
    comments: null,
    error: null,
    credit_retrieve_ingress: null,
}

const user = (state = initState, action) => {
    switch (action.type) {
         case 'FETCH_USER_PUBLIC_INFO_BY_ID_REQUEST':
             return { ...initState }
         case 'FETCH_USER_PUBLIC_INFO_BY_ID_SUCCESS':
             return { ...state, currentUser: action.data.currentUser, tradingInfo: action.data.tradingInfo, votes: action.data.votes, error: null}
         case 'FETCH_USER_PUBLIC_INFO_BY_ID_FAILURE':
             return { ...state, error: action.response }
        case 'FETCH_USER_PUBLIC_SALE_ITEMS_REQUEST':
            return { ...state, error: null }
        case 'FETCH__USER_PUBLIC_SALE_ITEMS_SUCCESS':
            return {...state, saleItems:action.data}
        case 'FETCH__USER_PUBLIC_SALE_ITEMS_FAILURE':
            return { ...state, error: action.response }
        case 'FETCH_USER_PUBLIC_COMMENTS_REQUEST':
            return { ...state, error: null }
        case 'FETCH_USER_PUBLIC_COMMENTS_SUCCESS':
            return { ...state, comments: action.data }
        case 'FETCH_USER_PUBLIC_COMMENTS_FAILURE':
            return { ...state, error: action.response }
        case 'FETCH_CREDIT_RETRIEVE_INGRESS_SUCCESS':
            return { ...state, credit_retrieve_ingress: action.data }
        case 'FETCH_CREDIT_RETRIEVE_INGRESS_FAILURE':
            return { ...state, error: action.response }
        default:
            return state
    }
}

export const getUserPublicInfo = (state) => {
   return state.user.currentUser
}

export const getTradingInfo = (state) => {
    return state.user.tradingInfo;
}

export const getUserPublicVotes = (state) => {
    return state.user.votes
}

export const getUserPublicSaleItems = (state) => {
    return state.user.saleItems
}

export const getUserPublicBuyItems = (state) => {
    return state.user.buyItems;
}

export const getUserPublicComments = (state) => {
    return state.user.comments
}

export const getCreditRetrieveIngress = (state) => {
    return state.user.credit_retrieve_ingress
}

export default user


