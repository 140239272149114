import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Route, withRouter } from 'react-router-dom'
import TOSScreen from './TOSScreen'
import PrivatePolicyScreen from './PrivatePolicyScreen'
import FAQScreen from './FAQScreen'
import WantToSellScreen from './WantToSellScreen'
import WantToBuyScreen from './WantToBuyScreen'
import AddCollectionScreen from './AddCollectionScreen'
import HowToUseScreen from './HowToUseScreen'

const StaticScreen = (props) => {
    return (
<div>
    <div>
        <Route path={props.intl.formatMessage({ id: 'ROUTE_STATIC_PAGE' }).replace(':view?', 'terms-of-service')} component={TOSScreen} exact />
        <Route path={props.intl.formatMessage({ id: 'ROUTE_STATIC_PAGE' }).replace(':view?', 'private-policy')} component={PrivatePolicyScreen} exact />
        <Route path={props.intl.formatMessage({ id: 'ROUTE_STATIC_PAGE' }).replace(':view?', 'faq')} component={FAQScreen} exact />
        <Route path={props.intl.formatMessage({ id: 'ROUTE_STATIC_PAGE' }).replace(':view?', 'add-collection')} component={AddCollectionScreen} exact />
        <Route path={props.intl.formatMessage({ id: 'ROUTE_STATIC_PAGE' }).replace(':view?', 'want-to-sell')} component={WantToSellScreen} exact />
        <Route path={props.intl.formatMessage({ id: 'ROUTE_STATIC_PAGE' }).replace(':view?', 'want-to-buy')} component={WantToBuyScreen} exact />
        <Route path={props.intl.formatMessage({ id: 'ROUTE_STATIC_PAGE' }).replace(':view?', 'how-to-use')} component={HowToUseScreen} exact />
        
    </div>
</div>
    )
}   

export default withRouter(injectIntl(connect()(StaticScreen)))