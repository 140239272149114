import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import Error404Screen from '../../screens/Error404Screen'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getIsLogged, getUserType, getVerified } from '../../store/login/reducers'
import { getMaintenanceMode } from '../../store/maintenance-mode/reducers'
import { fetchCart } from '../../store/cart/actions'
import { fetchMyNotifications } from '../../store/notifications/actions'
import { fetchPendingTasks } from '../../store/pending-tasks/actions'
import { fetchCredit, loginRequired } from '../../store/login/actions'

const mapStateToProps = (state) => {
    return {
        isLogged: getIsLogged(state),
        userType: getUserType(state),
        maintenanceMode: getMaintenanceMode(state),
        verified: getVerified(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCart: () => {
            dispatch(fetchCart())
        },
        fetchNotifications: () => {
            dispatch(fetchMyNotifications());
        },
        fetchPendingTasks : () => {
            dispatch(fetchPendingTasks())
        },
        loginRequired: () => {
            dispatch(loginRequired())
        },
        fetchCredit: () => {
            dispatch(fetchCredit())
        }
    }
}



class PrivateRoute extends React.Component {
    componentDidMount() {
        if (!this.props.isLogged) {
            this.props.loginRequired();
        }

        if(this.props.isLogged && !this.props.verified) {
            this.props.history.push(this.props.intl.formatMessage({ id: 'ROUTE_RESEND_VERIFICATION_MAIL_PAGE' }))
        }
    }
    render() {
        let { component: Component, ...rest } = this.props
        return <Route {...rest} render={(props) => (rest.isLogged ? <Component {...props} /> : <Error404Screen />)}></Route>
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)))
