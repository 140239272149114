import { qtySelector } from '../../store/cart/reducers'
import { getCountOfVotes, getUserName, getCredit, getUserProfileLogoUrl } from '../../store/login/reducers'
import { connect } from 'react-redux'
import IconButtons from './IconButtons'
import { getIsLogged } from '../../store/login/reducers'
import { logout } from '../../store/login/actions'
import * as ui from '../../store/ui/actions'
import { showingProfileModal, showMenuSelector } from '../../store/ui/reducers'
import { qtyNotificationsSelector } from '../../store/notifications/reducers'
import { qtyPendingTasksSelector } from '../../store/pending-tasks/reducers'

const mapStateToProps = state => {
    return {
        isLogged: getIsLogged(state),
        username: getUserName(state),
        userProfileLogoUrl: getUserProfileLogoUrl(state),
        countOfVotes: getCountOfVotes(state),
        totalQty: qtySelector(state),
        // isAdmin: getIsAdmin(state),
        showMenu: showMenuSelector(state),
        showingProfileModal: showingProfileModal(state),
        qtyNotifications: qtyNotificationsSelector(state),
        qtyPendingTasks: qtyPendingTasksSelector(state),
        credit: getCredit(state),
        // showCart: showModalSelector(state),
        // showProfile: showingProfileModal(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowUpLogin: () => {
            dispatch(ui.showLoginModal())
        },
        onShowUpCart: () => {
            dispatch(ui.showCartModal())
        },
        onShowUpProfile: () => {
            dispatch(ui.showProfileModal())
        },
        onToggleCart: active => {
            dispatch(ui.toggleCart(active))
        },
        onToggleMenu: active => {
            dispatch(ui.toggleMenu(active))
        },
        onToggleProfile: active => {
            dispatch(ui.toggleProfile(active))
        },
        onLogout: () => {
            dispatch(logout())
        },
        onToggleNotifications: active => {
            dispatch(ui.toggleNotifications(active))
        },
        onShowUpNotifications: () => {
            dispatch(ui.showNotificationsModal())
        }

    }
}
const IconButtonsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IconButtons)

export default IconButtonsContainer
