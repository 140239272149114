import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter,Route,Switch } from 'react-router-dom'
import TasksContainer from '../components/tasks/TasksContainer'
import NotificationsContainer from '../components/notifications/NotificationsContainer'

const DefaultDashboardScreen = (props,intl) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className='col-12 col-lg-6'>
                    <TasksContainer />
                </div>
                <div className='col-12 col-lg-6'>
                    <NotificationsContainer />
                </div>
            </div>
        </div>
    )
}

export default withRouter(injectIntl(connect()(DefaultDashboardScreen)))
