import React from 'react'

const WantToSellScreen = (props) => {
    return (
        <div className="mb-3 container-fluid">
            <div>
                <h1>Sell cards you don’t need</h1>                
                <p>
                    Having a collection of cards can be exciting, 
                    but it can be unfortunate when you realize the fortune you possess without knowing how to properly manage your assets. 
                    But worry not! CardsandCollections is here to help you maximize your sales and earn that extra money you need. 
                    Just imagine yourself enjoying the wonders of the Seychelles Islands while holding a refreshing Daikiry in your hand. Sounds good, right? 
                    Keep reading and find out how we can make it a reality!
                </p>
                <p>
                    Now that you are aware of the potential of our sales system, 
                    it's time to dive into the features that CardsandCollections has to offer. 
                    Our extensive database allows you to easily search for and find any card from your favourite game. 
                    Whether you are looking for specific cards to complete your collection or simply exploring new acquisitions, 
                    our intuitive and efficient system will provide you with precise results in a matter of seconds.
                </p>
                <p>
                    But that's not all. 
                    In addition to granting you access to our database; you will join a community of passionate users eager to acquire your valuable collections. 
                    Interact with fellow collectors, share your experiences, and discover opportunities for trading or selling. 
                    With CardsandCollections, you won't just be maximizing your sales; you'll also be expanding your network of contacts in the world of collectible cards.                
                </p>
                <h2>To list an item for sale, follow these simple steps:</h2>
                <p>
                    Step 1: Complete registration on the platform: 
                    <ul>
                        <li>Fill out the registration form on the platform.</li>
                        <li>Providing your personal details including your address.</li>  
                        <li>Verify your bank account as per the provided instructions.</li>
                    </ul>
                </p>
                <p>
                    Step 2: Access your dashboard: 
                    <ul>
                        <li>Log in to your account and access your dashboard.</li>
                        <li>Look for the "Want to Sell" option.</li>  
                        <li>Click on it to begin the selling process.</li>
                    </ul>
                </p>
                <p>
                    Step 3: Choose the item's details and price:
                    <ul>
                        <li>Select the specific item you want to sell.</li>
                        <li>Provide the relevant details such as its condition, edition, language, etc.</li>  
                        <li>Enter the desired price for the item.</li>
                    </ul>
                </p> 
                <p>
                    Additionally, you can also list an item for sale from the individual card page. 
                    When you find the item, you want to sell in the search results, select the "Add to Sell" option to input the item's details and price.
                </p>
                <p>
                    Remember to review the information you entered carefully before confirming the listing. 
                    Once you've completed these steps, your item will be available for interested buyers to purchase.
                </p>
                <p>
                    There you go! Do you not think that it's time to awaken that lazy soul and free yourself from everything you no longer use? 
                    So don't waste any more time. 
                    Join us today and discover how you can make the most of your cards and fuel your passion for collectible card games. 
                    We are excited to have the opportunity to help you turn your valuable collections into business opportunities and connections with other enthusiasts. 
                    Now users will have the necessary instructions to list an item for sale on the platform. 
                    If you have any more questions or need further assistance, feel free to ask.
                </p>
            </div>
        </div>
    )
}

export default WantToSellScreen