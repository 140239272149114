import { getCountOfVotes, getUserId, getBuyCredit, getCredit, getIsBusiness, getUserNameSlug } from './../../store/login/reducers'
import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { injectIntl } from 'react-intl'
import DisplayPrice from '../product/DisplayPrice'
import { connect } from 'react-redux'
import { getUserType, getUserAuthorization, getUserName, getIsLogged } from '../../store/login/reducers'
import { logout } from '../../store/login/actions'
const mapStateToProps = (state) => {
    return {
        userType: getUserType(state),
        userId: getUserId(state),
        username: getUserName(state),
        isLogged: getIsLogged(state),
        countOfVotes: getCountOfVotes(state),
        credit: getCredit(state),
        buyCredit: getBuyCredit(state),
        isBusiness: getIsBusiness(state),
        userNameSlug: getUserNameSlug(state)
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => {
            dispatch(logout())
        },
    }
}

const ProfileLinks = ({ intl, userType, responsive, onLogout, username, isLogged, countOfVotes, userId, credit, buyCredit, isBusiness, userNameSlug }) => (
    <React.Fragment>
        <ul className="nav navbar-nav navbar-right">
            <li className="nav-item d-block d-lg-none" style={{ color: credit >= 0 ? '#62BD5A':'#ff6669' }}>
                Credit: <DisplayPrice price={credit} showZero={true}></DisplayPrice> <span className="caret"></span>
            </li>

            <li className="nav-item">
                <span className="">
                    <LocalizedLink routeId="ROUTE_USER_DASHBOARD" params={{ view: 'add-buy-credit', id: '' }} className="dropdown-item">
                        <Translation id="buylist_budget" defaultMessage="Buylist budget" />: <DisplayPrice price={buyCredit}></DisplayPrice>
                    </LocalizedLink>
                </span>
            </li>
            <li className="nav-item">

                <span className="">
                    <LocalizedLink zedLink routeId="ROUTE_USER_DASHBOARD" params={{ view: 'add-credit', id: '' }} className="dropdown-item">
                        Add credit
                    </LocalizedLink>
                </span>
            </li>
            <li className="nav-item">
                <span className="">
                    <LocalizedLink routeId="ROUTE_USER_DASHBOARD" params={{ view: 'retrieve-credit', id: '' }} className="dropdown-item">
                        Retrieve credit
                    </LocalizedLink>
                </span>
            </li>
            <li className="nav-item">
                <span className="">
                    <LocalizedLink routeId="ROUTE_USER_DASHBOARD" params={{ view: 'credit-history', id: '' }} className="dropdown-item">
                        <Translation id="CREDIT_HISTORY" defaultMessage="Credit history" />
                    </LocalizedLink>
                </span>
            </li>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{ left: '-100px' }}></div>
            <li>
                <LocalizedLink routeId="ROUTE_PUBLIC_USER_PROFILE" params={{userNameSlug}}>
                    <span>
                        <Translation id="VOTES" defaultMessage="Votes:" /> {countOfVotes}
                    </span>
                </LocalizedLink>
            </li>
            {isLogged && (
                <li className="nav-item">
                    <span onClick={onLogout} className={responsive ? '' : null} style={{ color: '#ffc466', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span className="material-icons-outlined" style={{ fontSize: '32px' }}>
                            logout
                        </span>
                        <Translation id="LOGOUT" defaultMessage="Logout" />
                    </span>
                </li>
            )}
        </ul>
        {/* <li>
            <span>
                <Translation id="TYPE_USER" defaultMessage="Type:" />{' '}
                {isBusiness ? <Translation id="BUSINESS_USER" defaultMessage="Business_user" /> : <Translation id="NORMAL_USER" defaultMessage="Usuario normal" />}
            </span>
        </li> */}

        {/* <li className="nav-item">
            <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} className={responsive ? '' : null} params={{ view: '', id: '' }}>
                <Translation id="CONTROL_PANEL" defaultMessage="Control Panel" />
            </LocalizedLink>
        </li> */}
    </React.Fragment>
)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProfileLinks))
