import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import Button from '../global/Button'

import { injectIntl } from 'react-intl'

import { connect } from 'react-redux'

import { getUserType, getIsLogged } from '../../store/login/reducers'
import { showLoginModal } from '../../store/ui/actions'
import { logout } from '../../store/login/actions'


import { withRouter } from 'react-router-dom'

const mapStateToProps = (state) => {
    return {
        isLogged: getIsLogged(state),
        userType: getUserType(state),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showLoginModal: () => {
            dispatch(showLoginModal())
        },
        logout: () => {
            dispatch(logout())
        },
    }
}
const MenuLink = ({ children, responsive, customclass = null }) => <li className={responsive ? 'nav-item ' + customclass : customclass}>{children}</li>

// TODO borrar:Ejemplo de Joaquin
// const LinkToSlabs = ({ responsive }) => (
//     <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: '' }} className={responsive ? 'nav-link' : null}>
//         <Translation id="TABLES" defaultMessage="Slabs" />
//     </LocalizedLink>
// )

const LinkToLogin = ({ responsive,showLogin }) => (
    <Button className={responsive ? 'nav-link' : null} onClick={() => showLogin()}>
        <Translation id="LOGIN" defaultMessage="Login"></Translation>
    </Button>
)

const LinkToRegister = ({ responsive }) => (
    <LocalizedLink routeId="ROUTE_REGISTER"  className={responsive ? 'nav-link' : null}>
        <Translation id="REGISTER" defaultMessage="Register"></Translation>
    </LocalizedLink>
)

const LinkToNews = ({ responsive }) => (
    <LocalizedLink routeId="ROUTE_NEWS"  className={responsive ? 'nav-link' : null}>
        <Translation id="NEWS" defaultMessage="News"></Translation>
    </LocalizedLink>
)
const LinkToAdvancedSearch = ({ responsive }) => (
    <LocalizedLink routeId="ROUTE_ADVANCED_SEARCH" className={responsive ? 'nav-link' : null}>
        <Translation id="advanced_search" defaultMessage="Advanced search"></Translation>
    </LocalizedLink>
)
const LanguageSelector = ({ responsive }) => (
    <li>
        <a href="#">Language Selector</a>
    </li>
    
)

const MenuLinks = (props) => {
    const { responsive } = props

    return (
        <React.Fragment>
            {/* <MenuLink responsive={responsive}>
                <LinkToLogin responsive={responsive} showLogin={props.showLoginModal}/>
            </MenuLink>
            <Button onClick={() => props.logout()}>logout</Button> */}
            {!props.isLogged && (
                <MenuLink responsive={responsive}>
                    <LinkToRegister responsive={responsive} />
                </MenuLink>
            )}
            <MenuLink>
                <LinkToNews responsive={responsive} />
            </MenuLink>
            <MenuLink>
                <LinkToAdvancedSearch responsive={responsive} />
            </MenuLink>
            {/* <LanguageSelector></LanguageSelector> */}
        </React.Fragment>
    )
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MenuLinks)))
