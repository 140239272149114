import React, { useEffect } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import LocalizedLink from '../components/global/LocalizedLink'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Translation from '../components/global/Translation'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../components/global/Button'
import { resetPassword } from '../store/login/actions'
import { useParams } from "react-router-dom";
import { closeLoginModal } from '../store/ui/actions'

defineMessages({
    YOUR_REGISTERED_EMAIL: {
        id: 'YOUR_REGISTERED_EMAIL',
        description: 'YOUR_REGISTERED_EMAIL',
        defaultMessage: "Your registered email"
    },
    INVALID_EMAIL_OR_TOKEN: {
        id: 'INVALID_EMAIL_OR_TOKEN',
        description: 'INVALID_EMAIL_OR_TOKEN',
        defaultMessage: "Invalid email o token"
    }
})

const ResetPasswordScreen = props => {

    const error = useSelector(state => state.login.alert)
    const dispatch = useDispatch()
    let token  = useParams();

    const initialValues = {
        password: '',
        password_confirmation: '',
        email:'',
        token: token.token
    }

    const validationSchema = Yup.object({
        email: Yup.string()
            .email(props.intl.formatMessage({id:'INVALID_EMAIL_ADDRESS'}))
            .required(props.intl.formatMessage({id:'REQUIRED'})),
        password: Yup.string()
            .required(props.intl.formatMessage({ id: 'REQUIRED' }))
            .min(6, props.intl.formatMessage({ id: 'MUST_BE_6_CHARACTERS_OR_MORE' })),
        password_confirmation: Yup.string().oneOf([
            Yup.ref('password'), ''], props.intl.formatMessage({ id: 'PASSWORDS_MUST_MATCH' }))
            .required(props.intl.formatMessage({ id: 'REQUIRED' }))
    })

    const onSubmit = values => {
        dispatch(resetPassword(values,props.intl.formatMessage({id:'ROUTE_HOME'})))
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    const handleClickSubmit = () => {
        formik.submitForm()
    }

    useEffect(() => {
        dispatch(closeLoginModal())
    }, [])

    return (
        <div>
            <div className='container-fluid registerForm mb-5' id='registerForm'>
                <div className='row'>
                    <div className='col-12'>
                        <div className=''>
                            <div className='q-top d-none d-xl-block'>
                                <Translation id='RESET_PASSWORD' defaultMessage='Reset Password'/>
                            </div>
                            <div className='row my-3'>
                                <div className='col-12 lab'>
                                    <form className='form' onSubmit={formik.handleSubmit}>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-3 text-center">
                                                <label htmlFor="registration_email" className="form-label">
                                                    <Translation id="EMAIL" defaultMessage="Email" />
                                                </label>
                                                *
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <input
                                                    type="email"
                                                    id="registration_email"
                                                    name="email"
                                                    required="required"
                                                    placeholder={props.intl.formatMessage({id:'YOUR_REGISTERED_EMAIL'})}
                                                    className="form-control"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div style={{color:"red"}}>{formik.errors.email}</div>
                                                ) : null
                                                }
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-12 col-md-3 text-center'>
                                                <label htmlFor='new_password' className='form-label'>
                                                    <Translation id='NEW_PASSWORD' defaultMessage='New Password' />
                                                </label>
                                                *
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <input
                                                    className='form-control'
                                                    type='password'
                                                    id='new_password'
                                                    name='password'
                                                    required='required'
                                                    placeholder={props.intl.formatMessage({ id: 'AT_LEAST_6_CHARACTERS' })}
                                                    pattern='.{6,}'
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.password && formik.errors.password ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.password}</div>
                                                ) : null
                                                }
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-12 col-md-3 text-center'>
                                                <label htmlFor='registration_plainPassword' className='form-label'>
                                                    <Translation id='CONFIRM_PASSWORD' defaultMessage='Confirm password' />
                                                </label>
                                                *
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <input
                                                    type='password'
                                                    id='registration_plainPassword'
                                                    name='password_confirmation'
                                                    required='required'
                                                    placeholder={props.intl.formatMessage({ id: 'WRITE_THE_PASSWORD_AGAIN' })}
                                                    pattern='.{6,}'
                                                    className='form-control'
                                                    value={formik.values.password_confirmation}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.password_confirmation}</div>
                                                ) : null
                                                }
                                                {error && (
                                                    <div style={{ color: 'red' }}>{props.intl.formatMessage({ id: 'INVALID_EMAIL_OR_TOKEN' })}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row my-3 justify-content-md-center'>

                                            <div className='col-12 col-md-3'>
                                                <LocalizedLink routeId='ROUTE_HOME' className='col-10 offset-1 mt-3'>
                                                    <Translation id='BACK_TO_MAIN_PAGE' defaultMessage='Back to main page' />
                                                </LocalizedLink>
                                            </div>
                                            <div className='col-12 col-md-3'>
                                                <Button type='submit' disabled={!formik.dirty || !formik.isValid}
                                                        className='btn btn-primary register_button' value='Register'
                                                        onClick={handleClickSubmit}>
                                                    <Translation id='REGISTER' defaultMessage='Register' />
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default (injectIntl((ResetPasswordScreen)))