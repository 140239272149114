import { useDispatch, useSelector } from 'react-redux'
import React, { useState } from 'react'
import SignInForm from '../login-modal/SignInForm'
import { getIsLogging } from '../../store/login/reducers'
import { login, recoverPassword } from '../../store/login/actions'
import ForgotPasswordForm from '../login-modal/ForgotPasswordForm'

export const LoginInLineContainer = ({ intl }) => {
    const dispatch = useDispatch()

    const [showForgotPassword, setShowForgotPassword] = useState()
    const [showCreateAccount, setShowCreateAccount] = useState()

    const isLogging = useSelector((state) => getIsLogging(state))
    const onLogin = (username, password, callback) => {
        dispatch(login(username, password, callback, intl))
    }

    const onRecoverPassword = (email) => {
        dispatch(recoverPassword(email))
    }

    if (showForgotPassword) {
        return <ForgotPasswordForm
            showLogin={(e) => {
                e.preventDefault()
                setShowForgotPassword(false)
            }}
            onRecoverPassword={onRecoverPassword} />
    }


    return (
        <div>
            <SignInForm
                isLogging={isLogging}
                autofocusEnabled={false}
                onLogin={onLogin}
                showForgotPassword={(e) => {
                    e.preventDefault()
                    setShowForgotPassword(true)
                }
                }
                showCreateAccount={(e) => {
                    e.preventDefault()
                    setShowCreateAccount(true)
                }}
                callback={() => {
                }}
                callbackCenter={() => {
                }}
            />
        </div>
    )
}
