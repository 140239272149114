import { act } from 'react-dom/test-utils';
import { createSelector } from 'reselect'

export const initState = {
    pendingTasksList: {}
}

const pendingTasks = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_PENDING_TASKS_SUCCESS':
            return { ...state, pendingTasksList: {...action.pendingTasks }};
        case 'PENDING_TASK_CREATED_RECEIVED':            
            let pendingTaskListType = state.pendingTasksList[action.pendingTask.task_type]??[];
            pendingTaskListType.push(action.pendingTask);
            return {...state, pendingTasksList:{...state.pendingTasksList, [action.pendingTask.task_type]: pendingTaskListType}};
        case 'PENDING_TASK_DELETED':
            let newPendingTasksList = {...state.pendingTasksList}
            Object.entries(state.pendingTasksList).forEach(([pendingTaskType, tasks]) =>{
                newPendingTasksList[pendingTaskType] = tasks?.filter(task => task.id !== action.pendingTaskDeletedId) ?? []
            });
            return {...state, pendingTasksList:{...newPendingTasksList}};
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export const getPendingTasks = (state) => {
    return state.pendingTasks.pendingTasksList
}

export const qtyPendingTasksSelector = createSelector(getPendingTasks, pendingTasks => {
    let qty = 0;
    for (let key in pendingTasks) {
       qty += pendingTasks[key].length;
    }
    return qty;
})


export default pendingTasks
