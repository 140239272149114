const initState = {
    alert: null
}
const error = (state = initState, action) => {
    switch (action.type) {
        case 'SET_ERROR':
            return Object.assign({}, state, { alert: action.error })
        case 'ADD_CART_LINE_FAILURE':
            return Object.assign({}, state, { alert: `Error: ${action.payload.statusText}` })
        default:
            return state
    }
}
export const getAlert = state => {
    return state.error.alert
}
export default error
