import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { clearCurrentChat, fetchChat, fetchChats } from '../../store/chats/actions'
import { getChats, getIsLoading, getPages } from '../../store/chats/reducers'
import Chats from './Chats'
import { getBankData, getVerified } from '../../store/login/reducers'


const mapStateToProps = (state, { match }) => {
    return {
        activeChatId: match.params.activeChatId,
        chats: getChats(state),
        isLoading: getIsLoading(state),
        pages: getPages(state),
        verified: getVerified(state),
        bankData: getBankData(state),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchChats: () => dispatch(fetchChats()),
        fetchChat: (chatId) => dispatch(fetchChat(chatId)),
    }
}


const ChatsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Chats)

export default withRouter(ChatsContainer)
