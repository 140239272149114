import { connect } from 'react-redux'
import ProfileModal from './ProfileModal'
import { logout } from '../../store/login/actions'
import { closeProfileModal } from '../../store/ui/actions'
import { showingProfileModal } from '../../store/ui/reducers'
import { getUserName, getName, getUserImage, getUserType, getEmail, getIsLogged } from '../../store/login/reducers'
// import { fetchUnassignedReservations } from '../../store/api'
//import { countUnassignedReservations } from '../../store/reservations/reducers'

const mapStateToProps = state => {
    return {
        show: showingProfileModal(state),
        // username: getUserName(state),
        name: getUserName(state),
        // userimage: getUserImage(state),
        email: getEmail(state),
        userType: getUserType(state),
        isLogged: getIsLogged(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => {
            dispatch(logout())
        },
        onClose: () => {
            dispatch(closeProfileModal())
        }
    }
}

const ProfileModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileModal)

export default ProfileModalContainer
