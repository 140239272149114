export const FormInput = ({ errors, name, id, type, label, required, defaultValue, readOnly, showRequired = true, style, setRef, ...rest }) => (
    <div className="" style={style}>
        <label className="label__input">
            {label} {showRequired && required ? '(*)' : ''}
        </label>
        {id == 'price' && <span style={{ position: 'relative', left: '24px' }}>S$</span>}
        <input
            required={required}
            id={id}
            name={name}
            className={readOnly ? 'form__input--readonly' : 'form__input'}
            readOnly={readOnly}
            type={type}
            defaultValue={defaultValue}
            autoComplete="off"
            style={{ paddingLeft: id == 'price' ? '32px' : '16px' }}
            ref={setRef ? setRef : () => {}}
            {...rest}
        />
        {errors && errors[name] ? <span className="form__error"> {errors[name].join('. ')}</span> : null}
        <span className="focus-border" />
    </div>
)
