import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter,Route,Switch } from 'react-router-dom'
import AddToBuyForm from '../components/buy/AddToBuyForm'

const EditItemFromBuyScreen = (props, intl) => {
    return (
        <div className="container-fluid">
            {/* <h2>add to collection</h2> */}
            <AddToBuyForm mode={'edit'}></AddToBuyForm>
        </div>
    )
}

export default withRouter(injectIntl(connect()(EditItemFromBuyScreen)))
