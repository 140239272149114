import React from 'react'

const AddCollectionScreen = (props) => {
    return (
        <div className="mb-3 container-fluid">
            <div>
                <h2>Handle your collection, it is easy</h2>
                <p>
                    Do you have mountains of cards in your closet and don't know where to start? <br />
                    Are your card albums so full that you can never find the ones you're looking for? <br />
                    Don't worry anymore, my fella! <br />
                    Daddy has arrived, Cards and Collections is here.<br />    
                </p>
                <p>
                    At Cards and Collections, we understand the excitement and passion you feel for your collectible cards.<br />
                    We want you to enjoy to the fullest as you organize and manage your personal treasure.<br />
                    Our platform is your best ally in this thrilling adventure.<br />
                </p>
                <p>
                    But that's not all.<br /> 
                    In addition to granting you access to our database; you will join a community of passionate users eager to acquire your valuable collections. <br />
                    Interact with fellow collectors, share your experiences, and discover opportunities for trading or selling. <br />
                    With CardsandCollections, you won't just be maximizing your sales; you'll also be expanding your network of contacts in the world of collectible cards.<br />
                </p>
                <p>
                    Imagine having a super easy-to-use tool that helps you maintain a detailed record of each of your cards. <br />
                    With just a few clicks, you'll be able to add information about the condition, rarity, and value of your treasures. <br />
                    No more lost cards in the darkness of your closet! <br />
                </p>
                <p>
                    Moreover, our platform allows you to create virtual albums where you can organize your cards by categories, series, or any other way you desire. <br />
                    Imagine having all your favorite cards just a click away!<br />
                </p>
                <p>
                    But that's not all. <br />
                    One of the great advantages of our collection management service is that you can access your inventory from any device with an internet connection. <br />
                    Are you at home organizing your cards? Great, simply grab your phone or tablet and you can instantly check your collection. <br />
                    Are you on your way to a game store and want to verify if you already have a specific card? <br />
                    No problem, just open our app on your smartphone and you'll have all the information you need at your fingertips. <br />
                    Even at events and conventions, you can bring your virtual collection with you and share your passion with other collectors. <br />
                    With Cards and Collections, your collection is at your fingertips, no matter where you are.<br />
                </p>
                <p>
                    Now, let's talk about how to get started with managing your collection on our platform.<br />
                </p>
                <p>
                    Step 1: Registration on the platform
                    <ul>
                        <li>Go to our collection management platform.</li>
                        <li>Click on the "Sign up" or "Create an account" button on the homepage.</li>  
                        <li>Fill out the registration form with your nickname and email address.</li>
                        <li>Make sure to provide a valid email address, as you will receive a verification email.</li>
                    </ul>
                </p>
                <p>
                    Step 2: Verify your account
                    <ul>
                        <li>Check your inbox for the verification email from our platform.</li>
                        <li>Click on the verification link provided in the email.</li>                          
                        <li>You will be redirected back to the platform, and your account will be verified.</li>
                    </ul>
                </p>
                <p>
                    Step 3: Accessing the collection
                    <ul>
                        <li>In the bottom menu navigation, you will find the "Collection" option. Click on it.</li>
                        <li>You will be taken to the collection section, where you can manage all your cards.</li>                                                  
                    </ul>
                </p>
                <p>
                    Step 4: Adding cards to your collection <br />
                    <ul>
                        <li>To add cards to your collection, you have two options:</li>
                        <ol type="a">
                            <li>Using the search feature on the homepage:</li>
                            <ul>
                                <li>On the homepage, use the search feature to find the card you want to add.</li>
                                <li>Click on the card in the search results to go to the card's details page.</li>
                                <li>On the details page, select the "Add to collection" option to add the card to your personal collection.</li>
                            </ul>
                            <li>From the collection section:</li>                                                  
                            <lu>
                                <li>In the collection section, you will find a button or link to "Add new card."</li>
                                <li>Click on that button, and you will be directed to a form where you can enter the card details, such as name, rarity, and condition.</li>
                                <li>Fill out the form with the corresponding information and select "Save" to add the card to your collection.</li>
                            </lu>
                        </ol>
                    </ul>                                
                </p>
                <p>
                    And that's it! Now you are ready to manage your collection on our platform. <br />
                    You can repeat step 4 to add more cards as you acquire them. <br />
                    Enjoy exploring and organizing your valuable collectible cards!
                </p>
            </div>
        </div>
    )
}

export default AddCollectionScreen