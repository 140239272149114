import React from 'react'

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { getIsLogged } from '../../store/login/reducers'
import { getCurrentUrl } from '../../store/ui/reducers'
import LoginModalContainer from '../login-modal/LoginModalContainer'
import FloatingAlertContainer from '../alerts/FloatingAlertContainer'
const mapStateToProps = (state, { match }) => {
    return {
        isLogged: getIsLogged(state),
        currentUrl: getCurrentUrl(state).pathname,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}

class GlobalModals extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div>
                <FloatingAlertContainer />
                <LoginModalContainer />
            </div>
        )
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalModals)))
