import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import Button from '../components/global/Button'
import { setBuyCredit } from '../store/login/actions'
import { getBankData, getBuyCredit, getBuyCreditErrors } from '../store/login/reducers'
import { FormInput } from '../components/global/FormInput'
import DisplayPrice from '../components/product/DisplayPrice'
import Alerts from '../components/alerts/Alerts'

defineMessages({
    'PLACEHOLDER_ADD_BUY_CREDIT': {
        id: 'PLACEHOLDER_ADD_BUY_CREDIT',
        description: 'PLACEHOLDER_ADD_BUY_CREDIT',
        defaultMessage: 'Greater than 0'
    },
    'SET_BUY_CREDIT': {
        id: 'SET_BUY_CREDIT',
        description: 'SET_BUY_CREDIT',
        defaultMessage: 'Set your buy credit'
    }
})


const SetBuyCreditScreen = (props) => {
    const dispatch = useDispatch()
    const buyCredit = useSelector((state) => getBuyCredit(state))
    const errorsForm = useSelector((state) => getBuyCreditErrors(state))
    const [creditToChange, setCreditToChange] = useState(buyCredit)
    const bankData = useSelector((state) => getBankData(state))

    useEffect(() => {
        dispatch({ type: 'RESET_MESSAGES' })
    }, []);

    const onSubmit = (values) => {
        dispatch(setBuyCredit(values))
    }

    if(!bankData || !bankData.validated) {
        return  <Alerts alert={"FILL_FINANCIAL_INFO_TO_TRADE"} status={"warning"} />
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <form className="form">
                    <div className="row">
                        <div className="col-12 ">
                            <h2>
                                <Translation id="personal_information" defaultMessage="Personal information" />
                            </h2>
                        </div>
                    </div>

                    <div className="col-md-6 col-">
                        <h3>
                            <Translation id="SET_BUY_CREDIT" />
                        </h3>
                        <FormInput
                            name='buyCredit'
                            id='buyCredit'
                            type='number'
                            required={true}
                            showRequired={false}
                            label={<span>
                                <Translation
                                    id="buy-credit"
                                    defaultMessage="Specify the credit you want to use for buying announces, it should be minor or equal to your account credit, only your buy announces with enough buy credit available are listed on portal"
                                />
                                <br/>
                                <Translation
                                    id="your_buy-credit-is"
                                    defaultMessage="Your buy credit is:"
                                />
                                &nbsp;
                                <DisplayPrice price={buyCredit}></DisplayPrice>
                            </span>}
                            errors={errorsForm}
                            defaultValue={creditToChange}
                            onChange={(e) => setCreditToChange(e.target.value)}
                            placeholder={props.intl.formatMessage({id:'PLACEHOLDER_ADD_BUY_CREDIT'})}
                        />

                        <Button type="submit" className="btn btn-primary register_button" value="Update" onClick={() => onSubmit(creditToChange)}>
                            <Translation id="update" defaultMessage="Update" />
                        </Button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(SetBuyCreditScreen)))
