import React from 'react'
import ReactTooltip from 'react-tooltip'

const DisplayExpansion = ({ product }) => {
    return (
        <div>
            <ReactTooltip id={'min-set-' + product.id} effect='solid'>
                {product.setName}
            </ReactTooltip>

            {product.keyruneCode && (
                <img
                    style={{ width: '20px' }}
                    src={`https://svgs.scryfall.io/sets/${product.keyruneCode.toLowerCase()}.svg`}
                    data-tip
                    data-for={'min-set-' + product.id}
                    onError={(event) => {
                        event.target.onerror = ''
                        event.target.src = 'https://svgs.scryfall.io/sets/planeswalker.svg'
                        return true
                    }}
                />
            )}
        </div>
    )


}

export default DisplayExpansion
